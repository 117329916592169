import React,{Component, InputHTMLAttributes} from "react";
import { JsonEditor as JEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import {Field} from 'redux-form';
import _ from 'lodash';
import { InputProps } from "ra-core/esm/form/types";
var debug = require('debug')('app:components:objecteditor')

interface IProps {
    input:any,
    source:string
}

class Editor extends Component<IProps>{
    value:object = {};
    jsonEditor:any = null

    setRef = (instance:any) => {
        if (instance) {
              const {jsonEditor} = instance;
              this.jsonEditor = jsonEditor;
        } else {
              this.jsonEditor = null;
        }
    };

    onValueChange = (value:any) =>{
        this.value = value;
        if(value!==this.props.input.value && this.props.input.onChange)this.props.input.onChange(value)
    }

    render(){
        if(this.jsonEditor && !_.isEqual(this.props.input.value,this.value)){
            this.value = this.props.input.value;
            this.jsonEditor.set(this.props.input.value);
        }
        return(
            <div >
                {/* <JSONTree data={json} /> */}
                <JEditor
                    ref={this.setRef}
                    {...this.props.input}
                    onChange={this.onValueChange}
                    key={"jsoneditor_React::"+this.props.source}
                />
            </div>
        )
    }
}

const ObjectInput = (props:InputProps) => <Field {...props} name={props.source} component={Editor} />

export default ObjectInput;