import React from "react";
import {
	Create,
	SimpleForm,
	NumberInput,
	FormTab,
	BooleanInput,
	ImageInput,
	TabbedForm,
	TextInput,
	ArrayInput,
	SimpleFormIterator
} from "react-admin";
import { DynamicImageInputPreview } from "../../components";
import OfficeForm from "./OfficeForm";
import { Card, CardContent, Typography, CardActions, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import StepperWrapper from "./OfficeFormStepperWrapper";

const useCardStyles = makeStyles({
	root: {
        width:"50%",
        height:"auto"
	},
	bullet: {
		display: "inline-block",
		margin: "0 2px",
		transform: "scale(0.8)"
	},
	title: {
		fontSize: 14
	},
	pos: {
		marginBottom: 12
	}
});

const SimpleCard: React.FC<any> = (props: any) => {
	const classes = useCardStyles();
	const bull = <span className={classes.bullet}>•</span>;

	return (
		<Card className={classes.root}>
			<CardContent>
				<Typography
					className={classes.title}
					color="textSecondary"
					gutterBottom
				>
					Word of the Day
				</Typography>
				<Typography variant="h5" component="h2">
					be{bull}nev{bull}o{bull}lent
				</Typography>
				<Typography className={classes.pos} color="textSecondary">
					adjective
				</Typography>
				<Typography variant="body2" component="p">
					well meaning and kindly.
					<br />
					{'"a benevolent smile"'}
				</Typography>
			</CardContent>
			<CardActions>
				<Button size="small">Learn More</Button>
			</CardActions>
		</Card>
	);
};

const useStyles = makeStyles({
	div: {
        display: "flex",
        flexWrap: "wrap"
	},
	card: {
		width: "64px",
		height: "64px"
	}
});

const OfficeCreate: React.FC<any> = (props: any) => {
	const classes = useStyles();
	return (
		<Create {...props}>
			{/* <OfficeForm /> */}
			<StepperWrapper />
		</Create>
	);
};
export default OfficeCreate;
