import React from 'react';
import {MenuTextField} from '../components';

const styles={
    cardMain:{
        width:'100%',
        height:'100%'
    },
    searchBox:{
        display:"inline-block",
        maxWidth:"408px"
    },
    cardHeaderMain:{

    },
    avatar: {
        backgroundColor: "red[500]",
    },
    ntp_contents: {
        "display": "flex",
        "flexDirection": "column",
        "height": "100%",
        "position": "relative",
        "zIndex": "1"
    },
    logo: {
        "display": "block",
        "backgroundRepeat": "no-repeat",
        "bottom": "var(--logo-margin-bottom)",
        "height": "92px",
        "left": "calc(50% - 272px/2)",
        "position": "absolute",
        "width": "272px"
    },

    
}
export default class extends React.Component {
    render() {
        return (
            <div id="main" style={styles.searchBox}>
                <MenuTextField/>
            </div>
        );
    }
}
// export default class extends React.Component {
//     constructor(props) {
//         super(props);
//     }

//     render() {
//         return (
//             <Card style={styles.cardMain}>
//                 <CardHeader 
//                     avatar={            
//                         <Avatar aria-label="Recipe" className={styles.avatar}>
//                             <img rel="shortcut icon" src="../../favicon.ico" />
//                         </Avatar>
//                     }
//                     title={document.title} 
//                 />
//                 <CardContent> Lorem ipsum sic dolor amet...</CardContent>
//             </Card>
//         );
//     }
// }