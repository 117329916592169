import GoogleMap from "./GoogleMaps";
import React from "react";
import { loadPublicConfig } from "../ariabo.config";
import { Record } from "ra-core";
import { Geohash } from "../helpers";
import { Marker, MarkerClusterer } from "@react-google-maps/api";
import { Clusterer } from "@react-google-maps/marker-clusterer";
import { connect } from "react-redux";
import { push as routerPush } from 'react-router-redux';
import get from "lodash/get";
import { useConfig, useAuthorizedDataFetch } from "../hooks";
import QueryString from "query-string";
import {getDataRoutes} from "../ariabo.config"
import getDataRouteProperties from "../utils/getDataRouteProperties";
var debug = require("debug")("app:components:resourcesonmap");

const payload = {
    pagination: { page: 0, perPage: 0 },
    sort: { field: "id", order: "ASC" }
};

const ResourcesOnMap: React.FC<any> = (props: any) => {
    const { resource, basePath, push } = props;
    const config = useConfig();
    const center = config.geolocation;
    const aditionalResourceFilters = React.useMemo(()=>getDataRouteProperties(resource), [resource])
    const { data, loading, error } = useAuthorizedDataFetch(`tags?` + QueryString.stringify({ filter: JSON.stringify({ "modules.geolocation": { "$ne": null }, ...aditionalResourceFilters }) }))
    debug("props", props);
    debug("fetch", data, loading, error);
    return (
        <GoogleMap
            center={{
                lat: center.latitude,
                lng: center.longitude
            }}
        >
            {(data && data.length) &&
                <MarkerClusterer
                    options={{
                        imagePath:
                            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
                    }}
                    maxZoom={21}
                >
                    {(clusterer: Clusterer) =>
                        data
                            .filter((r: Record) => get(r, "modules.geolocation.locations"))
                            .map((record: Record) => {
                                const locations = get(record, "modules.geolocation.locations");
                                const target = locations.find((loc: any) => loc["geoHash"] && Geohash.isValid(loc["geoHash"]))
                                if (!target) return undefined;
                                const latLong = Geohash.decode(
                                    target["geoHash"]
                                );
                                return (
                                    <Marker
                                        key={`${record["id"]}.geoHash`}
                                        position={{
                                            lat: latLong.lat,
                                            lng: latLong.lon
                                        }}
                                        label={record["name"]}
                                        clusterer={clusterer}
                                        onClick={() => push(`${basePath}/${record.id}`)}
                                    />
                                );
                            })
                    }
                </MarkerClusterer>
            }
        </GoogleMap>
    );
};

export default connect(undefined, { push: routerPush })(ResourcesOnMap);
