import React from "react";
import {
	Edit,
	SimpleForm,
	SaveButton,
	Toolbar,
	DELETE,
	DeleteButton,
	ArrayInput,
	SimpleFormIterator
} from "react-admin";
import { ReactAdminCreateProps, ExpField } from "../../../types";
import { GetFields } from "./Fields";
import { createStyles, withStyles } from "@material-ui/core";
import {
	AccessControlledFeature,
	DeleteDialog,
	PlayerInput,
	FormDataJsonDrawerButton
} from "../../../components";
import { ModuleTypes } from "../../modules/types";
import omit from "lodash/omit";
import ReactJson from "react-json-view";
import { FormSpy } from "react-final-form";
import { makeStyles } from "@material-ui/styles";
import { ModuleCollectionInput } from "./Modules";
const debug = require("debug")("app:models:customExp:edit");

const styles = createStyles({
	form: {
		display: "block"
	}
});

const useToolbarStyles = makeStyles({
	toolbar: {
		display: "flex",
		justifyContent: "space-between"
	}
});

const CustomToolbar: React.FC<any> = (props: any) => {
	const { jsonViewer, ...restProps } = props;
	const classes = useToolbarStyles();
	return (
		<Toolbar {...restProps} classes={classes}>
			<SaveButton />
			{jsonViewer && <FormDataJsonDrawerButton />}
			<AccessControlledFeature feature={DELETE}>
				<DeleteButton />
			</AccessControlledFeature>
		</Toolbar>
	);
};

const EditTitle = (props: any) => {
	const { record } = props;
	return <span>Edit {record ? `"${record.name}"` : ""}</span>;
};

const ExpEdit: React.FC<ReactAdminCreateProps> = (
	props: ReactAdminCreateProps
) => {
	const { options, classes } = props;
	const fields: ExpField[] = options && options["fields"];
	const defaultValues: any = options && options["defaultValues"];
	const modules: ModuleTypes[] = options && options["modules"];
	const hasGeolocationModule: boolean =
		modules && modules.includes(ModuleTypes.GeolocationModule);
	const modulesWithoutGeolocation =
		modules && modules.filter(m => m !== ModuleTypes.GeolocationModule);
	return (
		<Edit {...props} undoable={false} title={<EditTitle />}>
			<SimpleForm
				toolbar={
					<CustomToolbar jsonViewer={options && options.jsonViewer} />
				}
				initialValues={defaultValues}
				className={classes.form}
			>
				{modules && modules.includes(ModuleTypes.GeolocationModule) && (
					<GetFields
						fields={["geoHash"]}
						fieldProps={{
							source: "modules.geolocation.locations[0].geoHash"
						}}
					/>
				)}
				<GetFields
					fields={fields}
					fieldProps={omit(props, ["label", "source", "options"])}
				/>
				{modulesWithoutGeolocation &&
					modulesWithoutGeolocation.length > 0 && (
						<ModuleCollectionInput
							source="modules"
							modules={modulesWithoutGeolocation.filter(
								m => m !== ModuleTypes.GeolocationModule
							)}
						/>
					)}
			</SimpleForm>
		</Edit>
	);
};

export default withStyles(styles)(ExpEdit);
