import React from 'react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Avatar from './Avatar'
const styles = {
    card: {
        float: 'left',
        margin: '-20px 20px 0 15px',
        zIndex: 100,
        borderRadius: 3,
        width: 82,
        height: 82,
    },
    icon: {
        float: 'right',
        width: 54,
        height: 54,
        padding: 14,
        color: '#fff',
    },
    style1:{
        margin: '0',
        padding: '0',
        border: '0',
        verticalAlign: 'baseline',    
    }
    
};

class CardIcon extends React.Component {
    render() {
        const { record, icon, classes, bgColor } = this.props;
        return (
            <Card className={classes.card} style={{ backgroundColor: bgColor }}>
                <Avatar record={record} source={icon}/>
            </Card>
        );
    }
}

export default withStyles(styles)(CardIcon);