import stagedImageReducer, {CHANGE_STAGED_IMAGE_ACTION as CSIA} from './stagedImageReducer';
import customThemeReducers from './customThemeReducers';
import inheritanceReducer, {SET_INHERITABLE_VALUES as SIV} from './inheritanceReducer';
import resourceSchemasReducer from "./resourceSchemasReducer";
import moduleSchemasReducer from "./moduleSchemasReducer";
export default {
    stagedImage:stagedImageReducer,
    theme:customThemeReducers,
    inheritance:inheritanceReducer,
    resourceSchemas:resourceSchemasReducer,
    moduleSchemas:moduleSchemasReducer
    // lll:customFormValuesReducer
}
export const CHANGE_STAGED_IMAGE_ACTION = CSIA;
export const SET_INHERITABLE_VALUES = SIV;
export * from "./resourceSchemasReducer";
export * from "./moduleSchemasReducer";