import React from "react";
import { TextInput, TabbedForm, FormTab, Loading, required } from "react-admin";
import MetaInput from "./MetaInput";
import urljoin from "url-join";
import { useConfig, useResourceSchemas, useModuleSchemas, useModuleSchema } from "../../hooks";
import { ModuleTypes } from "../../types";
var debug = require("debug")("app:models:appconfig:appconfigform");

const defaultValues = {
    name:"New Application",
    packageId:"com.company.newapp",
    generalData:{
        supportedLanguages:["English"] //assumes english is one of the valid languages provided by the server
    }
}

const AppConfigForm: React.FC = (props: any) => {
    const schema = useResourceSchemas().ApplicationConfigs;
    if (!schema) return <Loading />
    debug("Schema?", schema);
    return (
        <TabbedForm {...props} initialValues={defaultValues} redirect="list">
            <FormTab label="Summary">
                <TextInput source="name" validate={required()}/>
                <TextInput source="packageId" validate={required()}/>
                <TextInput source="applicationVersion"/>
            </FormTab>
            <FormTab label="General">
                <MetaInput schema={schema} source="generalData" />
            </FormTab>
            <FormTab label="Theme" disabled />
            <FormTab label="UI" disabled />
            <FormTab label="Data" disabled />
        </TabbedForm>
    );
};

export default AppConfigForm;