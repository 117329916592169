import React from "react";
import { NumberInput, TextInput, required } from "react-admin";
import OfficeForm from "./OfficeForm";
import {
	Card,
	CardContent,
	Typography,
	Button,
	Stepper,
	StepLabel,
	Step,
	CardMedia,
	CardActionArea,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Form } from "react-final-form";
import Templates from "./templates";

//#region styles
const useStyles = makeStyles({
	root: {
		textAlign: "center",
	},
});
const useBasicFormStyles = makeStyles({
	container: {
		display: "inline-flex",
		flexDirection: "column",
		alignItems: "center",
		margin: "20px",
	},
	button: {
		width: "fit-content",
	},
	buttonContainer: {
		width: "100%",
		display: "inline-flex",
		placeContent: "space-evenly",
	},
});
const useTemplateSelectionStyles = makeStyles({
	container: {
		margin: "20px",
	},
	grid: {
		display: "flex",
		flexWrap: "wrap",
		height: "256px",
		placeContent: "center",
		margin: "20px",
	},
	card: {
		width: "256px",
		height: "256px",
		margin: "0.5rem",
	},
	button: {
		width: "fit-content",
	},
});
//#endregion

interface IStepComponent {
	onSubmit: (values: Record<string, any>) => void;
}

interface IStepFormComponent extends IStepComponent {
	skipable?: boolean;
	children: any;
}

const StepForm: React.FC<IStepFormComponent> = (props: IStepFormComponent) => {
	const { onSubmit, skipable, children } = props;
	const classes = useBasicFormStyles();
	return (
		<Form
			onSubmit={onSubmit}
			render={(props) => {
				const {
					handleSubmit,
					form,
					submitting,
					pristine,
					values,
					invalid,
					...restProps
				} = props;
				return (
					<form onSubmit={handleSubmit}>
						<div className={classes.container}>
							{children}
							{/* actual inputs go here */}
							<span className={classes.buttonContainer}>
								{skipable && (
									<Button
										variant="contained"
										type="button"
										color="primary"
										className={classes.button}
										onClick={() => onSubmit({})}
									>
										Skip
									</Button>
								)}
								<Button
									disabled={invalid}
									variant="contained"
									color="primary"
									type="submit"
									className={classes.button}
								>
									Next
								</Button>
							</span>
						</div>
					</form>
				);
			}}
		/>
	);
};

const BasicForm: React.FC<IStepComponent> = (props: IStepComponent) => (
	<StepForm {...props}>
		<TextInput source="name" validate={required()} />
		<TextInput source="title" />
	</StepForm>
);

const ServerForm: React.FC<IStepComponent> = (props: IStepComponent) => (
	<StepForm {...props}>
		<TextInput source="apiUrl" validate={required()} />
		<TextInput source="filesApiUrl" validate={required()} />
	</StepForm>
);

const GeolocationForm: React.FC<IStepComponent> = (props: IStepComponent) => (
	<StepForm {...props} skipable={true}>
		<TextInput
			source="geolocation.googleMapsApiKey"
			multiline
			fullWidth={true}
			label="GoogleMaps API Key"
			validate={required()}
		/>
		<NumberInput
			source="geolocation.latitude"
			label="Center Latitude"
			validate={required()}
		/>
		<NumberInput
			source="geolocation.longitude"
			label="Center Longitude"
			validate={required()}
		/>
	</StepForm>
);

const TemplateSelection: React.FC<IStepComponent> = (props: IStepComponent) => {
	const { onSubmit } = props;
	const classes = useTemplateSelectionStyles();
	const [selectedTemplate, selectTemplate] = React.useState(-1);
	const TemplateCard: React.FC<any> = (props) => (
		<Card className={classes.card} raised={props.raised}>
			<CardActionArea onClick={props.onClick}>
				<CardMedia component="img" height="210px" image={props.img} />
				<CardContent>
					<Typography
						variant="body2"
						color="textSecondary"
						component="p"
					>
						{props.title}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
	return (
		<div className={classes.container}>
			<div className={classes.grid}>
				{Templates.map((t, i) => (
					<TemplateCard
						key={`TemplateSelection_${i}`}
						onClick={() => {
							selectTemplate(i);
						}}
						raised={selectedTemplate === i}
						title={t.title}
						img={t.img}
					/>
				))}
			</div>
			<Button
				disabled={selectedTemplate < 0}
				variant="contained"
				color="primary"
				className={classes.button}
				onClick={() => {
					onSubmit(Templates[selectedTemplate].data);
				}}
			>
				Next
			</Button>
		</div>
	);
};

const steps = [
	{
		label: "Basic Information",
		description: "Define the base configuration of this office",
		component: (props: IStepComponent) => <BasicForm {...props} />,
	},
	{
		label: "Server",
		description:
			"The office will have a dedicated server in charge of handling all database related operations",
		component: (props: IStepComponent) => <ServerForm {...props} />,
	},
	{
		label: "Select Template",
		description:
			"We offer a wide range of templates that preconfigure certain properties on the office",
		component: (props: IStepComponent) => <TemplateSelection {...props} />,
	},
	{
		label: "Geolocation",
		description:
			"Geolocation configuration will define the geographic environment this office refers to",
		component: (props: IStepComponent) => <GeolocationForm {...props}/>,
	},
];

const StepperWrapper: React.FC = (props: any) => {
	const { ...restProps } = props;
	const [activeStep, setActiveStep] = React.useState(0);
	const officeData = React.useRef({});
	const classes = useStyles();
	const onStepSubmit = (values: any) => {
		officeData.current = {
			...officeData.current,
			...values,
		};
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	const ActiveComponent =
		activeStep < steps.length && steps[activeStep].component;
	return (
		<div>
			<div className={classes.root}>
				<Stepper activeStep={activeStep}>
					{steps.map((step, index) => {
						return (
							<Step key={`StepperWrapper_stepbutton_${index}`}>
								<StepLabel>{step.label}</StepLabel>
							</Step>
						);
					})}
					<Step key={`StepperWrapper_stepbutton_${steps.length}`}>
						<StepLabel>Review</StepLabel>
					</Step>
				</Stepper>
				{ActiveComponent && (
					<ActiveComponent
						key={`StepperWrapper_stepcomponent_${activeStep}`}
						onSubmit={onStepSubmit}
					/>
				)}
			</div>

			{activeStep === steps.length && (
				<OfficeForm {...restProps} initialValues={officeData.current} />
			)}
		</div>
	);
};

export default StepperWrapper;
