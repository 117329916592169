import React from 'react';
import { FileInput } from "react-admin";
import ReactAudioPlayer from 'react-audio-player';
import { withStyles, createStyles } from '@material-ui/core';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { BasedFileInputProps } from "./types";
import { Dict, FileRecord } from '../types';
import { useForm } from "react-final-form";

var debug = require('debug')('app:components:basedaudioclipinput')

const AudioClipFilePreview = (props: BasedFileInputProps) => {
    let value: string = "";
    if (props.record) {
        if (typeof props.record === 'string') value = props.record;
        else if (props.record as FileRecord) value = (props.record as FileRecord).rawFile.preview;
        else if (get(props.record, props.source)) value = get(props.record, props.source) as string;
    }
    debug(get(props.record, props.source))
    return (
        <ReactAudioPlayer
            src={value}
            autoPlay={false}
            className={props.classes.av_audioPlayer}
            title={typeof props.record === 'string' ? props.record : (get(props.record, 'name') || get(props.record, 'rawFile.name'))}
            controls />
    )
}

const DynamicAudioClipPreview = (props: Partial<BasedFileInputProps>) => {
    const { completeSource, source } = props;
    const form = useForm();
    const formState = form.getState();
    const formValues = formState.values;
    const getSourceAndPath = () => {
        if (!completeSource || !source) return {};
        const initial = get(formValues, completeSource);
        debug("formValues",completeSource,formValues);
        if (typeof initial === "string") return {src:initial, fileName:"Audio resource"};
        const src = initial[source];
        const file = initial.rawFile as File;
        debug("memo initial", initial)
        debug("file?filePath?", file, src);
        return {src, fileName:file && file.name};
    };
    const {src, fileName} = getSourceAndPath();
    debug("initial", src);
    return <ReactAudioPlayer src={src}
        autoPlay={false}
        className={props.classes.av_audioPlayer}
        title={fileName}
        controls />
}

const MyAudioClipInput = (props: Dict) => (
    <div className={props.classes.inputDiv}>
        <FileInput {...props} classes={pick(props.classes, ['dropZone', 'removeButton'])} options={{ className: props.classes.dropZone, ...props.options }}>
            <DynamicAudioClipPreview classes={props.classes} source={props.audioSrc} completeSource={props.source} />
        </FileInput>
    </div>
)

const styles = createStyles({
    av_audioPlayer: {
        float: "left",
        marginLeft: '0.5rem',
        // padding: "0.1rem",

    },
    inputDiv: {
        alignSelf: "baseline",
        width: "100%"
    },
    removeButton: {
        marginTop: "0.5rem"
    },
    dropZone: {
        color: "#999",
        cursor: "pointer",
        padding: "0.1rem",
        background: "#efefef",
        textAlign: "center",
    }
})

export default withStyles(styles)(MyAudioClipInput);