// import * as ReactFieldProps from "react-admin node_modules/ra-ui-materialui/lib/field/types";
//import * as ReactFieldProps from "react-admin/node_modules/ra-ui-materialui/src/field/types";
import * as ReactFieldProps from "ra-ui-materialui/src/field/types";
import { Record as RaCoreRecord, ReduxState } from "ra-core";
import { Dictionary } from "lodash";
import { match } from "react-router";
// import { ReduxState, Record as RaCoreRecord, Identifier } from 'ra-core';

export type ThemeName = "light" | "dark";

export interface AppState extends ReduxState {
	theme: ThemeName;
}

export interface FieldProps
	extends ReactFieldProps.FieldProps,
	ReactFieldProps.InjectedFieldProps {
	record: RaCoreRecord;
	source: string;
	classes?: any;
}

export interface FileWithPreview extends File {
	preview: string;
}

export interface FileRecord {
	rawFile: FileWithPreview;
}

export type Dict = { [key: string]: any };

interface FormDataTypesInterface {
	STRING: string;
	COLOR: string;
	VECTOR: string;
	BOOL: string;
	INTEGER: string;
	REFERENCE: string;
	ARRAY: string;
	FLOAT: string;
	MAP: string;
	ENUM: string;
	eventMapper: string;
}

export const FormDataTypes: FormDataTypesInterface = Object.freeze({
	STRING: "string" as "string",
	COLOR: "color" as "color",
	VECTOR: "vector" as "vector",
	BOOL: "bool" as "bool",
	INTEGER: "integer" as "integer",
	REFERENCE: "reference" as "reference",
	ARRAY: "array" as "array",
	FLOAT: "float" as "float",
	MAP: "object" as "object",
	ENUM: "enum" as "enum",
	eventMapper: "eventMapper" as "eventMapper"
});

export interface FormDataStruct {
	id: string | number;
	name: string | number;
	type?: keyof typeof FormDataTypes;
	props?: (sourceProps: Dict) => Dict;
	validate?: (sourceProps: Dict) => boolean;
	args?: FormDataStruct[];
	defaultArgs?: Dict;
	map?: FormDataStruct[];
	arrayof?: FormDataStruct[];
	choices?: FormDataStruct[];
	tooltip?: string;
	exclusiveWith?: string[];
	initial?: boolean;
	defaultValue?: any;
}

export interface FormDataHolder {
	[key: string]: FormDataStruct[];
}

export enum ModuleTypes {
	GeolocationModule = "GeolocationModule",
	TriggersModule = "TriggersModule",
	EffectsModule = "EffectsModule",
	LocalizationModule = "LocalizationModule",
	DataClassificationModule = "DataClassificationModule",
	PlayersModule = "PlayersModule",
	HierarchyModule = "HierarchyModule",
	LightModule = "LightModule",
	ShapeModule = "ShapeModule",
	TransformModule = "TransformModule",
	TransformDirectorsModule = "TransformDirectorsModule",
	EventsModule = "EventsModule",
	StyleModule = "StyleModule",
	SkyboxModule = "SkyboxModule",
	GlobalStadiumEventModule = "GlobalStadiumEventModule"

}
export interface IModuleRecord<T> extends Record<ModuleTypes, T> {

}

export interface ReactAdminCreateProps {
	basePath: string;
	hasCreate?: boolean;
	hasEdit?: boolean;
	hasList?: boolean;
	hasShow?: boolean;
	location: Location;
	match: match;
	record?: Partial<RaCoreRecord>;
	resource: string;
	permissions?: string;
	history: History;
	options?: Dict;
	classes?: any;
}

export interface IConfigResources {
	tags: string[];
	assets: string[];
	users: string[];
}
export interface IConfigGeolocation {
	googleMapsApiKey: string,
	latitude: number,
	longitude: number
}

type ResourceKey = ResourceNames | string;

interface ResourcesConfig {
	[key: string]: any
}

export interface IConfig {
	name: string;
	title: string;
	apiUrl: string;
	filesApiUrl: string;
	hasDashboard: boolean,
	publicTagsName: string;
	geolocation: IConfigGeolocation,
	tagContextName: string,
	resources: ResourcesConfig
}

export type BaseResourceNames = "ApplicationConfigs" | "Assets" | "Tags" | "Effects" | "Users";
export type ResourceNames = BaseResourceNames | "GeolocatedTags" | "Points" | "PlayTags" | "Projects" | "PointsOfInterest" | "UtilityPoints" | "CommercialPoints" | "NewsPoints" | "Offices";

export type ExpField =
	| "geoHash"
	| "parent"
	| "children"
	| "id"
	| "name"
	| "group"
	| "type"
	| "content"
	| "description"
	| "img"
	| "expTriggers"
	| "markers"
	| "delivery"
	| "lang"
	| "transition"
	| "authors"
	| "creationTime"
	| "attributes"
	| "isBreakingNews"
	| "modules";
// | "stateInfo" //server handled
// | "appData"; //complex
