import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ActionDelete from '@material-ui/icons/Delete';
import { translate, crudDelete, startUndoable } from 'ra-core';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@material-ui/core/Button/Button';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';

const styles = theme => ({
deleteButton: {
	color: theme.palette.error.main,
 '&:hover': {
		 backgroundColor: fade(theme.palette.error.main, 0.12),
		 // Reset on mouse devices
		 '@media (hover: none)': {
				 backgroundColor: 'transparent',
		 },
 },
},
});


class DialogDelete extends React.Component {
	state = {
		open: false,
	};
	static propTypes = {
		basePath: PropTypes.string,
		classes: PropTypes.object,
		className: PropTypes.string,
		dispatchCrudDelete: PropTypes.func.isRequired,
		label: PropTypes.string,
		record: PropTypes.object,
		redirect: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.bool,
				PropTypes.func,
		]),
		resource: PropTypes.string.isRequired,
		startUndoable: PropTypes.func,
		translate: PropTypes.func,
        undoable: PropTypes.bool,
        dialogText: PropTypes.string,
        dialogTitle: PropTypes.string,
        dialogConditional: PropTypes.any,
        beforeDelete: PropTypes.func,
        afterDelete:PropTypes.func,
        permissionDeniedText:PropTypes.string,
        permissionRequired:PropTypes.string
	};
	static defaultProps = {
		redirect: 'list',
        undoable: true,
        dialogConditional:true,
        dialogText:"Are you sure?",
        dialogTitle: "Confirmation",

	};

	handleClose = () => {
		this.setState({ open: false });
	};

    endDelete = () =>{
		const {
            dispatchCrudDelete,
            startUndoable,
            resource,
            record,
            basePath,
            redirect,
            undoable,
        } = this.props;
        if(this.props.beforeDelete)this.props.beforeDelete(record);
		if (undoable) {
            startUndoable(
                crudDelete(resource, record.id, record, basePath, redirect)
            );
		} else {
            dispatchCrudDelete(resource, record.id, record, basePath, redirect);
        }
        if(this.props.afterDelete)this.props.afterDelete(record);
    }
    handleYesDelete = ()=>{
        this.handleClose();
        this.endDelete();
    }
    handleNoDelete = ()=>{
        this.handleClose();
    }
    
    handleDelete = event => {
		event.preventDefault();
        if(this.props.dialogConditional(this.props.record))
        {
            this.setState({ open: true });
        }
        else this.endDelete();
	};

	render() {
		const {
			label = 'ra.action.delete',
			classes = {},
            className,
            permissions,
            permissionRequired
		} = this.props;
		return (
			<div>
				<Button
					onClick={this.handleDelete}
					label={label}
					className={classnames(
							'ra-delete-button',
							classes.deleteButton,
							className
					)}
					key="button"
				>
					<ActionDelete/>
				</Button>
                {permissionRequired && permissions===permissionRequired &&
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {this.props.dialogTitle}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.dialogText}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleYesDelete} color="primary">
                                Yes
                            </Button>
                            <Button onClick={this.handleNoDelete} color="primary">
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                {permissionRequired && permissions!==permissionRequired &&
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {this.props.dialogTitle}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.permissionDeniedText}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleNoDelete} color="primary">
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                {!permissionRequired &&
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {this.props.dialogTitle}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.dialogText}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleYesDelete} color="primary">
                                Yes
                            </Button>
                            <Button onClick={this.handleNoDelete} color="primary">
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
                }

			</div>
		);
	}
}

export default compose(
	connect(
			null,
			{ startUndoable, dispatchCrudDelete: crudDelete }
	),
	translate,
	withStyles(styles)
)(DialogDelete);