import React from 'react';
import { TextField, List, Datagrid } from 'react-admin';

export default (props) =>(
    <List title={"Triggers"} {...props}>
        <Datagrid>
            <TextField source='name' />
            <TextField source='description' />
        </Datagrid>
    </List>
)