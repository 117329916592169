/* eslint-disable */
import React from "react";
import {
	GoogleMap,
	Data,
	GoogleMapProps,
	DataProps,
	useLoadScript,
} from "@react-google-maps/api";
import { GetConfig } from "../config";
var debug = require("debug")("app:components:googlemaps");

const defaultCenter = {
	lat: GetConfig()?.geolocation?.latitude,
	lng: GetConfig()?.geolocation?.longitude
};

interface IGoogleMapsProps {
	apiKey?: string;
	center?: typeof defaultCenter;
	ignoreUserLocation?: boolean;
	mapContainerStyle?: any;
	mapProps?: Partial<GoogleMapProps>;
	dataProps?: Partial<DataProps>;
	children?: any;
	onClick?: (e: any) => void;
	onLoad?: (map: any) => void;
}

const Map: React.FC<IGoogleMapsProps> = (props: IGoogleMapsProps) => {
	const {
		apiKey,
		center,
		children,
		mapProps,
		dataProps,
		onClick,
		onLoad,
		ignoreUserLocation
	} = props;
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: apiKey || GetConfig().geolocation.googleMapsApiKey
	});
	const G = React.useRef<any>(undefined);
	const MAP = React.useRef<any>(undefined);
	if (!G.current) {
		G.current = (window as any).google;
	}
	const centerValid = typeof center === "object" && center.lat && center.lng;
	React.useMemo(() => {
		if (centerValid || ignoreUserLocation) return;
		navigator.geolocation.getCurrentPosition(
			pos => {
				if (MAP.current)
					MAP.current.panTo({
						lat: pos.coords.latitude,
						lng: pos.coords.longitude
					});
				else {
					defaultCenter.lat = pos.coords.latitude;
					defaultCenter.lng = pos.coords.longitude;
				}
			},
			error => {
				console.error("ERROR RETRIEVING USER LOCATION", error);
			}
		);
	}, []);
	return isLoaded ? (
		<GoogleMap
			zoom={16}
			id="google-map"
			center={centerValid ? center : defaultCenter}
			mapContainerStyle={{ width: "100%", height: "100%" }}
			{...mapProps}
			onClick={e => {
				if (MAP.current) {
					MAP.current.panTo(e.latLng);
					onClick && onClick(e);
				}
			}}
			onLoad={map => {
				MAP.current = map;
				onLoad && onLoad(MAP.current);
			}}
		>
			<Data {...dataProps} />
			{children}
		</GoogleMap>
	) : (
		<div>Loading...</div>
	);
};

export default Map;
