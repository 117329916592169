import React from "react";
import { Edit } from "react-admin";
import AppConfigForm from "./AppConfigForm";

const AppConfigEdit: React.FC = (props: any) => {
    return (
        <Edit {...props}>
            <AppConfigForm />
        </Edit>
    );
}

export default AppConfigEdit;