import React from "react";
import Form, { IChangeEvent, withTheme, ErrorSchema } from "react-jsonschema-form";
import { addField, Labeled } from "react-admin";
import { InputProps } from "ra-core/esm/form/types";
import { Dialog, Button, DialogContent } from "@material-ui/core";
import { omit } from "lodash";
import MuiForm from "rjsf-material-ui";
import ArrayTemplate from "./RJSF_ArrayFieldTemplate";
import CustomWidgets from "./CustomWidgets";
import EditIcon from "@material-ui/icons/Edit";
import { AnyObject, FormApi } from "final-form";
import { useForm } from "react-final-form";
import get from "lodash/get";
import remove from "lodash/remove";
const debug = require("debug")("app:components:rjsf:schemaform");

interface IProps {
	onSubmit: (e: IChangeEvent<any>) => void;
	onChange?: (e: IChangeEvent<any>, es?: ErrorSchema) => any;
	onBlur?: (id: string, value: boolean | number | string | null) => void;
	formData: Object;
	schema: Object;
	uiSchema?: Object;
    formContext?: Object;
    disabled?:boolean
}

const SchemaForm: React.FC<IProps> = (props: IProps) => {
	const { schema, onSubmit, formData, uiSchema, formContext, onChange, onBlur, disabled } = props;
	const omittedSchema = omit(schema, ["$schema"]) as Object; //quickfix: no schema with key or ref "http://json-schema.org/draft-04/schema#"
	// return <MuiForm schema={omittedSchema} uiSchema={uiSchema} onSubmit={onSubmit} formData={formData} />;
	return (
		<Form
			widgets={CustomWidgets}
			ArrayFieldTemplate={ArrayTemplate}
			schema={omittedSchema}
			uiSchema={uiSchema}
			onSubmit={onSubmit}
			onBlur={onBlur}
			onChange={onChange}
			formData={formData}
            formContext={formContext}
            disabled={disabled}
		/>
	);
};

export interface InputSchemaFormProps extends Partial<IProps> {
	[key: string]: any;
	schema: Object;
	uiSchema?: Object
}

type SubscriptionFunction = (key: string, callback: SubscriptionCallback) => any[]
type SubscriptionCallback = (key: string, value: any) => void;

export interface InputSchemaFormContext {
	externalSource: string;
	treeIndex: string,
	submissionSubscribers: { [key: string]: (form: FormApi<AnyObject>) => void },
	subscribe: SubscriptionFunction
}

export const InputSchemaForm: React.FC<InputSchemaFormProps> = addField(
	(props: InputSchemaFormProps & InputProps) => {
		const { input, schema, uiSchema, label, source, treeIndex, disabled } = props;
		const [dialogOn, setDialogOn] = React.useState<boolean>(false);
		const form = useForm();
		const fieldState = form.getFieldState(source);

		const subscribers = React.useRef<{ [key: string]: Array<SubscriptionCallback> }>({})
		const formContextRef = React.useRef<InputSchemaFormContext>({
			externalSource: source,
			treeIndex: treeIndex,
			submissionSubscribers: {},
			subscribe: (key: string, callback: SubscriptionCallback) => {
				const formState = form.getState();
				const formValues = formState.values;
				subscribers.current[key] = subscribers.current[key] || [];
				subscribers.current[key].push(callback);
				const dotSource = key.replace(/_/g, ".").replace(/^root./, "");
				const rjsfScope = get(formValues, source, {})
				return [
					get(rjsfScope, dotSource),
					() => remove(subscribers.current[key], (f) => f === callback)
				]
			}
		});
		const onSubmit = (e: IChangeEvent<any>) => {
			debug("FORMDATA", e.formData);
			Object.values(formContextRef.current.submissionSubscribers).forEach(f => f(form));
			input.onChange(e.formData);
			setDialogOn(false);
		};
		const onBlur = (key: string, value: any) => {
			debug("onBlur", key, value)
			subscribers.current[key] && subscribers.current[key].forEach(f => f(key, value));
		}
		debug("input field state", source, fieldState)
		const EditButton = () => (
			<Button
				onClick={() => setDialogOn(true)}
				variant="contained"
				color={!fieldState || (fieldState.pristine && !fieldState.value) ? "default" : "primary"}
			>
				<EditIcon />
			</Button>
		)
		return (
			<>
				<Dialog
					open={dialogOn}
					onClose={() => setDialogOn(false)}
					maxWidth="md"
					fullWidth={true}
					// fullScreen={isMobile}
					// classes={{ paperWidthMd: props.classes.paperWidthMd }}
					aria-labelledby="responsive-dialog-title"
				>
					<DialogContent>
						<SchemaForm
							schema={schema}
							formData={input.value}
							onSubmit={onSubmit}
							onBlur={onBlur}
							uiSchema={uiSchema}
                            formContext={formContextRef.current}
                            disabled={disabled}
						/>
					</DialogContent>
				</Dialog>
				{label ?
					<Labeled label={label || props.source}>
						<EditButton />
					</Labeled>
					:
					<EditButton />
				}
			</>
		);
	}
);

export default SchemaForm;
