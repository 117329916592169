import React, { Component } from 'react';
import {
    Admin,
    Resource,
    mergeTranslations,
    Loading
} from 'react-admin';
import englishMessages from 'ra-language-english';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import OfficeConfig, { loadPublicConfig } from './ariabo.config';
import portugueseMessages from 'ra-language-portuguese';
import localMessages from './translation';
import customRoutes from './customRoutes';
import MyReducers from './customReducers';
import TreeEditor from './components/vxCollapsibleTree/TreeEditor';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Layout, Login } from './layout';
import { CircularProgress } from '@material-ui/core';
import { useConfig } from './hooks';

const messages = {
    'en': mergeTranslations(englishMessages/*, treeEnglishMessages*/, localMessages.english),
    'pt': mergeTranslations(portugueseMessages/*, treeEnglishMessages*/, localMessages.portuguese),
};

const i18nProviders = polyglotI18nProvider(locale => messages[locale]);

const App = (props) => {
    let useMyConfig = true;
    const config = useConfig();
    if(!config || !config.apiUrl)return <CircularProgress />;
    // const backofficeConfig = loadPublicConfig();
    return (
        <>
            {!useMyConfig ?
                <TreeEditor data={{ name: "Big data, very cool", id: "idk" }} onSelected={(n) => console.log("selected node " + n.data.name)} />
                :
                <Admin
                    layout={Layout}
                    login={Login}
                    customRoutes={customRoutes}
                    i18nProvider={i18nProviders}
                    dashboard={loadPublicConfig().dashboard}
                    messages={messages}
                    customReducers={MyReducers}
                    authProvider={authProvider}
                    dataProvider={dataProvider(config["apiUrl"])} >
                    {permissions =>
                        loadPublicConfig(permissions).pages.map(r =>
                            (!r.acess || localStorage.getItem("role") === r.acess) ? <Resource basePath={"i" + r.name} {...r} /> : <Resource name={r.name} />)}
                </Admin>
            }
        </>
    )
}

export default App;