import React from "react";
import DialogWrapper from "./InputDialogWrapper";
import LooksIcon from "@material-ui/icons/Looks";
import {
	TextInput,
	ArrayInput,
	SimpleFormIterator,
	NumberInput,
	ReferenceInput,
	SelectInput,
	ReferenceArrayInput,
	SelectArrayInput,
} from "react-admin";
import { makeStyles } from "@material-ui/styles";
import { InputAdornment } from "@material-ui/core";
import { InputConfigLoader } from ".";

const useStyles = makeStyles({
	leagueContainer: {
		textAlign: "center",
	},
});

const GlobalStadiumModuleInput: React.FC<any> = (props: any) => {
	const { source, label, resource } = props;
	const classes = useStyles();
	return (
		<DialogWrapper
			buttonLabel="Edit"
			label={label || "Global Stadium"}
			avatarIcon={<LooksIcon />}
			title="Global Stadium"
			scope={source}
		>
			<div className={classes.leagueContainer}>
				<InputConfigLoader resource={resource}>
					<TextInput source={`${source}.league`} label="League" />
				</InputConfigLoader>
			</div>
			<InputConfigLoader resource={resource}>
				<ReferenceArrayInput
					source={`${source}.sponsors`}
					label="Sponsors"
					reference="tags"
					perPage={0}
				>
					<SelectArrayInput optionText="name" />
				</ReferenceArrayInput>
				<ReferenceInput
					label="Common"
					source={`${source}.common`}
					reference="tags"
					perPage={0}
				>
					<SelectInput optionText="name" />
				</ReferenceInput>
				<ArrayInput source={`${source}.actors`} label="Actors" resource={resource}>
					<SimpleFormIterator>
						<InputConfigLoader
							resource={resource}
							jsonSource={`${source}.actors.key`}
							source="key"
							label="Actor"
							reference="tags"
							perPage={0}
						>
							<ReferenceInput>
								<SelectInput optionText="name" />
							</ReferenceInput>
						</InputConfigLoader>
						<NumberInput
							source="value"
							label="Weight"
							format={(v: number) => v * 100}
							parse={(v: any) => parseFloat(v) / 100}
							options={{
								InputProps: {
									endAdornment: (
										<InputAdornment position="end">
											%
										</InputAdornment>
									),
								},
							}}
						/>
					</SimpleFormIterator>
				</ArrayInput>
			</InputConfigLoader>
		</DialogWrapper>
	);
};

export default GlobalStadiumModuleInput;
