import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	SelectInput,
	ArrayInput,
	SimpleFormIterator,
	TextInput,
	FormDataConsumer,
	Labeled,
	NumberInput,
	addField,
	useInput,
} from "react-admin";
import {
	Card,
	Dialog,
	DialogTitle,
	Button,
	CardContent,
	Typography,
	DialogContent,
	Divider,
	Container,
	Box,
	createStyles,
	Theme,
	Fade,
	CardHeader,
	Avatar,
	IconButton,
	CardActions,
	fade,
} from "@material-ui/core";
import get from "lodash/get";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import { useForm } from "react-final-form";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import CloseIcon from "@material-ui/icons/Close";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
var debug = require("debug")("app:components:triggersmodule");

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			minWidth: 275,
		},
		cardGridContent: {
			display: "grid",
		},
		divider: {
			marginTop: "0.1rem",
			marginBottom: "0.1rem",
		},
		clearButton: {
			color: theme.palette.error.main,
		},
	})
);

const useDialogStyles = makeStyles({
	container: {
		padding: 0,
	},
	button: {
		width: "100%",
	},
});

const useTriggerChildrenInput = makeStyles((theme: Theme) =>
	createStyles({
		toggleButton: {
			width: "100%",
		},
		container: {
			backgroundColor: fade(theme.palette.action.active, 0.12),
		},
	})
);

const validatorChoices = [
	{ id: "Assert", name: "Assert" },
	{ id: "Deassert", name: "Deassert" },
];

const triggerConditionTypesChoices = [
	{ id: "Proximity", name: "Proximity" },
	{ id: "Date", name: "Date" },
	{ id: "Time", name: "Time" },
];

const childrenOperators = [
	{ id: "AND", name: "AND" },
	{ id: "OR", name: "OR" },
];

interface TriggerItemProps {
	// source: string,
	[key: string]: any;
}

const TriggerArgumentsInput: React.FC<any> = (props: any) => {
	const { source } = props;
	return (
		<FormDataConsumer>
			{(props: any) => {
				const { formData } = props;
				const scope = get(formData, source);
				if (!scope)
					return (
						<TextInput
							disabled
							source={`${source}.arguments.value`}
							label="Arguments"
						/>
					);
				const triggerId = scope["condition"];

				if (triggerId === "Proximity") {
					return (
						<NumberInput
							source={`${source}.arguments.value`}
							label="Meters"
						/>
					);
				}
				if (triggerId === "Date") {
					return (
						<div>
							<TextInput
								source={`${source}.arguments.from`}
								label="From"
								options={{
									type: "date",
									InputLabelProps: {
										shrink: "false",
									},
								}}
							/>
							<TextInput
								source={`${source}.arguments.to`}
								label="To"
								options={{
									type: "date",
									InputLabelProps: {
										shrink: "false",
									},
								}}
							/>
						</div>
					);
				}
				if (triggerId === "Time") {
					return (
						<div>
							<TextInput
								source={`${source}.arguments.from`}
								label="From"
								options={{
									type: "time",
									InputLabelProps: {
										shrink: "false",
									},
								}}
							/>
							<TextInput
								source={`${source}.arguments.to`}
								label="To"
								options={{
									type: "time",
									InputLabelProps: {
										shrink: "false",
									},
								}}
							/>
						</div>
					);
				}
				return (
					<TextInput
						disabled
						source={`${source}.arguments.value`}
						label="Arguments"
					/>
				);
			}}
		</FormDataConsumer>
	);
};

///Dangerous, expects react adming to represent hierarchy on id provided to array input children
const idToReadableRoute = (id: string) => {
	return id
		.substring(id.indexOf("root.children"), id.length)
		.replace("children", "subtriggers");
};

const TriggerChildrenInput: React.FC<any> = (props: any) => {
	const { source } = props;
	const classes = useTriggerChildrenInput();
	// const form = useForm();
	// const formState = form.getState();
	// const currentValue = get(formState.values, `${source}.childrenOperator`);
	// const toggleGroupHandleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
	//     form.change(`${source}.childrenOperator`, newValue);
	// };
	const toggleButtonGroupProps = useInput({
		source: `${source}.childrenOperator`,
	});
	const currentValue = toggleButtonGroupProps.input.value;
	debug("toggleButtonGroup", toggleButtonGroupProps);
	debug("toggleButtonGroup props value", currentValue);
	const toggleGroupOnChange = (
		event: React.MouseEvent<HTMLElement>,
		value: any
	) => {
		debug("toggleButtonGroup onChange value", value);
		toggleButtonGroupProps.input.onChange(value);
	};
	const toggleGroupOnBlur = (event: React.MouseEvent<HTMLElement>) => {
		debug("toggleButtonGroup onBlur event", event);
		toggleButtonGroupProps.input.onBlur(event);
	};
	const toggleGroupFocus = (event: React.MouseEvent<HTMLElement>) => {
		debug("toggleButtonGroup onFocus event", event);
		toggleButtonGroupProps.input.onFocus(event);
	};
	return (
		<>
			<ToggleButtonGroup
				id={toggleButtonGroupProps.id}
				size="small"
				exclusive
				{...toggleButtonGroupProps.input}
				onChange={toggleGroupOnChange}
				onBlur={toggleGroupOnBlur}
				onFocus={toggleGroupFocus}
				/*onChange={toggleGroupHandleChange}*/
			>
				<ToggleButton className={classes.toggleButton} value={"AND"}>
					AND
				</ToggleButton>
				<ToggleButton className={classes.toggleButton} value={"OR"}>
					OR
				</ToggleButton>
			</ToggleButtonGroup>
			<Fade in={currentValue}>
				<Container className={classes.container}>
					<ArrayInput
						label="Sub Triggers"
						source={`${source}.children`}
					>
						<SimpleFormIterator>
							<DialogTriggerItem buttonLabel="Edit Sub Trigger" />
						</SimpleFormIterator>
					</ArrayInput>
				</Container>
			</Fade>
		</>
	);
};

const TriggerValidatorInput: React.FC<any> = (props: any) => {
	const { source } = props;
	return (
		<div>
			<SelectInput
				allowEmpty={false}
				label="Validator"
				source={`${source}.validator`}
				choices={validatorChoices}
			/>
			<FormDataConsumer>
				{(props: any) => {
					const { formData } = props;
					const value = get(formData, `${source}.validator`);
					return (
						<SelectInput
							disabled={value === null || value === undefined}
							allowEmpty={false}
							label="Condition"
							source={`${source}.condition`}
							choices={triggerConditionTypesChoices}
						/>
					);
				}}
			</FormDataConsumer>
		</div>
	);
};

const TriggerCard: React.FC<TriggerItemProps> = (props: TriggerItemProps) => {
	const { source, index, id, close } = props;
	const classes = useStyles();
	const form = useForm();
	debug("TriggerItem props", props);
	const clear = () => {
		form.change(source, {});
	};
	return (
		<Card className={classes.root}>
			<CardHeader
				title={"Trigger Item"}
				subheader={
					index !== undefined ? idToReadableRoute(source) : "Root"
				}
				action={
					<>
						{close && (
							<IconButton onClick={() => close()}>
								<CloseIcon />
							</IconButton>
						)}
					</>
				}
				avatar={
					<Avatar>
						<AccountTreeIcon />
					</Avatar>
				}
			/>
			<CardContent className={classes.cardGridContent}>
				<TriggerValidatorInput source={source} />
				<TriggerArgumentsInput source={source} />
				<Divider className={classes.divider} />
				<TriggerChildrenInput source={source} />
			</CardContent>
			<CardActions>
				<Button color="primary" onClick={() => close()}>
					Ok
				</Button>
				<Button
					startIcon={<DeleteSweepIcon />}
					classes={{ root: classes.clearButton }}
					onClick={() => clear()}
				>
					Clear
				</Button>
			</CardActions>
		</Card>
	);
};

const DialogTriggerItem: React.FC<TriggerItemProps> = (
	props: TriggerItemProps
) => {
	const { buttonLabel, ...restProps } = props;
	const [open, setOpen] = React.useState(false);
	const handleClose = () => {
		setOpen(false);
	};
	debug("DialogTriggerItem props", props);
	const classes = useDialogStyles();
	return (
		<>
			<Button
				className={classes.button}
				variant="outlined"
				color="primary"
				onClick={() => setOpen(true)}
			>
				{buttonLabel || "Edit"}
			</Button>
			<Dialog open={open} onClose={handleClose}>
				<DialogContent classes={{ root: classes.container }}>
					<TriggerCard {...restProps} close={handleClose} />
				</DialogContent>
			</Dialog>
		</>
	);
};

const TriggersModule: React.FC<any> = (props: any) => {
	const { source } = props;
	debug("TriggersModule props", props);
	return (
		<div>
			<Labeled label="Triggers">
				<DialogTriggerItem
					{...props}
					source={`${source}.root`}
				/>
			</Labeled>
		</div>
	);
};

export default TriggersModule;
