import React from "react";
import { Create } from "react-admin";
import AppConfigForm from "./AppConfigForm";

const AppConfigCreate: React.FC = (props: any) => {
    return (
        <Create {...props}>
            <AppConfigForm />
        </Create>
    );
}

export default AppConfigCreate;