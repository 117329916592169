import React from "react";
import { TextInput, SelectArrayInput, ArrayInput, SimpleFormIterator, SelectInput, ImageInput } from "react-admin";
import { DynamicImageInputPreview } from "../../components";
var debug = require("debug")("app:models:appconfig:metainput");

const MetaInput: React.FC<any> = (props: any) => {
    const { schema, resource, source } = props;
    const languages = schema["definitions"]["Languages"];
    debug("languages", languages)
    const languageChoices = React.useMemo(() => {
        const enunvs: string[] = languages["enum"];
        const enumNames: string[] = languages["x-enumNames"];
        return enunvs.map((en, index) => ({
            id: en,
            name: enumNames[index]
        }));
    }, [resource]);
    return (
        <>
            <ImageInput source={`${source}.logo`} label="Select Logo" accept="image/*">
                <DynamicImageInputPreview source={`${source}.logo`} completeSource={`${source}.logo`} />
            </ImageInput>
            <SelectArrayInput fullWidth source={`${source}.supportedLanguages`} label="Supported Languages" choices={languageChoices} />
            <ArrayInput source={`${source}.termsOfService`} label="Terms of Service">
                <SimpleFormIterator>
                    <SelectInput source="key" label="Language" choices={languageChoices} />
                    <TextInput source="value" label="Text" multiline fullWidth />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source={`${source}.privacyPolicy`} label="Privacy Policy">
                <SimpleFormIterator>
                    <SelectInput source="key" label="Language" choices={languageChoices} />
                    <TextInput source="value" label="Text" multiline fullWidth />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source={`${source}.about`} label="About">
                <SimpleFormIterator>
                    <SelectInput source="key" label="Language" choices={languageChoices} />
                    <TextInput source="value" label="Text" multiline fullWidth />
                </SimpleFormIterator>
            </ArrayInput>
        </>
    );
}

export default MetaInput