import MapIcon from '@material-ui/icons/Map';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from "react-admin";
import React from 'react';
import { Dialog, withStyles, AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';
import {
    isMobile
} from "react-device-detect";
import ResourcesOnMap from "./ResourcesOnMap";

class ButtonToMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
        }
    }

    buttonClick = () => {
        this.setState({
            dialogOpen: true
        })
    }

    static propTypes = {
        resource: PropTypes.string
    }

    dialogOnClose = () => {
        this.setState({
            dialogOpen: false
        })
    }

    render() {
        const {
            props,
        } = this;

        return (
            <>
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={this.dialogOnClose}
                    maxWidth="md"
                    fullWidth={true}
                    fullScreen={isMobile}
                    classes={{ paperWidthMd: props.classes.paperWidthMd }}
                    aria-labelledby="responsive-dialog-title" >
                    {isMobile &&
                        <AppBar title={"Map"} className={props.classes.appBar}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={this.dialogOnClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="title" className={props.classes.title}>
                                    Map
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    }
                    <ResourcesOnMap resource={props.resource} basePath={props.basePath} />
                </Dialog>
                <Button onClick={this.buttonClick} label="root.pages.map">
                    <MapIcon />
                </Button>
            </>
        );
    }
}

const styles = theme => ({
    paperWidthMd: {
        height: '100%'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing.unit * 2,
        color: theme.palette.primary.contrastText,
        flex: 1,
    },
    bt: {
        color: theme.palette.primary.main,
        padding: '7px 8px',
        fontSize: '0.8125rem',
        display: 'inline-flex',
        alignItems: 'center',
        minHeight: '32px',
        position: 'relative'
    },
    span: {
        width: '100%',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit'
    },
    mapLabel: {
        paddingLeft: '0.5em'
    },
    mapIcon: {
        fontSize: '20px'
    }
})
export default withStyles(styles)(translate(ButtonToMap));