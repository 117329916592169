import React from "react";
import EffectRefInput from "./EffectRefInput";
import get from "lodash/get";
import { AudioAssetRefInput } from "./AssetRefInput";
var debug = require('debug')('app:components:rjsf:effectkeyinput')

const EffectKeyInput: React.FC<any> = (props: any) => {
	const { effectType, ...restProps } = props;
	if (effectType === "Sound") return <AudioAssetRefInput {...restProps} />
	else if (effectType === "VisualEffect") return <EffectRefInput {...restProps} />
	else return <></>
}

const EffectKeyInputController: React.FC<any> = (props: any) => {
	const { formContext } = props;
	const [effectType, setEffectType] = React.useState("");
	debug("props", props);
	React.useMemo(() => {
		const scopeField = props.id.substring(0, props.id.lastIndexOf("_"));
		const [initial, dispose] = formContext.subscribe(scopeField + "_type", (key: string, value: any) => {
			debug("subscription callback", key, value);
			setEffectType(value);
		})
		debug("initial", initial);
		setEffectType(initial);
	}, [props.id])
	return <EffectKeyInput {...props} effectType={effectType} />
}

export default EffectKeyInputController;