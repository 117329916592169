import React, {FC} from 'react';
import { Edit, SimpleForm, TextInput } from "react-admin";

const PlayerEdit:FC<any> = (props:any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="key" />
            <TextInput source="name" />
            <TextInput source="description" multiline />

        </SimpleForm>
    </Edit>
);

export default PlayerEdit;