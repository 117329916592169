import React from "react";
import { Datagrid, DatagridBody, List, TextField, useQuery, Loading, Error } from 'react-admin';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Fields } from "../customExp/Fields";
import { AccessControlledFeature, PublishButton } from "../../../components";
const debug = require("debug")("app:models:projectexp:explistexpand");

const MyDatagridRow: React.FC = (props: any) => {
    const { record, resource, id, onToggleItem, children, selected, basePath } = props;
    return (
        <TableRow key={id}>
            {/* data columns based on children */}
            {React.Children.map(children, field => (
                <TableCell key={`${id}-${field.props.source}`}>
                    {React.cloneElement(field, {
                        record,
                        basePath,
                        resource,
                    })}
                </TableCell>
            ))}
        </TableRow>
    )
};

const MyDatagridBody = (props: any) => <DatagridBody {...props} row={<MyDatagridRow />} />;
const MyDatagrid = (props: any) => <Datagrid {...props} body={<MyDatagridBody />} />;

const ExpandContainer: React.FC = (props: any) => {
    const { record, id, basePath, ...restProps } = props;
    const { data, loading, error } = useQuery({
        type: "getList",
        resource: "tags/getDescendants/" + id,
        payload: {
            pagination: { page: 0, perPage: -1 },
            sort: { field: "id", order: "DESC" }
        }
    });
    if (loading) return <Loading />;
    if (error) return <>Error resolving children {error}</>;
    if (!data) return null;
    if (!data || data.length === 0) return <span>Project contains no nodes</span>
    const datagridIds = data.map((r: any) => r["id"]);
    const datagridData = data.reduce((result: any, item: any) => {
        const id = item["id"];
        result[id] = item;
        return result;
    }, {})
    debug("ids and data", datagridIds, datagridData)
    return (
        <MyDatagrid ids={datagridIds} data={datagridData} currentSort={{ field: "_id", order: "DESC" }} basePath={basePath} setSort={() => { }}>
            {Fields["name"]}
            {Fields["authors"]}
            {Fields["creationTime"]}
        </MyDatagrid>
    )
}

export default ExpandContainer;