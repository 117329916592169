import AssetCreate from './AssetCreate';
import AssetList from './AssetList';
import AssetEdit from './AssetEdit';
import Asset from '@material-ui/icons/Bookmark';
import PermMediaIcon from '@material-ui/icons/PermMedia';

export default {
    name: 'assets',
	list: AssetList,
	create: AssetCreate,
	edit: AssetEdit,
    icon: PermMediaIcon,
    options : {label:"root.resources.assets"}
}

export {default as ASSET_TYPES} from './asset-types';
export {default as AssetQuickPreviewButton} from  "./AssetQuickPreviewButton";
export {default as AssetForm} from "./AssetForm";