import React from "react";
import {
	SaveButton,
	Toolbar,
	NumberInput,
	FormDataConsumer,
	FormTab,
	BooleanInput,
	ImageInput,
	TabbedForm,
	TextInput,
	ArrayInput,
	SimpleFormIterator
} from "react-admin";
import {
	DynamicImageInputPreview,
	FormDataJsonDrawerButton
} from "../../components";
import { makeStyles } from "@material-ui/styles";
import AutocompleteInput from "../../components/AutocompleteInput";
import ResourceVariantForm from "./ResourceVariantForm";

const debug = require("debug")("app:models:office:officeform");

const auxiliaryResources = ["ApplicationConfigs", "Assets", "Effects", "Users"];

const tagPredefinedVariants = [
	"Tags",
	"GeolocatedTags",
	"PointsOfInterest",
	"UtilityPoints",
	"CommercialPoints",
	"PlayTags",
	"Projects",
	"Points"
];

const useArrayInputStyles = makeStyles({
	form: { display: "flex", placeItems: "baseline" }
});

const useToolbarStyles = makeStyles({
	toolbar: {
		display: "flex",
		justifyContent: "space-between"
	}
});

const CustomToolbar: React.FC<any> = (props: any) => {
	const { jsonViewer, ...restProps } = props;
	const classes = useToolbarStyles();
	return (
		<Toolbar {...restProps} classes={classes}>
			<SaveButton />
			{jsonViewer && <FormDataJsonDrawerButton />}
		</Toolbar>
	);
};

const Form: React.FC<any> = (props: any) => {
	const { resource } = props;
	const simpleFormIteratorClasses = useArrayInputStyles();
	return (
		<TabbedForm {...props} toolbar={<CustomToolbar jsonViewer={true} />}>
			<FormTab label="Basic">
				<ImageInput source="icon" label="Select Icon" accept="image/*">
					<DynamicImageInputPreview
						source="icon"
						completeSource="icon"
					/>
				</ImageInput>
				<TextInput source="name" />
				<TextInput source="title" />
			</FormTab>
			<FormTab label="server">
				<TextInput source="apiUrl"/>
				<TextInput source="filesApiUrl"/>
			</FormTab>
			<FormTab label="Data">
				<BooleanInput source="hasDashboard" />
				<TextInput source="tagContextName" />
				<ArrayInput source="resources">
					<SimpleFormIterator classes={simpleFormIteratorClasses}>
						<AutocompleteInput
							source="key"
							label="Resource"
							choices={[
								...tagPredefinedVariants,
								...auxiliaryResources
							]}
							allowEmpty
						/>
						<FormDataConsumer>
							{(props: any) => {
                                const { scopedFormData, getSource, id } = props;
                                if(!scopedFormData) return undefined;
								const { key } = scopedFormData;
								if (
									!key ||
									[
										...tagPredefinedVariants,
										...auxiliaryResources
									].includes(key)
								) {
									return undefined;
								}
								return (
									<ResourceVariantForm
										buttonLabel="Edit"
										source={getSource("value")}
									/>
								);
							}}
						</FormDataConsumer>
					</SimpleFormIterator>
				</ArrayInput>
			</FormTab>
			<FormTab label="Geolocation">
				<TextInput
					source="geolocation.googleMapsApiKey"
					multiline
					fullWidth={true}
				/>
				<NumberInput source="geolocation.latitude" />
				<NumberInput source="geolocation.longitude" />
			</FormTab>
		</TabbedForm>
	);
};

export default Form;
