import { CREATE, UPDATE, GET_ONE } from "react-admin";
import { Dict } from "./../types";
import file from "./file";
import get from "lodash/get";
import set from "lodash/set";
import omit from "lodash/omit";
import remove from "lodash/remove";
import clone from "lodash/cloneDeep";
import dataProvider from ".";
import { ModuleSources } from "../ariabo.config";
var debug = require("debug")("app:dataprovider:sanitas");

const keyValuePairArrayToDictionary = (arr: any[]) => {
	const og = {};
	arr.reduce((obj, val) => {
		obj[val["key"]] = val["value"];
		return obj;
	}, og);
	return og;
};

const dictionaryToKeyValuePairArray = (obj: any) => {
	return Object.entries(obj).map(([key, value]) => ({ key, value }));
};

export const appConfigPreProcessor = () => {
	return async (record: Dict) => {
		var debug = require("debug")(
			"app:dataprovider:sanitas:appconfigpreprocessor"
		);
		var general = record["generalData"];
		if (!general) return Promise.resolve(record);
		debug("general", general);
		if (Array.isArray(general["termsOfService"]))
			general["termsOfService"] = keyValuePairArrayToDictionary(
				general["termsOfService"]
			);
		if (Array.isArray(general["privacyPolicy"]))
			general["privacyPolicy"] = keyValuePairArrayToDictionary(
				general["privacyPolicy"]
			);
		if (Array.isArray(general["about"]))
			general["about"] = keyValuePairArrayToDictionary(general["about"]);
		return Promise.resolve(record);
	};
};

export const globalStadiumPostProcessor = () => {
	return async (record: Dict) => {
		var debug = require("debug")(
			"app:dataprovider:sanitas:globalStadiumProcessor"
		);
		debug("globalStadiumPostProcessor",record);
		const globalStadium = record["modules"][ModuleSources.GlobalStadiumEventModule];
		if (!globalStadium) return Promise.resolve(record);

		const globalStadiumActors = record["modules"][ModuleSources.GlobalStadiumEventModule]["actors"];
		if (!globalStadiumActors) return Promise.resolve(record);

		if (Array.isArray(globalStadiumActors))
			globalStadium["actors"] = keyValuePairArrayToDictionary(
				globalStadiumActors
			);
		return Promise.resolve(record);
	};
};

export const officePreProcessor = () => {
	return async (record: Dict) => {
		var debug = require("debug")(
			"app:dataprovider:sanitas:officepreprocessor"
		);
		const resources = record["resources"];
		if (!resources) return Promise.resolve(record);

		if (Array.isArray(resources))
			record["resources"] = keyValuePairArrayToDictionary(
				resources
			);
		return Promise.resolve(record);
	};
};

export const appConfigPostProcessor = () => {
	return async (record: Dict) => {
		var debug = require("debug")(
			"app:dataprovider:sanitas:appconfigpostprocessor"
		);
		var general = record["generalData"];
		if (!general) return Promise.resolve(record);
		if (general["termsOfService"])
			general["termsOfService"] = dictionaryToKeyValuePairArray(
				general["termsOfService"]
			);
		if (general["privacyPolicy"])
			general["privacyPolicy"] = dictionaryToKeyValuePairArray(
				general["privacyPolicy"]
			);
		if (general["about"])
			general["about"] = dictionaryToKeyValuePairArray(general["about"]);
		debug("general", general);
		return Promise.resolve(record);
	};
};
export const globalStadiumPreProcessor = () => {
	return async (record: Dict) => {
		var debug = require("debug")(
			"app:dataprovider:sanitas:globalStadiumPostPRocessor"
		);
		var globalStadium = record["modules"][ModuleSources.GlobalStadiumEventModule];
		if (!globalStadium) return Promise.resolve(record);
		if (globalStadium["actors"])
			globalStadium["actors"] = dictionaryToKeyValuePairArray(
				globalStadium["actors"]
			);
		debug("globalStadium", globalStadium);
		return Promise.resolve(record);
	};
};

export const officePostProcessor = () => {
	return async (record: Dict) => {
		var debug = require("debug")(
			"app:dataprovider:sanitas:officepostprocessor"
		);
		const resources = record["resources"];
		if (!resources) return Promise.resolve(record);
		record["resources"] = dictionaryToKeyValuePairArray(
			resources
		);
		return Promise.resolve(record);
	};
};

export const fileProcessor = (field: string, fileContainer: string) => {
	return async (record: Dict) => {
		var debug = require("debug")("app:dataprovider:sanitas:fileprocessor");
		debug("Record, source", record, field);
		const recordValue = get(record, field);
		if (!recordValue || !recordValue.rawFile)
			return Promise.resolve(record);
		const fileItem = recordValue.rawFile; //used to be type File but isn't anymore
		debug("Will initiate file upload process with file", fileItem);

		const fileName = file.createFileName(fileItem.path); //used to be fileItem.name, changed??
		const response = await file.postWithAxios(
			fileContainer,
			fileItem,
			fileName
		);
		debug("File upload response", response);
		if (
			response.statusText === "OK" ||
			(response.status < 300 && response.status >= 200)
		) {
			const fileProcessedLink = file.GetDownloadLink(
				fileContainer,
				fileName
			);
			debug("fileProcessedLink", fileProcessedLink);
			set(record, field, fileProcessedLink);
			return Promise.resolve(record);
		}
		return Promise.reject(
			`File upload failed with response code ${response.status}`
		);
	};
};

export const resourceCollectionProcessor = (resourcesPath: string) => {
	return async (record: Dict) => {
		var debug = require("debug")(
			"app:dataprovider:sanitas:resourcesProcessor"
		);
		const resources = get(record, resourcesPath);
		if (!resources) return Promise.resolve(record);
		const assets = resources.assets || [];
		const map = resources.map;
		remove(assets, (asset: string) => {
			const refs = map[asset];
			if (refs) {
				const removedValues = remove(refs, (ref: string) => {
					return (
						get(record, ref, undefined) === undefined ||
						get(record, ref, undefined) !== asset
					);
				});
				debug("removed items", removedValues);
			}
			return refs === undefined || refs.length === 0;
		});
		Object.keys(map)
			.filter(k => map[k] === undefined || map[k].length === 0)
			.forEach(k => delete map[k]);

		return Promise.resolve(record);
	};
};

export const expHierarchyProcessor = (field: string) => {
	const mongoIdRgx = /^[a-f\d]{24}$/i;
	return async (record: Dict) => {
		const baseChildren = record["children"];
		if (baseChildren)
			set(record, "modules.hierarchy.children", baseChildren);
		const children = get(record, "modules.hierarchy.children");
		if (!children || children.every((child:any)=>typeof child === "string")) return Promise.resolve(record);
		// const children_ids:string[] = [];
		for (let i = 0; i < children.length; i++) {
			const child = children[i];
			if(typeof child==="string")continue;
			const alreadyExists = mongoIdRgx.test(child.id);
			let id = undefined;
			if (alreadyExists) {
				const update = await dataProvider().update("tags", {
					previousData: child,
					data: child,
					id: child.id
				});
				id = child.id;
			} else {
				const create = await dataProvider().create("tags", {
					data: omit(child, "id")
				});
				const data = await create.data;
				id = data.id;
			}
			children[i] = id;
		}
		// dataProvider()(CREATE, )
		return Promise.resolve(record);
	};
};

export const expHierarchyPreProcessor = (field: string) => {
	var debug = require("debug")(
		"app:dataprovider:sanitas:expHierarchypreprocessor"
	);
	const mongoIdRgx = /^[a-f\d]{24}$/i;
	return async (record: Dict) => {
		const moduleChildren = get(record, "modules.hierarchy.children");
		if (moduleChildren) set(record, "children", [...moduleChildren]);
		const children = record.children;
		if (!children) return Promise.resolve(record);
		for (let i = 0; i < children.length; i++) {
			const child_id = children[i];
			const get_one = await dataProvider().getOne("tags", {
				id: child_id
			});
			const child = get_one.data;
			children[i] = child;
		}
		debug("finished", record);
		return Promise.resolve(record);
	};
};

export const postprocessors: {
	[key: string]: ((record: Dict) => Promise<Dict>)[];
} = {
	// projectExp: [resourceCollectionProcessor("resources")], //why was I applying this just here??
	applicationConfigs: [
		appConfigPreProcessor(),
		fileProcessor("generalData.logo", "applicationConfigs")
	],
	offices: [fileProcessor("icon", "offices"), officePreProcessor()],
	projectTags:[expHierarchyProcessor("children")],
	tags: [
		fileProcessor("img", "tags"),
		resourceCollectionProcessor("resources"),
		globalStadiumPostProcessor()
	],
	assets: [fileProcessor("content", "assets")],
	effects: [fileProcessor("icon", "effects")]
};

export const preprocessors: {
	[key: string]: ((record: Dict) => Promise<Dict>)[];
} = {
	projectTags:[expHierarchyPreProcessor("children")], //this might break project exps but this is the lesser evil for now
	tags: [globalStadiumPreProcessor()],
	applicationConfigs: [appConfigPostProcessor()],
	offices: [officePostProcessor()]
};

async function wait(ms: number) {
	return new Promise(resolve => {
		setTimeout(resolve, ms);
	});
}

export const postProcess = async (resource: string, record: Dict) => {
	if (!resource || !record)
		return Promise.reject("Yo wtf the resource or record is null");
	const processors = postprocessors[resource];
	const objClone = clone(record);
	debug("will post process", record);
	debug("clone, source", objClone, record, resource);
	debug(processors);
	if (processors) {
		for (let i = 0; i < processors.length; i++) {
			const func = processors[i];
			await func(objClone);
		}
	}
	return Promise.resolve(objClone);
};

export const preProcess = async (resource: string, record: Dict) => {
	if (!resource || !record)
		return Promise.reject("Yo wtf the resource or record is null");
	const processors = preprocessors[resource];
	const objClone = clone(record);
	debug(processors);
	if (processors) {
		for (let i = 0; i < processors.length; i++) {
			const func = processors[i];
			await func(objClone);
		}
	}
	return Promise.resolve(objClone);
};

export const preProcessMany = async (resource: string, records: Dict[]) => {
	if (!resource || !records)
		return Promise.reject("Yo wtf the resource or record is null");
	const processedRecords: Dict[] = [];
	for (let i = 0; i < records.length; i++) {
		const procced = await preProcess(resource, records[i]);
		processedRecords.push(procced);
	}
	return Promise.resolve(processedRecords);
};
