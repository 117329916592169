export default [
	{
		title: "Vanilla Tags",
		img: "https://image.freepik.com/free-vector/planning-analysis-business-plan-concept_140689-189.jpg",
		data: {
			tagContextName:"root.resources.tags",
			hasDashboard:false,
			resources: [
				{
					key: "Tags",
					value: {}
				},
				{
					key: "Assets",
					value: {}
				},
				{
					key: "Users",
					value: {}
				}
			]
		}
	},
	{
		title: "Smart City",
		img: "https://image.freepik.com/free-vector/smart-city-man-user-smartphone-with-residential-smart-city-infrastructure_33099-164.jpg",
		data: {
			tagContextName:"root.resources.points",
			hasDashboard:true,
			resources: [{
				key: "Points",
				value: {
					"base": null,
					"dataform": null,
					"icon": null,
					"title": null
				}
			}, {
				key: "PointsOfInterest",
				value: {
					"base": null,
					"dataform": null,
					"icon": null,
					"title": null
				}
			}, {
				key: "UtilityPoints",
				value: {
					"base": null,
					"dataform": null,
					"icon": null,
					"title": null
				}
			}, {
				key: "Assets",
				value: {
					"base": null,
					"dataform": null,
					"icon": null,
					"title": null
				}
			}, {
				key: "Users",
				value: {
					"base": null,
					"dataform": null,
					"icon": null,
					"title": null
				}
			}]
		}

	},
	{
		title: "Media Console",
		img: "https://image.freepik.com/free-vector/business-ideas-tree-cartoon-woman-ladder_82574-10663.jpg",
		data: {
			tagContextName:"root.resources.tags",
			hasDashboard:false,
			resources: [{
				key: "ApplicationConfigs",
				value: {
					"base": null,
					"dataform": null,
					"icon": null,
					"title": null
				}
			}, {
				key: "Tags",
				value: {
					"base": null,
					"dataform": null,
					"icon": null,
					"title": null
				}
			}, {
				key: "Projects",
				value: {
					"base": null,
					"dataform": null,
					"icon": null,
					"title": null
				}
			}, {
				key: "Assets",
				value: {
					"base": null,
					"dataform": null,
					"icon": null,
					"title": null
				}
			}, {
				key: "Users",
				value: {
					"base": null,
					"dataform": null,
					"icon": null,
					"title": null
				}
			}]
		}
	},
	{
		title: "No Template",
		img: "https://image.freepik.com/free-vector/blank-canvas-concept-illustration_114360-1300.jpg",
		data: {
			tagContextName:"root.resources.tags",
			hasDashboard:false
		}
	}
]