import * as React from 'react'
import { 
	ImageInput,
	ImageField,
	REDUX_FORM_NAME
} from 'react-admin';
import { IconButton, withStyles, Theme, createStyles } from '@material-ui/core';
import Copy from '@material-ui/icons/FileCopy';
import Delete from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import {changeStagedImage} from '../customActions';
import {change} from 'redux-form';
import _ from 'lodash';
var debug = require('debug')('app:components:copyableimageinput')

interface IProps{
    [key:string]:any
}
interface IState{
    files:FileList|null
}

class copyAbleImageInput extends React.Component<IProps, IState> {

	onCopyClick = () =>{
        let baseImages =  _.get(this.props.formValues, this.props.source) || [];
        let stagedImages =Array.from(this.props.stagedImage).map((i:any)=>({rawFile:i, content:i.preview}))
        let newImages = stagedImages.filter(n=>!baseImages.some((b:any)=>b.content===n.content))
		this.props.change(REDUX_FORM_NAME, this.props.source, baseImages.concat(newImages))
    }
    
    onClearClick = () =>{
        this.props.change(REDUX_FORM_NAME, this.props.source, [])
    }

	onFilesChanged = (files:FileList|null) =>{
        debug("Files received", files)
		if(files && files.length>0){
            this.props.changeStagedImage({image:files})
        }
        this.setState({files});
	}

    render() {
		const {
            classes
		} = this.props
        return (
            <div className={classes.imagesDiv}>
                <ImageInput {...this.props} multiple={true} classes={_.pick(classes, ['dropZone', 'removeButton'])}   options={
                    {
                        onChange:(event:React.ChangeEvent<HTMLInputElement>)=>
                            {
                                this.onFilesChanged(event.target.files)
                            }
                    }}>
                    <ImageField source='content'/>
                </ImageInput>
                <IconButton color='primary' onClick={this.onCopyClick}>
                    <Copy/>
                </IconButton>
                <IconButton className={classes.deleteButton} onClick={this.onClearClick}>
                    <Delete/>
                </IconButton>
            </div>
        );
    }
}

const styles = (theme:Theme) =>createStyles({
    deleteButton: {
        color: theme.palette.error.main
    },
	imagesDiv:{
		alignSelf:"baseline",
		width:"100%"
	},
	dropZone:{
        color: "#999",
        padding: "0.1rem",
		cursor: "pointer",
        background: "#efefef",
		textAlign: "center",
	},
})

const mapStateToProps = (state:any) => ({
    stagedImage: state.stagedImage,
    formValues:  state.form['record-form']? state.form['record-form'].values : {}
});

export default connect(mapStateToProps, {changeStagedImage, change})(withStyles(styles)(copyAbleImageInput))