import React, { Component } from 'react';
import {
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    ArrayField,
    SingleFieldList,
    FunctionField
} from 'react-admin';
import { Chip } from '@material-ui/core';



class UserShow extends Component {
    componentDidMount() {
        // document.title = Config.app.name + ' - User';
    }

    render() {
        return (
            <Show title="User Info" {...this.props}>
                <TabbedShowLayout>
                    <Tab label="User Info">
                        <TextField source="id" />
                        <TextField source="userName" />
                        <TextField source="email" />
                    </Tab>
                    <Tab label="Role" path="security">
                        <ArrayField label="Roles" source="roles">
                            <SingleFieldList>
                                <FunctionField label="Role" render={attr => <Chip size="small" label={attr} />} />
                                {/* <ChipField source="name"/> */}
                            </SingleFieldList>
                        </ArrayField>          
                    </Tab>
                </TabbedShowLayout>
            </Show>
        );
    }
}

export default UserShow;