import React from "react";
import { useConfig } from "../hooks";
import get from "lodash/get";

const ConfigLoader: React.FC<any> = (props: any) => {
	const { resource, children, jsonSource, ...restProps } = props;
	const config = useConfig();
	const dataformprops = get(
		config.resources[resource],
		"dataformprops",
		{}
	) as any;
	const getPropsForSource = (source:string)=>{
		console.log("Getting", source,dataformprops)
		return get(dataformprops, source, {}).props ||  {}
	}
	console.log(props)
	return (
		<>
			{React.Children.map(children, (child) =>
				React.cloneElement(child, { ...child.props, ...restProps,...getPropsForSource(jsonSource||child.props.source) })
			)}
		</>
	);
};

export default ConfigLoader;
