import { withStyles } from '@material-ui/core/styles'
import React from "react";
import { ImageInput,ImageField, showNotification, translate } from "react-admin";
import reduxForm from "redux-form/lib/reduxForm";
import Card from "@material-ui/core/Card/Card";
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from "@material-ui/core/Divider/Divider";
import { connect } from "react-redux";
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { fileServer } from "../dataProvider";
import { MAIN_MAP_NAME } from "../config";
import PropTypes from "prop-types";
var debug = require('debug')('app:map:mapmain')

const styles = theme => ({
    root: {
        width: '100%',
    },
    paperMain:{
    },
    cardMain:{
        margin:"1em",
    },
    formMain:{
        margin:"1em"
    },
    column: {
        flexBasis: '33.33%',
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    details: {
        alignItems: 'center',
    },
    button: {
        margin: theme.spacing.unit,
    },  
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },  
    imageActive:{
        width:"100%"
    }
})

//still need to figure out whats up with these
// const observableImgInput=observable({
//     img:{},
//     name:""
// })
//

class MapMain extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            fileInput:{},
            fileActive:fileServer.GetDownloadLink("maps", MAIN_MAP_NAME),
            uploading:false,
            imgWidth:"",
            imgHeight:""
        }
    }
    onImageInput = (files) =>{
        let newFile = files.length>0? files[0] : null;
        this.setState({fileInput:newFile})
    }

    handleMySubmit = (values, someAction, theOtherPropsIGuess, clear) =>{
        this.setState({uploading:true})
        fileServer.post("maps", values["map"].rawFile, MAIN_MAP_NAME).then(()=>{
            debug("done unploading");
            this.setState({uploading:false})
            let newMap = fileServer.GetDownloadLink("maps", MAIN_MAP_NAME);
            this.setState({fileActive:newMap})
            this.props.showNotification("New map uploaded")

        })
    }
    static propTyeps={
        showNotification:PropTypes.func
    }
    render() {
        const {classes, handleSubmit, pristine, submitting, clearFields, translate} = this.props;
        const {fileActive} =this.state;

        var img = new Image();
        var setstate= (imgWidth, imgHeight) =>{
            this.setState({imgWidth,imgHeight})
        }
        img.onload = function() {
            setstate(this.width, this.height)
        }
        img.src = fileActive;

        return (
            <div className={classes.root}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <div className={classes.column}>
                                <Typography className={classes.heading}>{translate('root.action.upload_new_map')}</Typography>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.details} >
                                <form onSubmit={handleSubmit((...args)=>this.handleMySubmit(...args, clearFields))} >
                                    <ImageInput /*onChange={this.onImageInput}*/ source='map' accept="image/jpeg, image/png" options={{onChange:(event)=>
                                        {
                                            this.onImageInput(event.target.files)
                                        }}} label={translate('root.message.map_image')} 
                                    >
                                        <ImageField width="2000px" source='file' title="name" />
                                    </ImageInput>
                                    {this.state.fileInput && !submitting && !this.state.uploading &&
                                        <Button type="submit" disabled={pristine || submitting} variant="contained" size="small" className={classes.button}>
                                            <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                            Save
                                        </Button>
                                    }
                                    {(submitting || this.state.uploading) &&
                                        <CircularProgress />
                                    }
                                </form>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <Divider/>
                    <ExpansionPanel className={classes.expansionPanel} defaultExpanded>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <div className={classes.column}>
                                <Typography className={classes.heading}>{translate('root.message.current_map')}</Typography>
                            </div>
                            <div className={classes.column}>
                                <Typography className={classes.secondaryHeading}>{this.state.imgWidth}x{this.state.imgHeight}</Typography>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.details} >
                            <Card>
                                <img id={fileActive} src={fileActive} alt=""/>
                            </Card>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
            </div>
        );
    }
}


const MapForm = reduxForm({
    form:"mapForm"
})(connect(null, {showNotification})(withStyles(styles)(translate(MapMain))))

export default MapForm