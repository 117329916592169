import { Maps } from "../../components";
import React from "react";
import { Record } from "ra-core";
import { Geohash } from "../../helpers";
import { Marker, MarkerClusterer } from "@react-google-maps/api";
import { Clusterer } from "@react-google-maps/marker-clusterer";
import get from "lodash/get";
import { useConfig, useAuthorizedDataFetch } from "../../hooks";
import QueryString from "query-string";

var debug = require("debug")("app:dashboard:map:main");

const payload = {
    pagination: { page: 0, perPage: 0 },
    sort: { field: "id", order: "ASC" }
};

const Dashboard: React.FC<any> = (props: any) => {
    const config = useConfig();
    const { data, loading, error } = useAuthorizedDataFetch("tags?" + QueryString.stringify({ filter: JSON.stringify({ "modules.geolocation": { "$ne": null } }) }))

    return (
        <div style={{ height: "93.4vh" }}>
            <Maps
                center={{
                    lat: config.geolocation.latitude,
                    lng: config.geolocation.longitude
                }}
            >
                {(data && data.length) &&
                    <MarkerClusterer
                        options={{
                            imagePath:
                                "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
                        }}
                        maxZoom={21}
                    >
                        {(clusterer: Clusterer) =>
                            data
                                .filter((r: Record) => get(r, "modules.geolocation.locations"))
                                .map((record: Record) => {
                                    const locations = get(record, "modules.geolocation.locations");
                                    const target = locations.find((loc: any) => loc["geoHash"] && Geohash.isValid(loc["geoHash"]))
                                    if (!target) return undefined;
                                    const latLong = Geohash.decode(
                                        target["geoHash"]
                                    );
                                    return (
                                        <Marker
                                            key={`${record["id"]}.geoHash`}
                                            position={{
                                                lat: latLong.lat,
                                                lng: latLong.lon
                                            }}
                                            label={record["name"]}
                                            clusterer={clusterer}
                                        />
                                    );
                                })
                        }
                    </MarkerClusterer>
                }
            </Maps>
        </div>
    );
};
export default Dashboard;
