import React from 'react';
import { ImageInput, ImageField } from "react-admin";
import { withStyles, createStyles } from '@material-ui/core/styles';
import pick from 'lodash/pick';
import { BasedFileInputProps } from './types';
import { Dict } from '../types';
import { useForm } from 'react-final-form';
import get from 'lodash/get';
import set from 'lodash/set';

var debug = require('debug')('app:components:basedimagaeinput')

export const DynamicImageInputPreview = (props: Partial<BasedFileInputProps>) => {
    const { completeSource, source } = props;
    const form = useForm();
    const formState = form.getState();
    const formValues = formState.values;
    const [initialStrToRecord, initialStrValue] = React.useMemo(() => {
        if(!completeSource || !source)return [undefined, undefined];
        const initial = get(formValues, completeSource);
        if(typeof initial==="object" && initial===props.record) return [props.record, props.record]
        const initialRecord = {};
        set(initialRecord, source, initial);
        return [initialRecord, initial]; 
    }, [completeSource]);
    debug("initial", initialStrValue);
    debug("source, completeSource", source,completeSource);
    debug("record, initialrecord", props.record, initialStrToRecord);
    return <ImageField {...props} record={initialStrValue===props.record? initialStrToRecord : props.record } />
}

const MyImageInput = (props: Dict) => {
    const { source } = props;
    const form = useForm();
    const formState = form.getState();
    const initialStrToRecord = React.useMemo(() => {
        const initial = get(formState, source);
        const initialRecord = {};
        set(initialRecord, source, initial);
        return initialRecord;
    }, [source]);
    return (
        <div className={props.classes.inputDiv}>
            <ImageInput {...props} classes={pick(props.classes, ['dropZone', 'removeButton'])} options={{ className: props.classes.dropZone, ...props.options }}>
                <DynamicImageInputPreview completeSource={source} classes={props.classes}  />
            </ImageInput>
        </div>
    )
}
const styles = createStyles({
    image: {
        maxHeight: '10rem',
    },
    av_audioPlayer: {
        float: "left",
        marginLeft: '0.5rem'
    },
    inputDiv: {
        alignSelf: "baseline",
        width: "100%"
    },
    removeButton: {
        marginTop: "0.5rem"
    },
    dropZone: {
        color: "#999",
        cursor: "pointer",
        padding: "0.1rem",
        background: "#efefef",
        textAlign: "center",
    }
})
export default withStyles(styles)(MyImageInput);