import React from "react";
import { Inputs } from "../customExp/Fields";
import { useForm, FormSpy } from "react-final-form";
import FullScreen from "react-full-screen";
import TreeEditor from "../../../components/vxCollapsibleTree/TreeEditor";
import { engineName } from "react-device-detect";
import {
    Theme,
    createStyles,
    Card,
    CardContent,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    CardHeader
} from "@material-ui/core";
import "./fullscreen.css";
import classnames from "classnames";
import {
    TreeNode
} from "../../../components/vxCollapsibleTree/types";
import { getPath } from "../../../components/vxCollapsibleTree/utils";
import { makeStyles } from "@material-ui/styles";
import { FormDataConsumer, Loading, useQuery, ArrayInput, SimpleFormIterator } from "react-admin";
import ModulesUISchemas from "../../modules/uiSchemas";
import { InputSchemaForm, PlayerInput, ChipInput, TriggersModule } from "../../../components";
import { hierarchy, HierarchyNode } from "d3-hierarchy";
import get from "lodash/get";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactJson from 'react-json-view';
import { useModuleSchemas } from "../../../hooks";
import { ModuleCollectionInput } from "../customExp/Modules";
import { ModuleTypes } from "../../../types";
var debug = require("debug")("app:models:projectexp:projectform");

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "start"
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        initialDiv: {
            width: "inherit",
            // display: 'flex',
            minHeight:
                engineName === "Gecko"
                    ? "-moz-available"
                    : "-webkit-fill-available"
            // flexDirection:'row',
            // overflow: 'auto'
            // backgroundColor:"red"
        },
        innerPaper: {
            width: "100%",
            display: "flex"
            // backgroundColor:"blue"
        },
        scaled_tree_root: {
            width: "auto",
            height: "80vh",
            margin: "0px",
            backgroundColor: "inherit",
            resize: "none",
            overflow: "unset"
        },
        expansionPanelDetails: {
            display: "flex",
            flexDirection: "column"
        },
        appDataCard: {
            gridRowGap: "1rem",
            flexWrap: "wrap",
            gridTemplateColumns: "repeat(3,10fr)",
            display: "grid"
        },
        expansionPanel: {
            minWidth: "30vw"
        }
    })
);

const Form: React.FC<any> = (props: any) => {
    const { record, modules } = props;

    const classes = useStyles();
    const [tree_fullscreen, setTree_Fullscreen] = React.useState(false);
    const [index, setIndex] = React.useState("");
    const [parentIndex, setParentIndex] = React.useState("");
    //const modules_schemas = useModuleSchemas();
    const getSource = (source: string, sourceindex = index) => {
        if (sourceindex) {
            return sourceindex + "." + source;
        } else return source;
    };

    const form = useForm();
    const formValues = form.getState().values;
    const stageNode = (node: TreeNode) => {
        debug("stage node", node);
        let nodeSrc = "";
        let parentSrc = "";
        nodeSrc = getPath(node) || "";
        parentSrc = nodeSrc.substring(0, nodeSrc.lastIndexOf("."));
        setIndex(nodeSrc);
        setParentIndex(parentSrc);
        // form.change("nodeSrc", nodeSrc);
    };

    const modulesSchemas = useModuleSchemas();

    if (!modulesSchemas) return <Loading />;

    if (formValues.children && formValues.children.length > 0 && formValues.children.every((c: any) => typeof c === "string")) {
        if (record.children.every((c: any) => typeof c === "object")) {
            form.change("children", record.children)
        }
        return <Loading />;
    }

    const tree = hierarchy(formValues);
    debug(tree);
    debug("rerender", index);
    const descendants = tree.descendants();
    return (
        <div className={classes.root}>
            <FullScreen
                enabled={tree_fullscreen}
                onChange={isFull => setTree_Fullscreen(isFull)}
            >
                <div className={classnames("full-screenable-node")}>
                    <FormSpy>
                        {(props: any) => {
                            return (<TreeEditor onSelected={stageNode} classes={classes} onCanvasDoubleClick={() => setTree_Fullscreen(!tree_fullscreen)} expandAllByDefault={true} />);
                        }}
                    </FormSpy>
                </div>
            </FullScreen>
            <div>
                <ExpansionPanel className={classes.expansionPanel} defaultExpanded>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading} noWrap={true}>Form - {get(formValues, index, formValues).name}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                        className={classes.expansionPanelDetails}
                    >
                        <FormDataConsumer>
                            {(props: any) => {
                                const { formData, ...rest } = props;
                                return descendants.map(
                                    (node: HierarchyNode<any>) =>
                                        get(formValues, index, formValues).id ===
                                        node.data.id && (
                                            <>
                                                {React.createElement(Inputs["img"]!, { source: getSource("img"), label: "Icon", })}
                                                {React.createElement(Inputs["name"]!, { source: getSource("name"), label: "Name" })}
                                                {React.createElement(Inputs["content"]!, { source: getSource("content"), label: "root.resources.assets" })}
                                                {React.createElement(Inputs["attributes"]!, { source: getSource("modules.classification.attributes"), label: "root.properties.attributes", })}
                                                <ModuleCollectionInput source={getSource("modules")} modules={modules} />
                                            </>
                                        )
                                );
                            }}
                        </FormDataConsumer>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        </div>
    );
};

export default Form;
