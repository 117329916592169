import React from "react";
import { featureAcess } from "../ariabo.config";
var debug = require('debug')('app:components:accesscontrolledfeature')

const AccessControlledFeature:React.FC<any> = (props) =>{
    const {children, feature,...restProps} = props;
    if(!featureAcess(feature, props.record)){
        return <></>
    };
    return(
        <>
            {React.Children.map(children, (child, index)=>React.cloneElement(child, restProps))}
        </>
    )
}

export default AccessControlledFeature;