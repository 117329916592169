import React from "react";
import { ModuleTypes } from "../../modules/types";
import {
	Card,
	CardHeader,
	CardContent,
	makeStyles,
	Theme,
	createStyles,
	Avatar,
	CircularProgress,
} from "@material-ui/core";
import { ModuleLabels, ModuleSources } from "../../../ariabo.config";
import ExtensionIcon from "@material-ui/icons/Extension";
import { useModuleSchema } from "../../../hooks";
import ModulesUISchemas from "../../modules/uiSchemas";
import {
	InputSchemaForm,
	TriggersModule,
	PlayersModuleInput,
	HierarchyModuleInput,
	GlobalStadiumEventModuleInput,
	DataClassificationModuleInput,
} from "../../../components";
import intersection from "lodash/intersection";
import difference from "lodash/difference";
import { IModuleRecord } from "../../../types";

const debug = require("debug")("app:models:customExp:modules");

interface ModuleInputProps {
	module: ModuleTypes;
	source: string;
	moduleProps?: { [key: string]: any };
	resource: string;
	[key: string]: any;
}

export const ModuleInput: React.FC<ModuleInputProps> = (
	props: ModuleInputProps
) => {
	const { module, source } = props;

	const schema = useModuleSchema(module);
	if (!schema) return <CircularProgress color="primary" />;
	return (
		<InputSchemaForm
			label={ModuleLabels[module]}
			schema={schema}
			uiSchema={ModulesUISchemas[module]}
			source={source}
		/>
	);
};

interface ModuleCollectionInputProps {
	source: string;
	modules: ModuleTypes[];
	[key: string]: any;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			alignItems: "start",
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
			fontWeight: theme.typography.fontWeightRegular,
		},
		innerPaper: {
			width: "100%",
			display: "flex",
			// backgroundColor:"blue"
		},
		scaled_tree_root: {
			width: "auto",
			height: "80vh",
			margin: "0px",
			backgroundColor: "inherit",
			resize: "none",
			overflow: "unset",
		},
		expansionPanelDetails: {
			display: "flex",
			flexDirection: "column",
		},
		appDataCard: {
			gridRowGap: "1rem",
			flexWrap: "wrap",
			gridTemplateColumns: "repeat(3,10fr)",
			display: "grid",
		},
		expansionPanel: {
			minWidth: "30vw",
		},
	})
);

const SpecialModules: ModuleTypes[] = [
	ModuleTypes.TriggersModule,
	ModuleTypes.PlayersModule,
	ModuleTypes.HierarchyModule,
	ModuleTypes.GlobalStadiumEventModule,
	ModuleTypes.DataClassificationModule,
];

const SpecialInputComponents: Partial<IModuleRecord<
	React.FC<ModuleInputProps>
>> = {
	TriggersModule: (props: ModuleInputProps) => {
		const { source, module, resource } = props;
		return <TriggersModule source={source} resource={resource} />;
	},
	PlayersModule: (props: ModuleInputProps) => {
		const { source, module, resource } = props;
		return <PlayersModuleInput source={`${source}.configurations`} resource={resource} />;
	},
	HierarchyModule: (props: ModuleInputProps) => {
		const { source, module, resource } = props;
		return <HierarchyModuleInput source={source} resource={resource} />;
	},
	GlobalStadiumEventModule: (props: ModuleInputProps) => {
		const { source, module, resource } = props;
		return (
			<GlobalStadiumEventModuleInput
				source={source}
				resource={resource}
			/>
		);
	},
	DataClassificationModule: (props: ModuleInputProps) => {
		const { source, module, resource } = props;
		return (
			<DataClassificationModuleInput
				source={source}
				resource={resource}
			/>
		);
	},
};

export const ModuleCollectionInput: React.FC<ModuleCollectionInputProps> = (
	props: ModuleCollectionInputProps
) => {
	const classes = useStyles();
	const { modules, label, source, resource } = props;
	debug("Modules", modules);
	const genericInputModules = difference(modules, SpecialModules);
	debug("Generic Modules", genericInputModules);
	const specialInputModules = intersection(modules, SpecialModules);
	debug("Special Modules", specialInputModules);
	return (
		<>
			{modules.length > 1 ? (
				<Card>
					<CardHeader
						title={label || "Modules"}
						avatar={
							<Avatar>
								<ExtensionIcon />
							</Avatar>
						}
						titleTypographyProps={{ variant: "subtitle1" }}
					/>
					<CardContent>
						<div className={classes.appDataCard}>
							{genericInputModules.map((m) => (
								<ModuleInput
									module={m}
									source={`${source}.${ModuleSources[m]}`}
									resource={resource}
								/>
							))}
						</div>
						{specialInputModules.map((m) => {
							const Component = SpecialInputComponents[m];
							if (!Component) return undefined;
							return (
								<Component
									module={m}
									source={`${source}.${ModuleSources[m]}`}
									resource={resource}
								/>
							);
						})}
					</CardContent>
				</Card>
			) : (
				<>
					{!!genericInputModules.length && (
						<ModuleInput
							module={genericInputModules[0]}
							source={`${source}.${
								ModuleSources[genericInputModules[0]]
							}`}
							resource={resource}
						/>
					)}
					{!!specialInputModules.length &&
						SpecialInputComponents[specialInputModules[0]]!({
							module: specialInputModules[0],
							source: `${source}.${
								ModuleSources[specialInputModules[0]]
							}`,
							resource: resource,
						})}
				</>
			)}
		</>
	);
};
