export {default as Geohash} from './geohash';

export const latLon_rgx = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export const stringToLatLng = (value) => {
	if (!latLon_rgx.test(value)) return undefined;
	const split = value.split(",");
	const obj = {
		lat: parseFloat(split[0]),
		lng: parseFloat(split[1])
	};
	return obj;
};