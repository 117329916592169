import React from 'react';
import {ArrayInput, SimpleFormIterator} from 'react-admin'

class GalleryInput extends React.Component {
    render() {
        const {
            props,
        } = this;

        return (
            <ArrayInput {...props}>
                <SimpleFormIterator>
                    aaaa
                </SimpleFormIterator>
            </ArrayInput>
        );
    }
}

export default GalleryInput; 