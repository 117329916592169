import React from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import ContentCreate from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import IconButton from '@material-ui/core/IconButton/IconButton';

const EditButton = ({
    basePath = '',
    label = 'ra.action.edit',
    record = {},
    ...rest
}) => (
    <IconButton
        component={Link}
        to={linkToRecord(basePath, record.id)}
        label={label}
        {...rest}
    >
        <ContentCreate />
    </IconButton>
);

EditButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
};

const enhance = shouldUpdate(
    (props, nextProps) =>
        props.translate !== nextProps.translate ||
        (props.record &&
            nextProps.record &&
            props.record.id !== nextProps.record.id) ||
        props.basePath !== nextProps.basePath ||
        (props.record == null && nextProps.record != null)
);

export default enhance(EditButton);