import React from "react";
import { Dict, ExpField } from "../../../types";
import {
	TextInput,
	ReferenceInput,
	SelectInput,
	ReferenceArrayInput,
	SelectArrayInput,
	ArrayInput,
	SimpleFormIterator,
	TextField,
	required,
	DateField,
	FunctionField,
	ImageInput,
	ArrayField,
	SingleFieldList,
} from "react-admin";
import {
	TriggerPickerTS,
	GPSProvider,
	DynamicImageInputPreview,
	ChipInput,
	AttributeToggle,
} from "../../../components";
import { TagTypes } from "../utils";
import { loadPublicConfig } from "../../../ariabo.config";
import AssetReferenceInput from "../AssetReferenceInput";
import { supported_content_langs } from "../../../config";
import AuthorsField from "./AuthorsField";
import GeoHashLocationInput from "../../../components/GeoHashLocationInput";
import { Geohash } from "../../../helpers";
import { isMobile } from "react-device-detect";
import omit from "lodash/omit";
import { Avatar, Chip } from "@material-ui/core";
import JwtDecode from "jwt-decode";
import get from "lodash/get";
import StarIcon from "@material-ui/icons/Star";
import { useConfig } from "../../../hooks";
const debug = require("debug")("app:models:customExp:fields");

export const Inputs: Partial<{ [key in ExpField]: React.FC<any> }> = {
	geoHash: (props) => {
		return (
			<GPSProvider>
				{(latLon: Coordinates) => {
					const config = useConfig();
					return (
						<GeoHashLocationInput
							source="geoHash"
							validate={required()}
							defaultValue={Geohash.encode(
								isMobile && latLon
									? latLon.latitude
									: config.geolocation.latitude,
								isMobile && latLon
									? latLon.longitude
									: config.geolocation.longitude
							)}
							precision={10}
							{...props}
						/>
					);
				}}
			</GPSProvider>
		);
	},
	parent: (props) => (
		<ReferenceInput {...props} source="parent" reference="exps">
			<SelectInput optionText="name" />
		</ReferenceInput>
	),
	children: (props) => (
		<ReferenceArrayInput {...props} source="children" reference="exps">
			<SelectArrayInput optionText="name" />
		</ReferenceArrayInput>
	),
	name: (props) => (
		<TextInput
			source="name"
			label="Name"
			validate={[required()]}
			{...props}
		/>
	),
	group: (props) => (
		<ReferenceInput
			source="group.expGroups_id"
			reference="expGroups"
			{...props}
		>
			<SelectInput optionText="name" />
		</ReferenceInput>
	),
	type: (props) => (
		<SelectInput
			source="type"
			choices={Object.entries(TagTypes).map<Dict>(([key, value]) => ({
				id: value,
				name: key,
			}))}
			defaultValue={"generic"}
			{...props}
		/>
	),
	content: (props) => (
		<ArrayInput source="content" {...props}>
			<SimpleFormIterator>
				<AssetReferenceInput
					allowEmpty
					label="Asset"
					reference="assets"
					perPage={0}
					options={{
						resource: "assets",
					}}
				/>
			</SimpleFormIterator>
		</ArrayInput>
	),
	description: (props) => (
		<TextInput
			source="description"
			label="Description"
			style={{ width: "100%" }}
			multiline
			{...props}
		/>
	), //react admin's "fullwidth" prop or whatever is thrown away anyway by FormInput's bullshit"
	img: (props) => (
		<ImageInput
			source="img"
			label="Select image"
			accept="image/*"
			{...props}
		>
			<DynamicImageInputPreview
				source="img"
				completeSource={props.source || "img"}
			/>
		</ImageInput>
	),
	attributes: (props) => (
		<ChipInput
			label="Attributes"
			source={"modules.classification.attributes"}
			{...props}
		/>
	),
	isBreakingNews: (props) => (
		<AttributeToggle
			label="Is Breaking News"
			source="modules.classification.attributes"
			attribute="breaking"
			{...props}
		/>
	),
	expTriggers: (props) => <TriggerPickerTS source="expTriggers" {...props} />,
	markers: (props) => (
		<ArrayInput source="markers" {...props}>
			<SimpleFormIterator>
				<ReferenceArrayInput source="markers_id" reference="markers">
					<SelectArrayInput optionText="name" />
				</ReferenceArrayInput>
			</SimpleFormIterator>
		</ArrayInput>
	),
	delivery: (props) => (
		<ReferenceInput
			source="deliveries_id"
			reference="deliveries"
			{...props}
		>
			<SelectInput optionText="name" />
		</ReferenceInput>
	),
	lang: (props) => (
		<SelectInput
			defaultValue={"gb"}
			{...props}
			source="lang"
			choices={supported_content_langs.map<Dict>((lang) => ({
				id: lang.name,
				name: lang.readable,
			}))}
		/>
	),
	transition: (props) => (
		<ReferenceInput
			{...props}
			source="transitions_id"
			reference="transitions"
		>
			<SelectInput optionText="name" />
		</ReferenceInput>
	),
};

export const Fields: Partial<
	{ [key in ExpField]: React.FunctionComponentElement<any> }
> = {
	geoHash: React.createElement(
		(props) => <TextField source="geoHash" label="GeoHash" {...props} />,
		{ source: "geoHash", label: "GeoHash" }
	),
	img: React.createElement(
		(props) => (
			<FunctionField
				label="Image"
				render={(record: any) => (
					<Avatar
						alt={`${record["name"]}`}
						src={record["img"] + ".thumb"}
					>
						<Avatar alt={`${record["name"]}`} src={record["img"]}>
							{record["name"][0]}
						</Avatar>
					</Avatar>
				)}
				{...props}
			/>
		),
		{ source: "img", label: "Image" }
	),
	id:React.createElement(
		(props) => <TextField source="id" label="Id" {...props} />,
		{ source: "id", label: "Id" }
	),
	name: React.createElement(
		(props) => <TextField source="name" label="Name" {...props} />,
		{ source: "name", label: "Name" }
	),
	type: React.createElement(
		(props) => <TextField source="type" label="Name" {...props} />,
		{ source: "type", label: "Type" }
	),
	attributes: React.createElement(
		(props) => (
			<ArrayField
				label="Attributes"
				source="modules.classification.attributes"
				{...props}
			>
				<SingleFieldList>
					<FunctionField
						label="Name"
						render={(attr: string) => {
							return <Chip size="small" label={attr} />;
						}}
					/>
					{/* <ChipField source="name"/> */}
				</SingleFieldList>
			</ArrayField>
		),
		{ label: "Attributes", source: "modules.classification.attributes" }
	),
	isBreakingNews: React.createElement(
		(props) => (
			<FunctionField
				source="modules.classification.attributes"
				label="Breaking"
				style={{ textAlign: "center" }}
				render={(record: any) => {
					const attributes =
						get(record, "modules.classification.attributes") || [];
					if (attributes.includes("breaking")) return <StarIcon />;
					else return undefined;
				}}
				{...props}
			/>
		),
		{ label: "Breaking", source: "modules.classification.attributes" }
	),
	creationTime: React.createElement(
		(props) => (
			<FunctionField
				source="id"
				label="Date of Creation"
				render={(record: any) => {
					const stateInfo = JwtDecode(record["stateInfo"]);
					return (
						<DateField
							record={stateInfo}
							source="creationTime"
							label={props.label || "Date of Creation"}
							options={{ timeZone: "UTC" }}
						/>
					);
				}}
				{...props}
			/>
		),
		{ source: "id", label: "Date of Creation" }
	),
	authors: React.createElement(
		(props) => (
			<AuthorsField source="stateInfo" label="Authors" {...props} />
		),
		{ source: "stateInfo", label: "Authors" }
	),
};

interface GetFieldsProps {
	fields: ExpField[];
	fieldProps?: any;
}
export const GetFields: React.FC<GetFieldsProps> = (props: GetFieldsProps) => {
	const { fields, fieldProps } = props;
	return (
		<>
			{fields.map<any>((f) => (
				<div>{Inputs[f] && Inputs[f]!(fieldProps || {})}</div>
			))}
		</>
	);
};

export default "";
