// in LatLongInput.js
import { Field } from 'redux-form';
import { Labeled, addField } from 'react-admin';
import { VectorInputProps } from './types'
import React from 'react';
import { withStyles, createStyles } from '@material-ui/core';

const styles =createStyles({
    div:{
        display:'inline-grid',
        gap:'5px',
        gridTemplateAreas:"'a a a'"
    },
    field:{
        width:'50px'
    }
})

const inpt = (props: VectorInputProps) => (
    <Labeled label={props.label}>
        <div className={props.classes.div}>
            <Field className={props.classes.field} name={props.source+'.x'} component="input" type="number" placeholder="X" {...props.xProps}/>
            <Field className={props.classes.field} name={props.source+'.y'} component="input" type="number" placeholder="Y" {...props.yProps}/>
            {props.threeD&&
                <>
                    <Field className={props.classes.field} name={props.source+'.z'} component="input" type="number" placeholder="Z" {...props.zProps}/>
                </>
            }
        </div>
    </Labeled>
);

export default withStyles(styles)(addField(inpt));