import React, { Component } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    Responsive,
    SimpleList,
    ArrayField,
    FunctionField,
    SingleFieldList
} from 'react-admin';
import { Chip } from '@material-ui/core';



class UserList extends Component {
    componentDidMount() {
        // document.title = Config.app.name + ' - User';
    }

    render() {
        return (
            <List {...this.props}>
                <Responsive small={<SimpleList primaryText={record => record.username} secondaryText={record => record.email} tertiaryText={record => record.role} />} medium={
                    <Datagrid>
                        {/* <TextField source="id" /> */}
                        <TextField source="userName" />
                        <TextField type="email" source="email" />
                        <ArrayField label="Roles" source="roles">
                            <SingleFieldList>
                                <FunctionField label="Role" render={attr => <Chip size="small" label={attr} />} />
                                {/* <ChipField source="name"/> */}
                            </SingleFieldList>
                        </ArrayField>
                        <EditButton />
                        <ShowButton />
                    </Datagrid>
                } />

            </List>
        );
    }
}

export default UserList;