import React from 'react';
import PropTypes from 'prop-types';
import Flag from 'react-country-flags';

const FlagField = ({source, record={}, height, width})=><Flag country={record[source]} asSquare={true} style={{height:height, width:width}} />

FlagField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    height: PropTypes.any,
    width: PropTypes.any
};

FlagField.defaultProps={
    height: "auto",
    width: "auto"
}
export default FlagField;