import React, { Fragment} from "react";
import { ReferenceInput, SelectInput, required, ArrayInput, SimpleFormIterator } from "react-admin";
import { Field } from "redux-form";
import DRI from "./DateRangeInput";
import TRI from "./TimeRangeInput";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
var debug = require("debug")("app:components:triggerpicker")

const dbtriggers={
    date:"5bc9da7217a97563471491d8",
    time:"5bc9da2717a97563471491d6",
    gpsRange:"5bc9da4c17a97563471491d7",
    marker:"5d76412ba0dbee8bccecf19b"
};

const styles = theme => ({
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200,
    }
  });

const MuhTextField = ({ input, value, label, classes,meta: { touched, error }, ...custom }) => (
    <TextField
        hintText={label}
        errorText={touched && error}
        type="number"
        label={label}
        defaultValue={value}
        InputLabelProps={{
            shrink: true,
          }}
        {...input}
        {...custom}
    />
);

class TriggerField extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            selected:"",
            openDateDialog:false,
            triggersId:undefined
        }
    }

    onChange=(str, current, previous, key)=>{
        this.setState({selected:current})
    }

    onDateSelect = (ranges) =>
    {
    }

    componentDidMount()
    {
        if(this.props.baseRecord && this.props.baseRecord[this.props.baseSource])
        {
            let value = this.props.baseRecord;
            let triggerId = undefined;
            this.props.id.split(".").forEach(field => {
                if(!value){
                    value=undefined;
                    return;
                }
                let splitValues = field.split("[");
                value = value[splitValues[0]];
                if(splitValues.length>1)
                {
                    if(triggerId===undefined)triggerId= splitValues[1].split("]")[0];
                    value = value[splitValues[1].split("]")[0]];
                }
            });
            if(value)this.setState({selected:value, triggersId:triggerId})
        }
    }

    getDefaultValue = () =>{
        if(!this.props.baseRecord || !this.props.baseRecord[this.props.baseSource] || !this.state.triggersId) return {};
        switch (this.state.selected) {
            case dbtriggers.date:
                let dateValue= this.props.baseRecord[this.props.baseSource][this.state.triggersId]["args"].split("&&");
                return dateValue? dateValue: undefined;
            case dbtriggers.gpsRange:
                return this.props.baseRecord[this.props.baseSource][this.state.triggersId]["args"];
            case dbtriggers.time:
                let timeValue= this.props.baseRecord[this.props.baseSource][this.state.triggersId]["args"].split("&&");
                return timeValue? timeValue: undefined;         
            default:
                return {};
        }
    }

    render() {
        const {
            props,
        } = this;

        let startVal=this.getDefaultValue();
        debug("props", props);
        return (
            <Fragment>
                <ReferenceInput key={props.id} id={props.id} index={props.index} onChange={this.onChange} validate={required()} label="Trigger" source="trigger.triggers_id" reference="triggers" {...props} >
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                {this.state.selected===dbtriggers.date ?  
                <>
                    {/* <DRI /> */}
                    <Field name={props.baseSource+".args"} startDate={new Date(startVal[0])} endDate={new Date(startVal[1])} label="Select Date Range" component={DRI} />
                </>:<></>}              
                {this.state.selected===dbtriggers.time ? <> 
                    <br/>
                    <Field name={props.baseSource+".args"} start={startVal[0]} end={startVal[1]} label="Select Time Range" component={TRI} />
                </>:<></>}
                {this.state.selected===dbtriggers.gpsRange ?
                <span style={{display: "flex", flexWrap: "wrap",}}>
                    <Field name={props.baseSource+".args"} value={startVal} label="Set Range (meters)" component={MuhTextField} />
                </span> :<></>}
            </Fragment>
        );
    }
}
export default withStyles(styles)(class extends React.Component {
    render() {
        const {
            props,
        } = this;
        debug(props);
        return <>
            <ArrayInput label={props.label} source={props.source}>
                <SimpleFormIterator>
                    <TriggerField baseSource={props.source} baseRecord={props.record} label="Trigger" source="trigger.triggers_id" classes={props.classes}/>
                </SimpleFormIterator>
            </ArrayInput>
        </>;
    }
})
