import React from 'react';
import AvatarMUI from '@material-ui/core/Avatar/Avatar';
import get from 'lodash/get';
import {AvatarProps} from './types';

class Avatar extends React.Component<AvatarProps> {
    
    render() {
        const { record } = this.props;
        return <AvatarMUI style={{width:this.props.size, height:this.props.size, borderRadius:0}} src={get(record, this.props.source)} alt="" />;
    }

    static defaultProps={
        size:'100%'
    }
    
}

export default Avatar;