import React from "react";
import { XMasonry, XBlock } from "react-xmasonry";
import AssetCard from "./AssetCard";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
var debug = require('debug')('app:models:assets:assetxgrid');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        assetBlock: {
            margin: "0.5rem"
        }
    }));

const Block: React.FC<any> = (props: any) => {
    const classes = useStyles();
    return (
        <XBlock {...props}>
            <div className={classes.assetBlock}>
                {props.children && props.children}
            </div>
        </XBlock>
    )
}

const Grid: React.FC = (props: any) => {
    const { ids, data, basePath, resource } = props;
    debug("Grid props", props)
    return (
        <XMasonry>
            {ids.map((id: string) =>
                <Block key={id}>
                    <AssetCard record={data[id]} basePath={basePath} resource={resource} />
                </Block>
            )}
        </XMasonry>
    )
}

export default Grid;