import React from "react";
import GeolocationInput from "./GeolocationInput";
import { Geohash, latLon_rgx, stringToLatLng } from "../helpers";
import _ from "lodash";
import { useQuery, Loading, addField } from "react-admin";
import { makeStyles, Theme, NativeSelect, Paper, withStyles, InputBase, createStyles } from "@material-ui/core";
var debug = require("debug")("app:components:geohashlocationinput");

const geolocationModuleName = "GeolocationModule";

const styles = createStyles((theme: Theme) => ({
    root: {
        position: "relative",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        left: "50%",
        marginLeft: "-120px",
        margin: "10px",
        width: "240px",
        height: "36px"
    },
    typography: {
        width: "fit-content",
        position: "relative",
        left: "50%",
        marginLeft: "-120px",
        marginTop: "-10px"
    },
    input: {
        marginLeft: theme.spacing(),
        flex: 1
    },
    error: {
        textDecoration: "underline",
        textDecorationColor: "red",
        textDecorationStyle: "dashed"
    }
}))
const useStyles = makeStyles(styles);

const BootstrapInput = withStyles(styles)(InputBase);

const PlaceTypeInput: React.FC<any> = (props: any) => {
    const { submit, errorMessage, placeTypes, source, ...restProps } = props;
    const classes: any = useStyles() as any;
    return (
        <Paper className={classes && classes.root}>
            <NativeSelect
                id="demo-customized-select-native"
                // className={props.classes && props.classes.input}
                {...restProps.input}
                input={<BootstrapInput />}

            >   {placeTypes && placeTypes.ids.map((id: string, index: string) => (
                <option value={id}>{placeTypes["names"][index]}</option>
            ))}
            </NativeSelect>
        </Paper>

    )
}

const PlaceTypeAddedInput = addField(PlaceTypeInput);


const geoHashToLatLonString = (geohash: string) => {
    if (!geohash) return geohash;
    try {
        const latLon = Geohash.decode(geohash);
        return latLon && `${latLon.lat}, ${latLon.lon}`;
    } catch (error) {
        return geohash;
    }
};

interface IProps {
    source: string;
    precision: number;
    [key: string]: any;
}

const GeoHashLocationInput: React.FC<IProps> = (props: IProps) => {
    const [parsedValue, setParsedValue] = React.useState<string | undefined>(
        undefined
    );

    const format = (value: string) => {
        debug("format me baby", value);
        if (!value && props.defaultValue)
            return geoHashToLatLonString(props.defaultValue);
        if (value && !parsedValue) return geoHashToLatLonString(value);
        else return parsedValue;
    };

    const parse = React.useCallback((inputValue: string) => {
        setParsedValue(inputValue);
        if (!inputValue) return inputValue;
        if (!latLon_rgx.test(inputValue)) return inputValue;
        const latLon = stringToLatLng(inputValue);
        if (latLon) {
            const geoHash = Geohash.encode(
                latLon.lat,
                latLon.lng,
                props.precision || 9
            );
            debug("parse from, to:", latLon, geoHash);
            return geoHash;
        } else return inputValue;
    }, []);

    const typeSource = React.useMemo(() => {
        return props.source && props.source.split(".").length > 1 && props.source.substring(0, props.source.lastIndexOf(".")) + ".type";
    }, [props.source])

    const { data, loading, error } = useQuery({
        type: "getOne",
        resource: "modules/configs",
        payload: {
            id: ""
        }
    });

    const placeTypes: { names: string[], ids: string[] } | undefined = React.useMemo(() => {
        if (loading || error) return undefined;
        const geolocationModule = JSON.parse(data[geolocationModuleName]);
        debug("loaded geolocation module", geolocationModule)
        return {
            names: geolocationModule["definitions"]["PlaceLocationType"]["x-enumNames"].map((name:string)=>name.replace(/_/g, " ")),
            ids: geolocationModule["definitions"]["PlaceLocationType"]["enum"]
        }

    }, [loading])

    if (!data) return <Loading />;

    debug("places", placeTypes)

    return (
        <GeolocationInput {...props} format={format} parse={parse}>
            <PlaceTypeAddedInput placeTypes={placeTypes} source={typeSource} />
        </GeolocationInput >
    );
};

export default GeoHashLocationInput;
