import React from "react";
import { ReactAdminCreateProps } from "../../../types";
import { Create, SimpleForm, Toolbar, SaveButton, DeleteButton} from "react-admin";
import ProjectForm from "./ProjectForm";
import { makeStyles } from "@material-ui/core";
import { FormDataJsonDrawerButton } from "../../../components";

const debug = require("debug")("app:models:projectexp:create");

const useToolbarStyles = makeStyles({
    toolbar: {
        display: "flex",
        justifyContent: "space-between"
    }
})

const CustomToolbar: React.FC<any> = (props: any) => {
    const { jsonViewer, ...restProps } = props;
    const classes = useToolbarStyles();
    return (
        <Toolbar {...restProps} classes={classes}>
            <SaveButton />
            {jsonViewer &&
                <FormDataJsonDrawerButton />
            }
        </Toolbar>
    )
}

const ExpCreate: React.FC<ReactAdminCreateProps> = (
	props: ReactAdminCreateProps
) => {
	debug("props", props);
	const { options } = props;
	const defaultValues: any = options && options["defaultValues"];

	return (
		<Create title="Project Creation" {...props}>
			{/* <ProjectForm /> */}
			{/* <CreateForm /> */}
			<SimpleForm
				initialValues={{
					name: "root",
					...defaultValues
                }}
                toolbar={<CustomToolbar jsonViewer={options&&options.jsonViewer}/>}
                redirect="list" 
			>
				<ProjectForm modules={options && options.modules}/>
			</SimpleForm>
		</Create>
	);
};

export default ExpCreate;
