import React from 'react';
import {
    ImageInput,
    ImageField,
    SimpleForm,
    TextInput,
    Create
} from 'react-admin';
import { fileServer, restServer } from '../../dataProvider'
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createEffectName } from "./effect-utils";
import { BasedImageInput } from '../../components';
var debug = require('debug')('app:models:effect:create')

const EffectCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source='name' />
                <TextInput source='key' />
                {/* <BasedImageInput accept="image/*"  source="icon" />, */}
                <ImageInput source='icon' label="Select effect image" accept="image/*">
                    <ImageField source='icon' title="wtf" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );

}

export default EffectCreate;
