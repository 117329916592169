import React from "react";
import { FormSpy } from "react-final-form";
import ReactJson from "react-json-view";
import { Drawer, Button, Container } from "@material-ui/core";
import BugReportIcon from '@material-ui/icons/BugReport';

const FormDataJsonViewer: React.FC = (props: any) => {
    return (<FormSpy>
        {(props) =>
            <ReactJson
                src={props.form.getState().values}
                collapsed={false} collapseStringsAfterLength={36}
                theme="monokai"
                style={{height:"100%", minWidth:"20vw",maxWidth:"50vw", wordBreak:"break-all", overflow:"auto"}}
            />
        }
    </FormSpy>);
}

interface FormDataJsonDrawerProps {
    open: boolean,
    toggleDrawer: (open: boolean) => void
}

const FormDataJsonDrawer: React.FC<FormDataJsonDrawerProps> = (props: FormDataJsonDrawerProps) => {
    const { open, toggleDrawer } = props;
    return (
        <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)}>
            <FormDataJsonViewer />
        </Drawer>
    );
}

const DrawerLauncherButton: React.FC = (props: any) => {
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = (open: boolean) => setOpen(open);
    return (
        <>
            <FormDataJsonDrawer open={open} toggleDrawer={toggleDrawer} />
            <Button startIcon={<BugReportIcon />} variant="outlined" color="primary" component="span" onClick={(ev: any) => setOpen(true)}>
                Json
            </Button>
        </>
    );
}

export default DrawerLauncherButton;