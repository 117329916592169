import List from "./AppConfigList";
import Create from "./AppConfigCreate";
import Edit from "./AppConfigEdit";
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

export default {
    name:"applicationConfigs",
    list:List,
    create:Create,
    edit:Edit,
    icon:PhoneAndroidIcon,
    options:{
        label:"root.resources.applications"
    }
}