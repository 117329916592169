import React from "react";
import { SimpleForm, TextInput, SelectInput, required } from "react-admin";
import { ASSET_TYPES } from ".";
import AssetSelector from "./AssetSelector";
import { TriggerPickerTS, ChipInput } from "../../components";

const defaultValues = {
    name: "New Asset",
    assetType: "text",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
}

const AssetForm: React.FC<any> = (props: any) => {
    const assetTypes = React.useMemo(() => {
        return Object.entries(ASSET_TYPES)
            .filter(([key, value]) => typeof value === "string")
            .map(([key, value]) => ({ name: key, id: value }));
    }, []);
    return (
        <SimpleForm label="Asset Creation ;*" {...props} initialValues={defaultValues} redirect="list">
            <TextInput source="name" validate={[required()]} />
            <SelectInput choices={assetTypes} source="assetType" validate={[required()]} />
            <AssetSelector />
            <ChipInput label="Attributes" source={"attributes"} />
            {/* <TriggerPickerTS source="triggers" /> */}
        </SimpleForm>
    );
};

export default AssetForm;