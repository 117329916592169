import * as THREE from 'three';
import React, {Component, useMemo, useState, useRef, FunctionComponent} from 'react';
import { Canvas, extend, useThree, useRender } from 'react-three-fiber';
import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { IconButton, Button, Dialog, DialogContent } from '@material-ui/core';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import PauseIcon from '@material-ui/icons/PauseCircleOutline';
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import {Dict, FieldProps} from '../types';
import { ModelViewerProps } from "./types";
import ModelViewerIcon from './ModelViewerIcon';
const debug = require('debug')('app:components:modelviewer');

extend({ OrbitControls })

const Controls = (props:Dict) => {
    const debug = require('debug')('app:components:modelviewer:controls');
    const { camera, gl } = useThree();
    const { onGetControls, ...restProps } = props;
    const controls = useRef();
    useRender(() =>{
        if(controls){
            let currentControls:any = controls.current;
            if(currentControls){ //it's retarded that i have to do this... https://stackoverflow.com/questions/49610779/typescript-error-ts2532-object-is-possibly-undefined-even-after-undefined-c
                currentControls.update()
            }
        }
    }, false);
    if(onGetControls)onGetControls(controls);
    return <orbitControls ref={controls} args={[camera, gl.domElement]} domElement={gl.domElement} {...restProps}/> 
}

interface IState{
    hasAnimations:boolean,
    animate:boolean,
    gltf:GLTF|undefined,
}

class Viewer extends Component<ModelViewerProps, IState>{
    constructor(props: ModelViewerProps) {
        super(props);
        this.state={
            hasAnimations:false,
            animate:false,
            gltf:undefined
        }
    }

    webGLRenderer:THREE.WebGLRenderer|undefined = undefined;
    scene:THREE.Scene|THREE.Object3D|THREE.Mesh|undefined = undefined;
    animator:THREE.AnimationMixer|undefined = undefined;

    static defaultProps={
        backgroundColor:'lightgray',
        cameraPosition:[0, 0, 0],
        ambientLightIntensity:1
    }
    togglePlay = ()=>{
        this.setState({animate:!this.state.animate})
    }
    onAnimationsFound= () =>{
        this.setState({hasAnimations:true})
    }
    // onModelSizeComputed = (size)=>this.setState({size:[size.x, size.y, size.z]})
    GLTFScene=(props:Dict)=> {
        const {url, onAnimationsFound} = props;
        const {gl, scene} = useThree();
        if(!this.webGLRenderer){
            this.webGLRenderer=gl;
            this.scene=scene;
        }
        // debug("called with", url)

        let [gltf, set] = [this.state.gltf, (obj:GLTF)=>this.setState({gltf:obj})]
        useMemo(() => {
            let loader = new GLTFLoader();
            
            loader.load(url, obj=>{
                // debug("loaded", url, obj)
                this.animator = new THREE.AnimationMixer(obj.scene) //dont worry, only happens once, trust me please
                obj.animations.forEach((clip)=>{
                    this.animator!.clipAction(clip).play();
                });
                if(obj.animations && obj.animations.length && onAnimationsFound)onAnimationsFound();
                var box:THREE.Box3 = new THREE.Box3().setFromObject(obj.scene);
                box.getCenter(obj.scene.position);
                obj.scene.localToWorld(box.max);
                obj.scene.position.multiplyScalar(-1);
                set(obj)
            })
        }, [url])
        let lastime=0;
        useRender((state:any, delta:number)=>{ //should probably move thiss out
            debug("useRender delta",delta)
            if(this.animator && this.state.animate){ //this is what requires us to keep the scene in the viewer class
                this.animator.update((delta));
            }
            lastime=delta;
        }, false)

        let mesh:THREE.Scene|undefined = gltf? gltf.scene : undefined;
        debug("gltf and scene",gltf, gltf && gltf.scene);
        return gltf &&mesh ? <primitive object={mesh} /> : null
    }

    diposeScene = (scene:THREE.Scene) =>{
        scene.traverse(child=>{
            if(child instanceof THREE.Mesh)
            {
                let mesh = child as THREE.Mesh;
                this.scene!.remove(child);
                mesh.geometry.dispose();
                let diposeMat = (mat:THREE.Material) =>mat.dispose();
                if(Array.isArray(mesh.material))
                    mesh.material.forEach(diposeMat);
                else if(mesh.material instanceof THREE.Material) diposeMat(mesh.material);
            }
        })
        if(scene&&scene instanceof THREE.Mesh){
            scene.geometry.dispose()
        }
    }

    componentWillUnmount() {
        if(this.webGLRenderer){
            if(this.state.gltf){
                this.diposeScene(this.state.gltf.scene);
                this.setState({gltf:undefined})
            }
            this.scene && this.scene instanceof THREE.Scene &&this.diposeScene(this.scene);
            this.scene=undefined;
            this.animator && this.animator.stopAllAction();
            this.animator = undefined;
            this.webGLRenderer.renderLists.dispose();
            this.webGLRenderer.context.finish();
            this.webGLRenderer.context.flush();
            this.webGLRenderer.clear();
            this.webGLRenderer=undefined;
            THREE.Cache.clear(); //just in case
            debug("webGLRenderer DISPOSED");
        }
    }

    render(){
        const {children,backgroundColor, cameraPosition, record, source, ambientLightIntensity,setPosAuto} = this.props;
        return (
            <div style={{backgroundColor:'red', height:'100%'}}>
                    {this.state.hasAnimations &&
                        <IconButton onClick={this.togglePlay} color='primary' style={{zIndex:1, position:'absolute'}}>
                            {this.state.animate?<PauseIcon/>:<PlayIcon/>}
                        </IconButton>
                    }              
                    <Canvas  key={record?record.id+'.'+source : 'undefinedRecord.'+source} style={{ height:'100%', background: backgroundColor }} camera={{near:0.01, far:5000}} >
                        <ambientLight intensity={ambientLightIntensity} />
                        <this.GLTFScene onAnimationsFound={this.onAnimationsFound} animate={this.state.animate} setPosAuto={setPosAuto} url={record && source?record[source]:"noSource"} />
                        <Controls enableDamping enablePan={true} dampingFactor={0.1} screenSpacePanning={true} panSpeed={0.1} rotateSpeed={0.1} maxPolarAngle={Math.PI / 2} />
                    </Canvas>
            </div>
        )
    }
}

const ButtonToViewer:FunctionComponent<ModelViewerProps> = (props:ModelViewerProps) =>{
    const {classes, ...restProps} = props;
    const [modal, setModal] = useState(false);

    return (
        <div className={classes.divRoot}>
            <Button 
                color='primary'
                className={classes.button}
                onClick={i=>{
                    i.preventDefault();
                    setModal(true)}
                }
            >
                <span className={classes.icon}>
                    <ModelViewerIcon />
                </span>
                View
            </Button>
            {modal &&
                <Dialog 
                    classes={{paper:classes.paper}}
                    open={modal}
                    onClose={i=>setModal(false)}
                    maxWidth="lg"
                    fullWidth={true} 
                    fullScreen={false} 
                    aria-labelledby="confirmation-dialog-title" >
                    <DialogContent style={{height:'100%'}}>
                        <Viewer {...restProps}/>
                    </DialogContent>
                </Dialog>
            }
        </div>
    )
}

const buttonStyles= (theme:Theme) =>createStyles({
    divRoot:{
        textAlign:'center'
    },
    paper:{height:'75%', width:'75%'},
    icon:{
        height:'24px',
        width:'24px',
        marginRight: '0.5em',
        color: theme.palette.primary.main
    },
    button:{
        height:'100%',
        width:'fit-content'
    }
})

export default withStyles(buttonStyles)(ButtonToViewer);
