import React from "react";
import PropTypes, { object } from "prop-types";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import FormatListIcon from '@material-ui/icons/List';
import FormatHierarchyIcon from '@material-ui/icons/ClearAll';
import Grid from "@material-ui/core/Grid/Grid";

export default class ToggleButtons extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            value:props.defaultValue,
        }
        this.value = props.grid[0].key;
    } 

    static propTypes={
        onSwitch:PropTypes.func,
        grid:PropTypes.arrayOf(object),
        exclusive:PropTypes.bool
    }
    static defaultProps={
        grid:[
             {
                 key:"list",
                 icon:<FormatListIcon />
             },
             {
                key:"hierarchy",
                icon:<FormatHierarchyIcon />
            }
        ]
    }

    handleMode = (event, mode) =>{
        this.setState({value:mode})
        if(this.props.onSwitch)
        {
            this.props.onSwitch(mode);
        }
    } 

    render(){
        const { grid, exclusive } = this.props;
        const {value} = this.state;
        return(
            <Grid>
                <ToggleButtonGroup key="toggleChildAdult" exclusive={exclusive} value={value} onChange={this.handleMode}>
                        {grid.map(i=>(
                            <ToggleButton key={i.key} value={i.key}>
                                {i.icon}
                            </ToggleButton>
                        ))}
                </ToggleButtonGroup>
            </Grid>
        )
    }
}