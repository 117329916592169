import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
var debug = require('debug')('app:components:timerangeinput')

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

class TimePickers extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            start:undefined,
            end:undefined
        }
    }

    onChangeStart = (args) =>{
        this.setState({start:args.target.value})
        debug("change", args)
        if(this.props.input)
        {
            this.props.input.value= args.target.value + "&&" + this.state.end;
            this.props.input.onChange(args.target.value + "&&" + this.state.end)
        }
    }

    onChangeEnd = (args) =>{
        this.setState({end:args.target.value})
        if(this.props.input)
        {
            this.props.input.value= this.state.start + "&&" + args.target.value;
            this.props.input.onChange(this.state.start + "&&" + args.target.value)
        }
        
    }

    componentDidMount()
    {
        this.setState({end:this.props.end, start:this.props.start})
        this.props.input.value= this.props.start + "&&" + this.props.end;
        this.props.input.onChange(this.props.start+ "&&" + this.props.end)
    }
    
    render() {
        const {
            props,
        } = this;

        const { classes, start, end } = props;
        
        return (
          <form className={classes.container} noValidate>
              <TextField
                  id="time"
                  label="From"
                  type="time"
                  defaultValue={start}
                  className={classes.textField}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  inputProps={{
                      step: 300, // 5 min
                  }}
                  onChange={this.onChangeStart}
                //   {...input}
              />
              <TextField
                  id="time"
                  label="To"
                  type="time"
                  defaultValue={end}
                  className={classes.textField}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  inputProps={{
                      step: 300, // 5 min
                  }}
                  onChange={this.onChangeEnd}
                //   {...input}
            />
          </form>
        );
    }
}

TimePickers.propTypes = {
    classes: PropTypes.object.isRequired,
    start: PropTypes.any,
    end: PropTypes.any
};

export default withStyles(styles)(TimePickers);