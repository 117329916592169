import React, { Component } from 'react';
import {
    Create,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    required,
    SelectInput,
} from 'react-admin';
import { Field } from 'redux-form';
import { ChipInput } from '../../components';
var debug = require('debug')('app:models:user:create')

const UserCreateToolbar = ({ ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label="Save"
            redirect="show"
            submitOnEnter={true}
        />
    </Toolbar>
);

class UserCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAssets: {

            }
        }
    }

    componentDidMount() {
        // document.title = Config.app.name + ' - Create User';
    }

    onSpaceSelect = (chars, currrentKey, previousKey, field) => {
        this.setState(prevState => (
            {
                selectedAssets: {
                    ...prevState.selectedAssets,
                    [field]: currrentKey
                }
            }
        ))
        //debug(this.state.selectedAssets);
    }

    MyFunction = (props) => {
        //debug(props)
        return <Field name={props} component={props => {
            return (
                <SelectInput label="Space" onChange={this.onSpaceSelect} choices={[{ id: "exp", name: "root.resources.tags" }, { id: "asset", name: "root.resources.assets" }]} />
            )
        }} />
    }

    render() {
        return (
            <Create {...this.props}>
                <SimpleForm
                    toolbar={<UserCreateToolbar />}
                    defaultValue={{ roles: ['agent'] }}
                    redirect="show"
                >
                    <TextInput source="username" validate={[required()]} />
                    <TextInput type="email" source="email" validate={[required()]} />
                    <TextInput source="password" validate={[required()]} />
                    <ChipInput label="Roles" source="roles" validate={[required()]} />
                </SimpleForm>
            </Create>
        );
    }
}

export default UserCreate;