import React from "react";
import { Triggers } from "../models/trigger";
import {
	SelectInput,
	FormDataConsumer,
	ArrayInput,
	SimpleFormIterator,
	TextInput,
	NumberInput
} from "react-admin";
import { get } from "lodash";
import { createStyles, withStyles } from "@material-ui/core";
import { useForm, FormSpy } from "react-final-form";
var debug = require("debug")("app:components:triggerpickerts");

const triggerIdSource = "value";

const retardedStyles = createStyles({
	argsContainer: {
		display: "inline-flex"
	}
});

const Picker: React.FC<any> = (props: any) => {
	const { source } = props;
	const [selectedTrigger, selectTrigger] = React.useState<string>("");
	// const [value, selectedValue] = React.useS
    debug("BUIG SOURCE", props);
	return (
		<>
			<SelectInput
				source={`${source}.${triggerIdSource}`}
				choices={Triggers}
				label=""
			/>
		</>
	);
};

const PickersArray: React.FC<any> = (props: any) => {
    const { classes } = props;
    const trashboisquad = useForm();
    const values = trashboisquad.getState();
	return (
		<>
			<ArrayInput {...props} label="Triggers">
				<SimpleFormIterator>
					<Picker />
					<FormDataConsumer>
						{(props: any) => {
							// const {scopedFormData, getSource } = props; idk why this is not working....
							const value = get(
								props.scopedFormData,
								triggerIdSource
							);
							const selectedTrigger = Triggers.find(
								c => c.id === value
							);
							if (
								selectedTrigger &&
								selectedTrigger.key === "TRIGGER_DISTANCE"
							)
								return (
									<NumberInput
										source={props.getSource("args.value")}
										label="Meters"
									/>
								);
							if (
								selectedTrigger &&
								selectedTrigger.key === "TRIGGER_TIME"
							)
								return (
									<div className={classes.argsContainer}>
										<TextInput
											source={props.getSource(
												"args.from"
											)}
											label="From"
											options={{
												type: "time",
												InputLabelProps: {
													shrink: "false"
												}
											}}
										/>
										<TextInput
											source={props.getSource("args.to")}
											label="To"
											options={{
												type: "time",
												InputLabelProps: {
													shrink: "false"
												}
											}}
										/>
									</div>
								);
							if (
								selectedTrigger &&
								selectedTrigger.key === "TRIGGER_DATE"
							)
								return (
									<div className={classes.argsContainer}>
										<TextInput
											source={props.getSource(
												"args.from"
											)}
											label="From"
											options={{
												type: "date",
												InputLabelProps: {
													shrink: "false"
												}
											}}
										/>
										<TextInput
											source={props.getSource("args.to")}
											label="To"
											options={{
												type: "date",
												InputLabelProps: {
													shrink: "false"
												}
											}}
										/>
									</div>
								);
							return (
								<TextInput
									source={props.getSource("args.value")}
									label={
										(selectedTrigger &&
											selectedTrigger.name) ||
										"Value"
									}
								/>
							);
						}}
					</FormDataConsumer>
				</SimpleFormIterator>
			</ArrayInput>
		</>
	);
};

export default withStyles(retardedStyles)(PickersArray);
