import React from "react";
import { List, EditButton, DeleteButton, DELETE, UPDATE } from "react-admin";
import {
	MediaCard,
	PublishButton,
	AccessControlledFeature,
	DeleteDialog
} from "../../../components";
import { restServer } from "../../../dataProvider";
const debug = require("debug")("app:models:customExp:mobilegrid");

const Grid: React.FC<any> = (props: any) => {
	const { ids, data, basePath, permissions, resource, history } = props;
	return (
		<div style={{ margin: "1em" }}>
			{ids.map((id: any) => (
				<MediaCard
					key={`CustomExpMobileGrid_${id}`}
					media={data[id]["img"]}
					title={data[id]["name"]}
					description={data[id]["description"]}
					onClick={() => history.push(`${basePath}/${id}`)}
					actions={
						<>
							<AccessControlledFeature
								feature="PUBLISH"
								basePath={basePath}
								record={data[id]}
								resource={resource}
							>
								<PublishButton />
							</AccessControlledFeature>
							<AccessControlledFeature
								feature={UPDATE}
								basePath={basePath}
								record={data[id]}
							>
								<EditButton />
							</AccessControlledFeature>
							<AccessControlledFeature
								feature={DELETE}
								basePath={basePath}
								record={data[id]}
								resource={resource}
							>
                                <DeleteButton />
								{/* <DeleteDialog
									{...props}
									dialogConditional={(record: any) => {
										return record.stateInfo.publishedId;
									}}
									dialogTitle="Delete"
									dialogText="Are you sure you want to delete the selected tag(s)? Selection contains published experience(s)."
									beforeDelete={(record: any) => {
										if (record.stateInfo.publishedId)
											restServer.unpublishTag(record);
									}}
								/> */}
							</AccessControlledFeature>
						</>
					}
				/>
			))}
		</div>
	);
};

export default Grid;
