export default {
	"ui:options": {
        addable: false,
        expandable:true
        
    },
    shape:{
        asset:{
            "ui:widget":"assetRef"
        }
    },
	mainColor: {
		"ui:widget": "color"
	},
	label: {
		mainColor: {
			"ui:widget": "color"
		},
		sideColor: {
			"ui:widget": "color"
		},
		borderColor: {
			"ui:widget": "color"
		}
	}
};
