import React from "react";
import ChipInput from 'material-ui-chip-input';
import { addField, Labeled } from "react-admin";
import omit from "lodash/omit";

const InputChips: React.FC = (props: any) => {
    const { source, label, record, input, ...restProps } = props;
    return (
        <>
            {(props.label || props.source) &&
                <Labeled label={props.label || props.source}>
                    <ChipInput defaultValue={input && input.value} {...restProps} {...omit(input, "value")} />
                </Labeled>
            }
            {!(props.label || props.source) &&
                <ChipInput defaultValue={input && input.value} {...restProps} {...omit(input, "value")} />
            }
        </>
    )
}

export default addField(InputChips);