import React from "react";
import { useQuery, Loading, Error, addField, SelectInput, FormDataConsumer, ArrayInput, SimpleFormIterator } from "react-admin";
import { get } from "lodash";
import { InputSchemaForm } from "./rjsf/SchemaForm";
import { AnyObject } from "final-form";
import { useForm } from "react-final-form";

var debug = require('debug')('app:components:playerinput')

const PlayerInput: React.FC = (props: any) => {
    const { source } = props;
    const form = useForm();
    const [keySource, jsonDataSource] = React.useMemo(() => {
        return [source + ".player", source + ".playerData"]
    }, [source]);

    const initialPlayer = React.useMemo(() => {
        const formState = form.getState();
        const formValues = formState.values;
        const initialKey = get(formValues, keySource);
        return initialKey;
    }, [source])

    const selectedPlayerRef = React.useRef(initialPlayer);

    const { data, loading, error } = useQuery({
        type: "getOne",
        resource: "players/configs",
        payload: {
            id: ""
        }
    });
    if (loading || error) return <Loading />;
    if (error) return <Error error={error} />;//this doesnt work, wtf react admin
    if (!data) return null;
    const selectChoices = Object.keys(data).map((key) => ({ id: key, name: key }))
    const dataField = form.getFieldState(jsonDataSource);
    debug(initialPlayer)
    // debug("hey got it?", selectChoices)
    debug("props", props)
    debug("sources", keySource, jsonDataSource);
    // return<div> ok </div>
    return (
        <div style={{ display: "flex", placeItems:"baseline" }}>
            <SelectInput source={keySource} label="Player" choices={selectChoices} allowEmpty disabled={!!selectedPlayerRef.current} />
            <FormDataConsumer>
                {(props: any) => {
                    const { formData, ...rest } = props;
                    const selectedPlayer = get(formData, keySource);
                    if (selectedPlayer !== selectedPlayerRef.current) {
                        // form.resetFieldState(jsonDataSource);
                        debug("Selected player changed, ideally i should allow user to change player even after selecting once")
                    }
                    selectedPlayerRef.current = selectedPlayer;
                    if (!selectedPlayer) return <></>;
                    const playerConfigSchema = JSON.parse(data[selectedPlayer]);
                    debug("hello?", JSON.parse(data[selectedPlayer]))
                    return <InputSchemaForm
                        source={jsonDataSource}
                        schema={playerConfigSchema}
                        parse={(jObject: AnyObject) => JSON.stringify(jObject)}
                        format={(jString: string) => jString && JSON.parse(jString)}
                    />
                }}
            </FormDataConsumer>
        </div>
    );
};

interface PlayersModuleInputProps {
    source: string,
    label?: string,
    resource: string
}

export const PlayersModuleInput: React.FC<PlayersModuleInputProps> = (props: PlayersModuleInputProps) => {
    return (
        <ArrayInput {...props} label={props.label || "Players"}>
            <SimpleFormIterator>
                <PlayerInput />
            </SimpleFormIterator>
        </ArrayInput>
    )
}
export default addField(PlayerInput)