import React from "react";
import {
	Create,
	SimpleForm,
	ArrayInput,
	SimpleFormIterator,
	SaveButton,
	Toolbar,
} from "react-admin";
import { ReactAdminCreateProps, ExpField } from "../../../types";
import { GetFields } from "./Fields";
import { createStyles, withStyles, makeStyles } from "@material-ui/core";
import { ModuleTypes } from "../../modules/types";
import ReactJson from "react-json-view";
import { FormSpy } from "react-final-form";
import {
	FormDataJsonDrawerButton,
} from "../../../components";
import { ModuleCollectionInput } from "./Modules";
import intersection from "lodash/intersection";

const debug = require("debug")("app:models:customExp:create");

const useFormStyles = makeStyles({
	form: {
		display: "block",
	},
});

const useToolbarStyles = makeStyles({
	toolbar: {
		display: "flex",
		justifyContent: "space-between",
	},
});

const CustomToolbar: React.FC<any> = (props: any) => {
	const { jsonViewer, ...restProps } = props;
	const classes = useToolbarStyles();
	return (
		<Toolbar {...restProps} classes={classes}>
			<SaveButton />
			{jsonViewer && <FormDataJsonDrawerButton />}
		</Toolbar>
	);
};

const ExpCreate: React.FC<ReactAdminCreateProps> = (
	props: ReactAdminCreateProps
) => {
	const { options } = props;
	const fields: ExpField[] = options && options["fields"];
	const defaultValues: any = options && options["defaultValues"];
	const modules: ModuleTypes[] = options && options["modules"];
	const hasGeolocationModule: boolean =
		modules && modules.includes(ModuleTypes.GeolocationModule);
	const modulesWithoutGeolocation =
		modules && modules.filter((m) => m !== ModuleTypes.GeolocationModule);
	const classes = useFormStyles();
	debug("prop options", options);
	// debug("got this wack ass props lol wtf", props);
	return (
		<Create {...props}>
			<SimpleForm
				toolbar={
					<CustomToolbar jsonViewer={options && options.jsonViewer} />
				}
				initialValues={defaultValues}
				className={classes.form}
			>
				{modules && hasGeolocationModule && (
					<GetFields
						fields={["geoHash"]}
						fieldProps={{
							source: "modules.geolocation.locations[0].geoHash",
						}}
					/>
				)}
				<GetFields fields={fields} />
				{modulesWithoutGeolocation &&
					modulesWithoutGeolocation.length > 0 && (
						<ModuleCollectionInput
							source="modules"
							modules={modulesWithoutGeolocation.filter(
								(m) => m !== ModuleTypes.GeolocationModule
							)}
						/>
					)}
				{/* <ModuleCollectionInput source="modules" modules={[ //tests
					ModuleTypes.EventsModule,
					ModuleTypes.DataClassificationModule,
					ModuleTypes.LightModule,
					ModuleTypes.GeolocationModule,
					ModuleTypes.EffectsModule,
					ModuleTypes.PlayersModule,
					ModuleTypes.TriggersModule
					]
				} /> */}
			</SimpleForm>
		</Create>
	);
};

export default ExpCreate;
