import List from './ExpList';
import Create from './ExpCreate';
import Edit from './ExpEdit';
// import Show from './ExpShow';
import { supported_content_langs } from "../../../config";
import NotListedLocation from '@material-ui/icons/NotListedLocation';
import { Dict, ExpField } from '../../../types';
import { ResourceProps } from 'ra-core/lib/types';
import { ModuleTypes } from '../../modules/types';
import Name2Icon from './Name2Icon';
// export default {
//     name: 'pous',
//     list: List,
//     // create: Create,
//     // edit: Edit,
//     // show: Show,
//     icon: NotListedLocation,
//     langs: supported_content_langs
// }

type CustomExpFunc=(name:string, fields:ExpField[], modules:ModuleTypes[],defaultValues:Partial<{[key in ExpField]:any}>, options?:Dict)=>Dict;

const customExp:CustomExpFunc = (name, fields, modules, defaultValues, options) => ({
    name: name,
    list: List,
    create: Create,
    edit: Edit,
    // show: Show,
    icon: options&&options["icon"]? Name2Icon(options["icon"]): NotListedLocation,
    options:{fields,modules,defaultValues, ...options}
});


export {default as Fields} from "./Fields";
export default<CustomExpFunc> customExp;