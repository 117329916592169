import React from "react";
import Typography from "@material-ui/core/Typography/Typography";

export const TagTypes={
    Generic:'generic',
    Accounting:"accounting",
    Airport:"airport",
    AmusementPark:"amusement_park",
    Aquarium:"aquarium",
    ArtGallery:"art_gallery",
    Atm:"atm",
    Bakery:"bakery",
    Bank:"bank",
    Bar:"bar",
    BeautySalon:"beauty_salon",
    BicycleStore:"bicycle_store",
    BookStore:"book_store",
    BowlingAlley:"bowling_alley",
    BusStation:"bus_station",
    Cafe:"cafe",
    Campground:"campground",
    CarDealer:"car_dealer",
    CarRental:"car_rental",
    CarRepair:"car_repair",
    CarWash:"car_wash",
    Casino:"casino",
    Cemetery:"cemetery",
    Church:"church",
    CityHall:"city_hall",
    ClothingStore:"clothing_store",
    ConvenienceStore:"convenience_store",
    Courthouse:"courthouse",
    Dentist:"dentist",
    DepartmentStore:"department_store",
    Doctor:"doctor",
    Electrician:"electrician",
    ElectronicsStore:"electronics_store",
    Embassy:"embassy",
    FireStation:"fire_station",
    Florist:"florist",
    FuneralHome:"funeral_home",
    FurnitureStore:"furniture_store",
    GasStation:"gas_station",
    Gym:"gym",
    HairCare:"hair_care",
    HardwareStore:"hardware_store",
    HinduTemple:"hindu_temple",
    HomeGoodsStore:"home_goods_store",
    Hospital:"hospital",
    InsuranceAgency:"insurance_agency",
    JewelryStore:"jewelry_store",
    Laundry:"laundry",
    Lawyer:"lawyer",
    Library:"library",
    LiquorStore:"liquor_store",
    LocalGovernmentOffice:"local_government_office",
    Locksmith:"locksmith",
    Lodging:"lodging",
    MealDelivery:"meal_delivery",
    MealTakeaway:"meal_takeaway",
    Mosque:"mosque",
    MovieRental:"movie_rental",
    MovieTheater:"movie_theater",
    MovingCompany:"moving_company",
    Museum:"museum",
    NightClub:"night_club",
    Painter:"painter",
    Park:"park",
    Parking:"parking",
    PetStore:"pet_store",
    Pharmacy:"pharmacy",
    Physiotherapist:"physiotherapist",
    Plumber:"plumber",
    Police:"police",
    PostOffice:"post_office",
    RealEstateAgency:"real_estate_agency",
    Restaurant:"restaurant",
    RoofingContractor:"roofing_contractor",
    RvPark:"rv_park",
    School:"school",
    ShoeStore:"shoe_store",
    ShoppingMall:"shopping_mall",
    Spa:"spa",
    Stadium:"stadium",
    Storage:"storage",
    Store:"store",
    SubwayStation:"subway_station",
    Supermarket:"supermarket",
    Synagogue:"synagogue",
    TaxiStand:"taxi_stand",
    TrainStation:"train_station",
    TransitStation:"transit_station",
    TravelAgency:"travel_agency",
    VeterinaryCare:"veterinary_care",
    Zoo:"zoo"
}

export const styles={
    div1:{
        display: 'block',
    },
    div2:{
        marginTop: '16px',
        marginBottom: '8px',
    },
    valueDiv:{
        color: 'currentColor',
        width: '100%',
        border: '0',
        margin: '0',
        padding: '8px 0 4px',
        display: 'block',
        boxSizing: 'content-box',
        background: 'none',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        verticalAlign: 'middle'
    },
    field1:{
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '0',
        fontSize: '0.75rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: '1',
        display: 'inline-block',
        maxWidth: '20em'
    },
    div:
    {
        marginTop: '16px',
        marginBottom: '8px',
        display: 'inline-flex',
        flexDirection: 'column',
        lineHeight:"1",
    }
}