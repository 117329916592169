import EffectList from "./EffectList";
import EffectsIcon from "@material-ui/icons/Star";
import EffectCreate from "./EffectCreate";

export default {
	name: "visualEffects",
	list: EffectList,
	create: EffectCreate,
	icon: EffectsIcon,
	options: { label: "root.resources.visualEffects" }
};
