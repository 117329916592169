import React, { ReactElement } from "react";
import { latLon_rgx } from "../helpers";
var debug = require("debug")("app:components:gpsprovider");

interface GPSProviderProps {
	children: any;
	live?: boolean;
}

const Provider: React.FC<GPSProviderProps> = (props: GPSProviderProps) => {
	const [latLon, setLatLon] = React.useState<Coordinates>();
	const { children, live } = props;
	let watchId:number|undefined = undefined;
	watchId = React.useMemo(() => {
		if (!live) {
			if (watchId) navigator.geolocation.clearWatch(watchId);
			navigator.geolocation.getCurrentPosition(
				pos => {
					setLatLon(pos.coords);
				},
				error => {
					console.error("ERROR RETRIEVING USER LOCATION", error);
				}
			);
		} else {
			return navigator.geolocation.watchPosition(
				pos => {
					setLatLon(pos.coords);
				},
				error => {
					console.error("ERROR RETRIEVING USER LOCATION", error);
				},
				{
					maximumAge:60000
				}
			);
		}
		return undefined;
	}, [live]);
	React.useEffect(() => {
		if (!watchId) return undefined;
		return ()=>{
			debug("will clear watch id", watchId);
			navigator.geolocation.clearWatch(watchId!);
			watchId=undefined;
		};
	}, []);
	debug("latlon", latLon, watchId);
	// if (!latLon) return <></>;
	if (children instanceof Function) {
		return children(latLon);
	}
	return (
		<>
			{React.Children.map(children, child =>
				React.cloneElement(child, { latLon, ...child.props })
			)}
		</>
	);
};

export default Provider;
