import React from "react";
import { addField, regex } from "react-admin";
import GoogleMaps from "./GoogleMaps";
import {
	Paper,
	withStyles,
	createStyles,
	Theme,
	Typography
} from "@material-ui/core";
import InputBase, {
	InputBaseProps,
	InputBaseComponentProps
} from "@material-ui/core/InputBase";
import _ from "lodash";
import { Marker } from "@react-google-maps/api";
import { latLon_rgx, stringToLatLng } from "../helpers";
var debug = require("debug")("app:components:geolocationinput");

const textBoxStyles = createStyles((theme: Theme) => ({
	root: {
		position: "relative",
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		left: "50%",
		marginLeft: "-120px",
		margin: "10px",
		width: "240px"
	},
	typography: {
		width: "fit-content",
		position: "relative",
		left: "50%",
		marginLeft: "-120px",
		marginTop: "-10px"
	},
	input: {
		marginLeft: theme.spacing(),
		flex: 1
	},
	error: {
		textDecoration: "underline",
		textDecorationColor: "red",
		textDecorationStyle: "dashed"
	}
}));

interface MUITextInputProps extends InputBaseProps {
	[key: string]: any;
	classes?: any;
}

const MUITextInput: React.FC<MUITextInputProps> = (
	props: MUITextInputProps
) => {
	const { classes, submit, errorMessage, ...restProps } = props;
	const inputClasses = _.pick(classes, "error");
	return (
		<>
			<Paper className={classes && classes.root}>
				<InputBase
					classes={inputClasses}
					id="latLon_input"
					className={classes && classes.input}
					placeholder="Latitude, Longitude"
					inputProps={{ "aria-label": "Latitude, Longitude" }}
					{...restProps}
				/>
			</Paper>
			{props.error && (
				<Typography
					className={classes && classes.typography}
					variant="caption"
					color="error"
				>
					{errorMessage}
				</Typography>
			)}
		</>
	);
};

const LatLongTextInput = withStyles(textBoxStyles)(MUITextInput);

const GeoLocationInput: React.FC<any> = (props: any) => {
	const { input, meta, style, children } = props;
	const latLng = React.useMemo(() => {
		return stringToLatLng(input.value);
	}, [input.value]);
	const MAP = React.useRef<any>(undefined);
	const onChange = React.useCallback((ev: any) => {
		input.onChange(ev);
		const ll = stringToLatLng(ev.target.value);
		ll && MAP.current && MAP.current.panTo(ll);
	}, []);
	const regexError = !latLon_rgx.test(input.value);
	// console.log(regexError(input.value))
	return (
		<div style={style || { width: "100%", height: "50vmin" }}>
			<GoogleMaps
				onClick={e => {
					debug("???????", e);
					input.onChange(`${e.latLng.lat()}, ${e.latLng.lng()}`);
				}}
				onLoad={map => {
					MAP.current = map;
					const initValue = stringToLatLng(input.value);
					initValue && map.setCenter(initValue);
				}}
				mapProps={{
					mapContainerStyle: {
						width: "100%",
						height: "100%",
						display: "flex",
						flexDirection: "column-reverse"
					},
					mapTypeId:"hybrid"
				}}
				ignoreUserLocation={!!input.value}
			>
				<LatLongTextInput
					error={meta.touched && (regexError || !!meta.error)}
					errorMessage={
						(regexError &&
							"Invalid Format, Expected: number, number") ||
						meta.error
					}
					inputProps={{
						...(input as InputBaseComponentProps),
						onChange, // ;),
						autoComplete: "off"
					}}
				/>
				{children && children}
				{latLng && <Marker position={latLng} />}
			</GoogleMaps>
		</div>
	);
};

const FieldedInput = addField(GeoLocationInput);

const FinalInput: React.FC<any> = (props: any) => {
	return (
		<FieldedInput
			{...props}
			validate={[
				...(props.validate || [])
				// regex(latLon_rgx, "Invalid Format, Expected: number, number")
			]}
		/>
	);
};

export default FieldedInput;
