export default {
	"ui:options": {
		addable: false,
		expandable: true
	},
	effects: {
		items: {
            effectKey:{
                "ui:widget":"effectKey"
            },
			soundEffect: {
				"ui:widget": "audioAssetRef"
            },
            visualEffectKey: {
                "ui:widget": "effectRef"
            }
		}
	}
};
