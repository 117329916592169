import create from "./OfficeCreate";
import edit from "./OfficeEdit";
import list from "./OfficeList";
import icon from '@material-ui/icons/Business';

export default {
    name:"offices",
    list,
    edit,
    create,
    icon,
    options:{
        label:"root.resources.offices"
    }
}