import EffectsModule from "./EffectsModule";
import EventsModule from "./EventsModule";
import SceneModule from "./SceneModule";
import StyleModule from "./StyleModule";
import TransformModule from "./TransformModule";
import { ModuleTypes } from "../types";
import SkyboxModule from "./SkyboxModule";
import LightModule from "./LightModule";
import ShapeModule from "./ShapeModule";

const UISchemas:Partial<{[key in ModuleTypes]:any}> ={
    EventsModule:EventsModule,
    EffectsModule:EffectsModule,
    SkyboxModule:SkyboxModule,
    LightModule:LightModule,
    StyleModule:StyleModule,
    TransformModule:TransformModule,
    ShapeModule:ShapeModule
}
export default UISchemas;