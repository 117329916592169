import React from "react";

const ArrayFieldTitle = (props: any) => {
	const { TitleField, idSchema, title, required } = props;
	if (!title) {
		return null;
	}
	const id = `${idSchema.$id}__title`;
	return <TitleField id={id} title={title} required={required} />;
};

const ArrayFieldDescription = (props: any) => {
	const { DescriptionField, idSchema, description } = props;
	if (!description) {
		return null;
	}
	const id = `${idSchema.$id}__description`;
	return <DescriptionField id={id} description={description} />;
};

const actualIcons: { [key: string]: string } = {
	plus: "➕",
	remove: "❌",
	"arrow-up": "⬆️",
	"arrow-down": "⬇️"
};

const IconButton = (props: any) => {
	const { type = "default", icon, className, ...otherProps } = props;
	return (
		<button
			type="button"
			className={`btn btn-${type} ${className}`}
			{...otherProps}
		>
			{actualIcons[icon]}
		</button>
	);
};

// Used in the two templates
const DefaultArrayItem = (props: any) => {
	const btnStyle = {
		flex: 1,
		paddingLeft: 6,
		paddingRight: 6,
		fontWeight: "bold"
	};
	return (
		<div key={props.key} className={props.className}>
			<div className={props.hasToolbar ? "col-xs-9" : "col-xs-12"}>
				{props.children}
			</div>

			{props.hasToolbar && (
				<div className="col-xs-3 array-item-toolbox">
					<div
						className="btn-group"
						style={{
							display: "flex",
							justifyContent: "space-around"
						}}
					>
						<button disabled>#{props.index}</button>
						{(props.hasMoveUp || props.hasMoveDown) && (
							<IconButton
								icon="arrow-up"
								className="array-item-move-up"
								tabIndex="-1"
								style={btnStyle}
								disabled={
									props.disabled ||
									props.readonly ||
									!props.hasMoveUp
								}
								onClick={props.onReorderClick(
									props.index,
									props.index - 1
								)}
							/>
						)}

						{(props.hasMoveUp || props.hasMoveDown) && (
							<IconButton
								icon="arrow-down"
								className="array-item-move-down"
								tabIndex="-1"
								style={btnStyle}
								disabled={
									props.disabled ||
									props.readonly ||
									!props.hasMoveDown
								}
								onClick={props.onReorderClick(
									props.index,
									props.index + 1
								)}
							/>
						)}

						{props.hasRemove && (
							<IconButton
								type="danger"
								icon="remove"
								className="array-item-remove"
								tabIndex="-1"
								style={btnStyle}
								disabled={props.disabled || props.readonly}
								onClick={props.onDropIndexClick(props.index)}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

const AddButton = (props: any) => {
	const { className, onClick, disabled } = props;
	return (
		<div className="row">
			<p className={`col-xs-3 col-xs-offset-9 text-right ${className}`}>
				<IconButton
					type="info"
					icon="plus"
					className="btn-add col-xs-12"
					tabIndex="0"
					onClick={onClick}
					disabled={disabled}
				/>
			</p>
		</div>
	);
};
const DefaultNormalArrayFieldTemplate = (props: any) => {
	return (
		<fieldset className={props.className} id={props.idSchema.$id}>
			<ArrayFieldTitle
				key={`array-field-title-${props.idSchema.$id}`}
				TitleField={props.TitleField}
				idSchema={props.idSchema}
				title={props.uiSchema["ui:title"] || props.title}
				required={props.required}
			/>

			{(props.uiSchema["ui:description"] || props.schema.description) && (
				<ArrayFieldDescription
					key={`array-field-description-${props.idSchema.$id}`}
					DescriptionField={props.DescriptionField}
					idSchema={props.idSchema}
					description={
						props.uiSchema["ui:description"] ||
						props.schema.description
					}
				/>
			)}

			<div
				className="row array-item-list"
				key={`array-item-list-${props.idSchema.$id}`}
			>
				{props.items &&
					props.items.map((p: any) => DefaultArrayItem(p))}
			</div>

			{props.canAdd && (
				<AddButton
					className="array-item-add"
					onClick={props.onAddClick}
					disabled={props.disabled || props.readonly}
				/>
			)}
		</fieldset>
	);
};

export default DefaultNormalArrayFieldTemplate;
