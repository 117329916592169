import React, { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import Logo from './Logo';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText={translate('pos.configuration')}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
        />
    );
});

const CustomUserMenu = (props: any) => {
    const [userId, userName] = React.useMemo(() => {
        return [window.localStorage.getItem("userId"), window.localStorage.getItem("username")]
    }, []);
    return (
        <UserMenu {...props}>
            <MenuItemLink
                to={`/users/${userId}`}
                primaryText={userName}
                leftIcon={<PersonIcon />}
            />
            <ConfigurationMenu />
        </UserMenu>
    )
};

const CustomAppBar = (props: any) => {
    const classes = useStyles();
    return (
        <AppBar {...props} userMenu={<CustomUserMenu />} />
    );
};

export default CustomAppBar;