import React from "react";
import {
	createStyles,
	Card,
	CardMedia,
	CardContent,
	Typography,
	CardActions,
	Button,
	withStyles
} from "@material-ui/core";
import { CardActionArea } from "@material-ui/core";
import defaultImage from "./MediaCard_defaultImage.png"

const styles = createStyles({
    card: { maxWidth: "100%", margin: "0.5em" },
    cardActions:{ placeContent: "flex-end" },
	media: { height: 140 }
});

const MediaCard: React.FC<any> = (props: any) => {
	const { classes, actions, media, title, description, onClick } = props;
	return (
		<Card className={classes.card}>
			<CardActionArea onClick={onClick}>
				<CardMedia
					className={classes.media}
					image={media||defaultImage}
                    title="media"
				/>
				<CardContent>
					<Typography gutterBottom variant="h6" component="h2">
						{title}
					</Typography>
					<Typography
						variant="body2"
						color="textSecondary"
						component="p"
					>
						{description}
					</Typography>
				</CardContent>
			</CardActionArea>
			{actions && (
				<CardActions className={classes.cardActions}>
					{React.Children.map(actions, (child, index) => child)}
				</CardActions>
			)}
		</Card>
	);
};

const StyledMediaCard = withStyles(styles)(MediaCard);

export default StyledMediaCard;
