import React from "react";
import { SimpleForm, TextInput, ArrayInput, SimpleFormIterator, Edit } from "react-admin";
import OfficeForm from "./OfficeForm";

const OfficeEdit: React.FC<any> = (props: any) => {
    return (
        <Edit {...props}>
            <OfficeForm />
        </Edit>
    )
}

export default OfficeEdit;