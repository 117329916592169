import React, { FC, useState, createElement, cloneElement } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelIcon from '@material-ui/icons/Label';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink, getResources } from 'react-admin';
import { getDataRoutes, loadPublicConfig } from "../ariabo.config";
import _ from "lodash";
import TagIcon from '@material-ui/icons/Bookmarks';
// import visitors from '../visitors';
// import orders from '../orders';
// import invoices from '../invoices';
// import products from '../products';
// import categories from '../categories';
// import reviews from '../reviews';
import SubMenu from './SubMenu';
import { AppState } from '../types';
import { useConfig } from '../hooks';
var debug = require("debug")("app:layout:menu");

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState<{ [key: string]: any }>({});
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => (state.admin.ui as any).sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change
    const config=useConfig(); //force rerender on config load
    debug("Im dumb", config)
    const tagDerivatives = React.useMemo<string[]>(() => ["tags", ...getDataRoutes().filter(i => i.to === "tags").map(i => i.from)], [config.apiUrl]);
    debug("i guess im retarded then", tagDerivatives)
    const handleToggle = (menu: string) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    const resources = useSelector(getResources) as any;
    const nonTagsResources = resources.filter((r: any) => r.hasList && !tagDerivatives.includes(r.name))
    const tagResources = resources.filter((r: any) => r.hasList && tagDerivatives.includes(r.name));
    const applicationResources = _.remove(nonTagsResources, (r: any) => r.name === "applicationConfigs");
    const boConfig = loadPublicConfig();
    debug("resources", resources);
    debug("tag derivatives", tagDerivatives);
    debug("tagResources", resources);
    return (
        <div>
            {' '}
            {boConfig.dashboard && <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />}
            {applicationResources.map((resource: any) => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={translate(resource.options && resource.options.label || resource.name)}
                    leftIcon={resource.icon && <resource.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}
            {tagResources.length > 0 &&
                <SubMenu
                    name={config.tagContextName}
                    handleToggle={() => handleToggle("tags")}
                    isOpen={state["tags"]}
                    sidebarIsOpen={open}
                    icon={<TagIcon />}
                    dense={dense}
                >
                    {tagResources.map((resource: any) => (
                        <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}`}
                            primaryText={translate(resource.options && resource.options.label || resource.name)}
                            leftIcon={resource.icon && <resource.icon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            name="root.resources.tags"
                        />
                    ))}
                </SubMenu>
            }
            {nonTagsResources.map((resource: any) => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={translate(resource.options && resource.options.label || resource.name)}
                    leftIcon={resource.icon && <resource.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}
            {isXSmall && (
                <MenuItemLink
                    to="/configuration"
                    primaryText={translate('pos.configuration')}
                    leftIcon={<SettingsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {isXSmall && logout}
        </div>
    );
};

export default Menu;