import React from 'react'
import {
    Filter,
    TextInput,
    ReferenceInput,
    SelectInput,
    NullableBooleanInput 
} from 'react-admin';
import { TagTypes } from './utils';
import PropTypes from 'prop-types';
import { ChipInput } from '../../components';

const filterDict={
    name:<TextInput key='t_filter_0' label="Search" source="name" alwaysOn />,
    attributes: <ChipInput key='t_filter_5' label="Attributes" source="modules.classification.attributes" />,
    group:
        <ReferenceInput key='t_filter_1' label="Group" source="group.expGroups_id" reference="expGroups" allowEmpty={true} >
            <SelectInput  optionText="name"/>
        </ReferenceInput>,
    brand:
        <ReferenceInput key='t_filter_2' label="Brand" source="group.expGroups_id" reference="expGroups" allowEmpty={true} >
            <SelectInput  optionText="name"/>
        </ReferenceInput>,
    type:
        <SelectInput key='t_filter_3' source="type" choices={Object.keys(TagTypes).map(v=>(
            {id:TagTypes[v], name:v}
        ))}/>,
    isPublished:
            <NullableBooleanInput key="t_filter_6" label="Is Published" source="#isPublished"/>,
    publicState:
            <SelectInput key='t_filter_7' label="Public State" source="publicState" choices={[{name:"Updated", id:2}, {name:"Outdated", id:1}]}/>,
    createdBy:
        <ReferenceInput key='t_filter_4' label="Author" source="createdBy" reference="users" allowEmpty={true} >
            <SelectInput  optionText="userName"/>
        </ReferenceInput>
        // <SelectInput key='t_filter_4' source="stateInfo.createdBy" choices={Object.keys(TagTypes).map(v=>(
        //     {id:TagTypes[v], name:v}
        // ))}/>
}
const TagFilter = ({searchboxonly=false, setCustomFilter=undefined, sources, ...props}) => (
    <Filter {...props}>
        {searchboxonly?  (<TextInput label="Search" source="name" alwaysOn />) : sources.map(i=>filterDict[i])}
    </Filter>
);

TagFilter.propTypes = {
    sources:PropTypes.array,
    searchboxonly:PropTypes.bool
}

export default TagFilter;