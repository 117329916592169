import React from "react";
import { Create, Edit, SimpleForm, TabbedForm, Toolbar, SaveButton, DeleteButton } from "react-admin";
import { ReactAdminCreateProps } from "../../../types";
import ProjectForm from "./ProjectForm";
import { FormDataJsonDrawerButton } from "../../../components";
import { makeStyles } from "@material-ui/styles";

const GetName = (props: any) => {
    return props && props.record && <span>Editing {props.record.name}</span>;
};

const useToolbarStyles = makeStyles({
    toolbar: {
        display: "flex",
        justifyContent: "space-between"
    }
})

const CustomToolbar: React.FC<any> = (props: any) => {
    const { jsonViewer, ...restProps } = props;
    const classes = useToolbarStyles();
    return (
        <Toolbar {...restProps} classes={classes}>
            <SaveButton />
            {jsonViewer &&
                <FormDataJsonDrawerButton />
            }
            <DeleteButton />
        </Toolbar>
    )
}

const ExpEdit: React.FC<ReactAdminCreateProps> = (
    props: ReactAdminCreateProps
) => {
    const { options } = props;
    const defaultValues = options && options["defaultValues"];
    return (
        <Edit title={<GetName />} {...props} >
            <SimpleForm
                toolbar={<CustomToolbar jsonViewer={options&&options.jsonViewer} />}
                initialValues={{
                    type: "project_console",
                    name: "root",
                    ...defaultValues
                }}
            >
                <ProjectForm modules={options && options.modules}/>
            </SimpleForm>
        </Edit >
    );
};
export default ExpEdit;