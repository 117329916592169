import React from "react";
import { Edit } from "react-admin";
import AssetForm from "./AssetForm";

const AssetEdit = (props) => {
    return (
        <Edit {...props}>
            <AssetForm />
        </Edit>
    );
};

export default AssetEdit;
