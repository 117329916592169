import React, { ReducerAction } from "react";
import { createReducer, createAction } from "@reduxjs/toolkit";
import { ModuleTypes } from "../types";

type ModuleCollection = Partial<{[key in ModuleTypes]:any}>
const GetInitialModuleCollection:()=>ModuleCollection = () =>Object.keys(ModuleTypes).reduce<{[key in ModuleTypes]?:any}>((obj, k)=>{
    obj[k as ModuleTypes]=undefined;
    return obj;
}, {})
export const GET_MODULESCHEMAS = createAction("GET_MODULESCHEMAS");
export const SET_MODULESCHEMAS = createAction("SET_MODULESCHEMAS")

const moduleSchemasReducer = createReducer(GetInitialModuleCollection(), {
    [GET_MODULESCHEMAS.type]: (state, action) => state,
    [SET_MODULESCHEMAS.type]: (state, action) => action.payload
})

export default moduleSchemasReducer;