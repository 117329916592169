import React from 'react'
import {
    List,
    Datagrid,
    EditButton,
    CardActions,
    CreateButton,
    ExportButton,
    translate,
    FunctionField,
    ArrayField,
    SingleFieldList
} from 'react-admin';
import { restServer } from "../../../dataProvider";
import { DeleteDialog, PublishButton, AccessControlledFeature } from "../../../components";
import TagFilter from '../TagFilter';
import PostBulkActionButtons from '../TagBulkActionButtons';
import { Fields } from "../customExp/Fields";
import ExpListExpand from "./ExpListExpand";
import _ from 'lodash';
import { Chip } from '@material-ui/core';

const rowStyle = (record, index) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.01)'
});

const PostActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    onCreateClick
}) => (
        <CardActions>
            {bulkActions && React.cloneElement(bulkActions, {
                basePath,
                filterValues,
                resource,
                selectedIds,
                onUnselectItems,
            })}
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
            <ExportButton
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
        </CardActions>
    );

export default translate(class extends React.Component {
    state = {
        openCreationPrompt: false
    }
    onCreateClick = () => {
        this.setState({ openCreationPrompt: true })
    }
    render() {
        const {
            props,
        } = this;
        const { permissions, translate } = props;
        return (

            <List
                perPage={25}
                actions={<PostActions onCreateClick={this.onCreateClick} />}
                bulkActionButtons={<PostBulkActionButtons permissions={permissions} {...props} />}
                filters={<TagFilter setCustomFilter={this.setfilter} sources={['name']} />}
                title="root.pages.projects" 
                sort={{field:"id", order:"DESC"}}
                {...props}
            >
                <Datagrid hasBulkActions={true} expand={<ExpListExpand />} /*rowStyle={rowStyle}*/ rowClick="edit">
                    {Fields["img"]}
                    {Fields["name"]}
                    <ArrayField label="Attributes" source="modules.classification.attributes">
                        <SingleFieldList>
                            <FunctionField label="Name" render={attr => <Chip size="small" label={attr} />} />
                            {/* <ChipField source="name"/> */}
                        </SingleFieldList>
                    </ArrayField>
                    {Fields["authors"]}
                    {Fields["creationTime"]}
                    <AccessControlledFeature feature="PUBLISH">
                        <PublishButton />
                    </AccessControlledFeature>
                    {/* <ShowButton label='' /> */}
                    <EditButton label='' />
                    <DeleteDialog permissions={permissions} {...props}
                        dialogConditional={(record) => record.stateInfo.publishedId}
                        dialogTitle={translate("ra.action.delete")}
                        dialogText={translate("root.message.deletePublishedExpPrompt")}
                        permissionDeniedText={translate("root.message.deletePublishedExpDenial")}
                        permissionRequired="admin"
                        beforeDelete={(record) => {
                            if (record.stateInfo.publishedId)
                                restServer.unpublishTag(record);
                        }}
                    />
                </Datagrid>
            </List>

        );
    }
})