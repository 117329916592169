/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useInput } from "react-admin";

const AutocompleteInput: React.FC<any> = (props: any) => {
    const { choices, ...restProps } = props;
    const raInputProps = useInput(restProps);
    const { source, label } = restProps;
    return (
        <Autocomplete
            id="free-solo-demo"
            freeSolo
            options={choices}
            onChange={(ev:any, value:any)=>raInputProps.input.onChange(value)}
            style={{ width: 256 }}
            // {...raInputProps.input}
            value={raInputProps.input.value}
            renderInput={params => (
                <TextField
                    {...raInputProps.input}
                    {...params}
                    {...raInputProps.meta}
                    style={{ width: 256 }}
                    label={label || source}
                    margin="normal"
                    InputProps={{ ...raInputProps, ...params.InputProps }}
                />
            )}
        />

    );
}
export default AutocompleteInput;