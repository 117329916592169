import React from "react";
import { IConfig } from "../types";

const eventName = "onConfigLoaded";
const useConfig: () => IConfig = () => {
    const [config, setConfig] = React.useState<IConfig>((window as any).Config)

    React.useLayoutEffect(() => {
        const eventCallback =()=>setConfig((window as any).Config)
        window.addEventListener(eventName, eventCallback);
        return ()=>window.removeEventListener(eventName, eventCallback);
    }, [])

    return config;
}

export default useConfig;