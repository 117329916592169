import React from "react";
import { List, Datagrid, TextField, EditButton, DeleteButton,FunctionField } from "react-admin";
import { Avatar } from "@material-ui/core";
import get from "lodash/get";

const ApplicationConfigList: React.FC = (props: any) => {
    return (
        <List {...props} title="root.resources.applications" sort={{ field: "id", order: "DESC" }}>
            <Datagrid>
                <FunctionField label="Logo" render={(record: any) =>
                    <Avatar
                        alt={`${record["name"]}`}
                        src={get(record, "generalData.logo") + ".thumb"}
                    >
                        <Avatar
                            alt={`${record["name"]}`}
                            src={get(record, "generalData.logo")}
                        >
                            {record["name"][0]}
                        </Avatar>
                    </Avatar>
                }  {...props} />
                <TextField source="name" label="Name" />
                <TextField source="packageId" label="Package ID" />
                <TextField source="applicationVersion" label="App Version" />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    )
}

export default ApplicationConfigList;