import {getDataRoutes} from "../ariabo.config"

export default  (resource: string) => {
	const dataRoutes = getDataRoutes();
	const customRoute = dataRoutes.find(route => route.from === resource);
	const filter = customRoute && customRoute.withFilter;
	if (!filter) return {};
	return ({
		[filter.field]: filter.value
	})
}