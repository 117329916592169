import React from "react";
import {
	ReferenceArrayField,
	ChipField,
    SingleFieldList,
    TextField
} from "react-admin";
import _ from "lodash";
import PersonIcon from "@material-ui/icons/Person";
import EditIcon from "@material-ui/icons/Edit";
import { Avatar } from "@material-ui/core";
import jwt_decode from "jwt-decode";

var debug = require("debug")("app:components:authorsfield");

const AuthorsField: React.FC<any> = (props: any) => {
	const { source, record } = props;
	const { authorsRecord, author, editor } = React.useMemo(() => {
        const stateInfo = jwt_decode(record["stateInfo"])
        debug("decoded",stateInfo);
		const modifiedBy = _.get(stateInfo, "modifiedBy");
		const createdBy = _.get(stateInfo, "createdBy");
		const author = (createdBy && createdBy.substring(0, createdBy.indexOf("@"))) || createdBy;
        const editor = (modifiedBy &&  modifiedBy.substring(0, modifiedBy.indexOf("@"))) || modifiedBy;
        debug("author, editor",createdBy, modifiedBy, author, editor);
		return {
			authorsRecord: { array: [...new Set([author, editor])] },
			author,
			editor
		};
	}, source);
    debug("props",props);
	return (
		<ReferenceArrayField
			{...props}
			record={authorsRecord}
			reference="users"
            source="array"
            link="show"
		>
			<SingleFieldList>
				{React.createElement((props: any) => {
					const record = props.record;
                    const id = record && record.id;
                    debug("SingleFieldList props",props);
                    if(!record)return <div></div>
					return (
						<ChipField
							{...props}
							source="userName"
							size="small"
							avatar={
								id && (
									<Avatar>
										{id === author ? (
											<PersonIcon />
										) : (
											<EditIcon />
										)}
									</Avatar>
								)
							}
						/>
					);
				})}
			</SingleFieldList>
		</ReferenceArrayField>
	);
};

export default AuthorsField;
