import React from "react";
import { WidgetProps } from "react-jsonschema-form";
import { useQuery, Loading, Error } from "react-admin";
import {
    Select,
    MenuItem
} from "@material-ui/core";
import { ASSET_TYPES } from "../../models/asset";
import { AssetQuickPreviewButtonBase } from "../../models/asset/AssetQuickPreviewButton";
var debug = require('debug')('app:components:rjsf:assetrefinput')

const AssetRefInput: React.FC<WidgetProps & Partial<{ resource: string }>> = (props: WidgetProps & Partial<{ resource: string }>) => {
    const {
        schema,
        id,
        options,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onChange,
        onBlur,
        onFocus,
        placeholder,
        formContext,
        resource
    } = props;
    // const form = useForm();
    // const formState = form.getState();
    // const formValues = formState.values;
    // debug(formValues);
    const { treeIndex, externalSource } = formContext;

    debug(props);
    const correctSource = props.id.replace(/_/g, ".");
    debug("correctSource", correctSource);
    const absoluteSource = correctSource.replace("root.", externalSource + ".");
    debug("absoluteSource", absoluteSource)
    const inNodeSource = treeIndex ? absoluteSource.replace(formContext.treeIndex + ".", "").replace(treeIndex, "") : absoluteSource;
    debug("inNodeSource", inNodeSource);
    const { data, loading, error } = useQuery({
        type: "getList",
        resource: resource || "assets",
        payload: {
            pagination: { page: 0, perPage: 0 },
            sort: { field: "id", order: "DESC" }
        }
    });

    if (loading || error) return <Loading />;
    if (error) return <Error error={error} />;//this doesnt work, wtf react admin
    if (!data) return null;

    debug("Value>", value)
    debug("data", data)
    return (
        <>
            <Select
                style={{ marginLeft: "0.5em" }}
                id={id}
                className="form-control"
                value={!!value ? value : ""}
                required={required}
                disabled={disabled || readonly}
                autoFocus={autofocus}
                onBlur={
                    onBlur &&
                    (event => {
                        onBlur(id, event.target.value);
                    })
                }
                onFocus={
                    onFocus &&
                    (event => {
                        onFocus(id, event.target.value);
                    })
                }
                onChange={event => {
                    onChange(event.target.value);
                }}
            >
                <MenuItem value={undefined}>
                    <span>
                        None
                    </span>
                </MenuItem>
                {data.map((record: any, index: number) => (
                    <MenuItem value={record.id}>
                        <span>
                            {(ASSET_TYPES as any).icons[record.assetType]}{" "}
                            {record.name}
                        </span>
                    </MenuItem>
                ))}
            </Select>
            {(!!value) && <AssetQuickPreviewButtonBase id={value} record={data.find((rec: any) => rec.id === value)} />}
        </>
    );
};

export const AudioAssetRefInput: React.FC<WidgetProps> = (props: WidgetProps) => (
    <AssetRefInput {...props} resource="sounds" />
)

export const PanoramaImageRefInput: React.FC<WidgetProps> = (props: WidgetProps) => (
    <AssetRefInput {...props} resource="panoramaImages" />
)

export default AssetRefInput;
