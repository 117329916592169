import React, { useState } from "react";
import useAuthToken from "./useAuthToken";
import { useDispatch, useSelector } from "react-redux";
import {
	IResourceSchemas,
	IReSchemsReduxAction,
	EReSchemsReduxActions,
	GET_MODULESCHEMAS,
	SET_MODULESCHEMAS
} from "../customReducers";
import useConfig from "./useConfig";
import urljoin from "url-join";
import { ModuleTypes } from "../types";
import pick from "lodash/pick";
import moduleSchemasReducer from "../customReducers/moduleSchemasReducer";
const debug = require("debug")("app:hooks:useresourceschemas");

const stateName = "resourceSchemas";

const resources: { [key in keyof IResourceSchemas]: string } = {
	Assets: "assets",
	Tags: "tags",
	ApplicationConfigs: "applicationConfigs",
	Effects: "visualEffects"
};

const schemaRoute = "getSchema";

const useResourceSchemas = () => {
	debug("Enter schemas");
	const acessToken = useAuthToken();
	const dispatch = useDispatch();
	const config = useConfig();
	const resourceSchemasRedux: IResourceSchemas = useSelector(
		(state: any) => state[stateName]
	);

	React.useLayoutEffect(() => {
		if (Object.values(resourceSchemasRedux).every(value => !!value)) return;
		(async () => {
			const keys: Array<keyof IResourceSchemas> = Object.keys(
				resources
			) as any;
			const headers = {
				Authorization: `bearer ${acessToken}`
			};
			const requests = keys.map<
				[keyof IResourceSchemas, Promise<Response>]
			>(k => [
				k,
				fetch(urljoin(config.apiUrl, resources[k]!, schemaRoute), {
					headers
				})
			]);
			const allResponses = await Promise.all(
				requests.map(([k, req]) => req)
			);
			if (
				allResponses.some(
					(req: Response) => req.status < 200 || req.status >= 300
				)
			) {
				console.error(
					"Couldn't fetch one or more resources schemas, please check console"
				);
				return Promise.reject(
					"Couldn't fetch one or more resources schemas, please check console"
				);
			}
			const allSchemas = await Promise.all(
				allResponses.map(r => r.json())
			);
			const mappedSchemas = allSchemas
				.map<[keyof IResourceSchemas, any]>((schem, index) => [
					requests[index][0],
					schem
				])
				.reduce((obj: any, tuple) => {
					obj[tuple[0]] = tuple[1];
					return obj;
				}, {});
			debug("Fetchd schemas", mappedSchemas);
			dispatch<IReSchemsReduxAction>({
				type: EReSchemsReduxActions.SET_RESOURCESCHEMAS,
				data: mappedSchemas
			});
		})();
	}, [acessToken, config.apiUrl]);
	//const [resourceSchemas, setResourceSchemas] = useState
	return resourceSchemasRedux;
};

interface Schema {
	[key: string]: any;
}
type ModuleCollectionObject = { [key in ModuleTypes]: any };
type ModuleSchemasHook = () => ModuleCollectionObject | undefined;
type ModuleSchemaHook = (moduleType: ModuleTypes) => Schema | undefined;

const moduleSchemasStateName = "moduleSchemas";
export const useModuleSchemas: ModuleSchemasHook = () => {
	const acessToken = useAuthToken();
	const dispatch = useDispatch();
	const config = useConfig();
	const moduleSchemasRedux: ModuleCollectionObject = useSelector(
		(state: any) => state[moduleSchemasStateName]
	);
	React.useLayoutEffect(() => {
        // if(moduleSchemasReducer.)
		(async () => {
            if (Object.values(moduleSchemasRedux).every(value => !!value)) return;
			const headers = { Authorization: `bearer ${acessToken}` };
			const request = await fetch(
				urljoin(config.apiUrl, "modules", "configs"),
				{ headers }
			);
            const data = await request.json();
            Object.entries(data).forEach(([key,value]:[any,any])=>{data[key]=JSON.parse(value)});
			dispatch({ type: SET_MODULESCHEMAS.type, payload: data });
		})();
	}, [config.apiUrl, acessToken]);

	debug("Got modules", moduleSchemasRedux as ModuleCollectionObject);
	return moduleSchemasRedux as ModuleCollectionObject;
};

export const useModuleSchema: ModuleSchemaHook = moduleType => {
	const moduleSchemas = useModuleSchemas();
	debug("modules", moduleSchemas);
	if (moduleSchemas) debug("Target module", moduleSchemas[moduleType]);
	return moduleSchemas && moduleSchemas[moduleType];
};
export default useResourceSchemas;
