import React from "react";
import { Card, CardHeader, Avatar, CardContent, Typography, CardMedia, makeStyles, Theme, createStyles, CardActions, Chip, LinearProgress, Link } from "@material-ui/core"
import AssetTypes from "./asset-types";
import { red } from "@material-ui/core/colors";
import AssetView from "./AssetView";
import { EditButton, DeleteButton, useGetList, linkToRecord } from "react-admin";
import TroublesomeAssetQuickPreviewButton from "./AssetQuickPreviewButton";
import { useConfig } from "../../hooks";
var debug = require("debug")("app:models:assets:assetcard");

const recognizedMediaTypes = ["img", "video", "audio"]
const AssetQuickPreviewButton: React.FC<any> = TroublesomeAssetQuickPreviewButton;

const ReferersSection: React.FC<any> = (props: any) => {
    const { record } = props;
    const { data, ids, loading, error } = useGetList(
        "tags",
        { page: 1, perPage: 0 },
        { field: "_id", order: "DESC" },
        { "#refersAsset": record.id }
    );
    const config = useConfig();
    if (loading) return <LinearProgress color="primary" />;
    if (error) {
        console.error("AssetCard ReferersSection error fetching asset referors " + error);
        return null;
    }
    // debug("ReferersSection data", data, ids)

    if (!ids || ids.length < 1 || !data) return null;
    return (
        <CardContent>
            <Typography variant="subtitle2" color="textSecondary" component="p">
                Refs
            </Typography>
            {ids.map((id: any) =>
                // <Link href={linkToRecord(`#/${config.baseTagResource}`, id)}> //i need to fix this later
                <Link href={linkToRecord(`#/tags`, id)}> 
                    <Chip size="small" label={data[id].name} style={{maxWidth:"100%"}} />
                </Link>
            )}
        </CardContent>
    )
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            maxWidth: 345,
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        avatar: {
            backgroundColor: red[500],
        },
        actions: {
            placeContent: "flex-end"
        },
        "typography":{
            maxHeight:200,
            width:"100%",
            overflowY:"scroll",
            display: "-webkit-box",
            wordBreak: "break-word",
            overflowWrap: "break-word"
        }
    }),
);

const AssetCard: React.FC<any> = (props: any) => {
    const { record, basePath, resource, ...restProps } = props;
    const icons: { [key: string]: JSX.Element } = AssetTypes.icons;
    const classes = useStyles();
    const assetType = record["assetType"];
    const config = useConfig();
    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        {icons[record["assetType"]]}
                    </Avatar>
                }
                title={record["name"]}
            />
            {(assetType !== AssetTypes.TEXT&&assetType !== AssetTypes.AROC) ?
                <AssetView record={record} AVimgStyle={{ width: "100%", height: "auto" }} />
                :
                <CardContent>
                    <Typography classes={{"root":classes["typography"]}} variant="body2" color="textSecondary" component="p">
                        {record["content"]}
                    </Typography>
                </CardContent>
            }
            {(record["attributes"] && record["attributes"].length > 0) &&
                <CardContent>
                    <Typography variant="subtitle2" color="textSecondary" component="div">
                        Attributes
                    </Typography>
                    {record["attributes"].map((a: string) => <Chip size="small" label={a} />)}
                </CardContent>
            }
            {config.resources["Tags"] &&<ReferersSection record={record} />}
            <CardActions className={classes.actions}>
                <EditButton label="" resource={resource} basePath={basePath} record={props.record} />
                <AssetQuickPreviewButton label="" record={record} basePath={basePath} />
                <DeleteButton label="" resource={resource} basePath={basePath} record={record} />
                {/* {props.basePath === "/assets" &&
                    <DeleteButton resource="assets" basePath={basePath} record={record} />
                } */}
            </CardActions>
        </Card>
    )
}

export default AssetCard;