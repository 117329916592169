import Modal from "./Modal";
import React from "react";
import PropTypes from "prop-types";

const styles = {
    img:
    {
        top: "50%",
        width: "100%",
        position: "relative",
        transform: "translateY(-50%)"
    },
    div:
    {
        "height": "100%",
        "display": "block",
        "position": "relative",
        "overflow": "hidden"
    },
    imgFullCentered: {
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "width": "100%"
    }
}

class ImageField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showImg: false,
            fallbackthumb: undefined
        }
    }

    static propTypes = {
        handleModalClose: PropTypes.func,
        record: PropTypes.object,
        source: PropTypes.string,
        image: PropTypes.any,
        thumb: PropTypes.string,
        alt: PropTypes.string,
        divstyle: PropTypes.object,
        imgfullstyle: PropTypes.object
    }
    static defaultProps = {
        style: styles.img,
        divstyle: styles.div,
        thumb: undefined,
        imgfullstyle: styles.imgFullCentered,
        alt: ''
    }
    handleModalClose = () => {
        if (this.props.handleModalClose) this.props.handleModalClose();
        this.setState({ showImg: false });
    }
    onThumbError = (ev) => {
        if (this.state.fallbackthumb) return;
        this.setState({ fallbackthumb: this.props.record ? this.props.record[this.props.source] : this.props.image })
    }
    render() {
        const {
            record,
            source,
            image,
            alt,
            divstyle,
            imgfullstyle,
            thumb
        } = this.props;
        const { fallbackthumb } = this.state;
        let img_src = record ? record[source] : image;
        return (
            <div style={divstyle}>
                <img {...this.props} alt="" onClick={(a) => { this.setState({ showImg: true }) }} src={fallbackthumb || thumb || img_src} onError={this.onThumbError} />

                {this.state.showImg &&
                    <Modal
                        onCloseRequest={this.handleModalClose}
                    >
                        <img style={imgfullstyle} src={img_src} alt={alt} />
                    </Modal>
                }
            </div>
        );
    }
}


export default ImageField;