import MapEditor from "./map";
import DefaultDashBoard from "./dashboard";
import { MapDashboard } from "./dashboard";
import {
	User,
	Asset,
	Delivery,
	Effect,
	Exp,
	Trigger,
	ProjectExp,
	Player,
	ApplicationConfig,
	Office
} from "./models";
import indigo from "@material-ui/core/colors/indigo";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";
import { CustomExp } from "./models/exp";
import { POIS_GROUP } from "./config";
import { DELETE, DELETE_MANY } from "react-admin";
import BuildIcon from "@material-ui/icons/Build";
import GPSFixedIcon from "@material-ui/icons/GpsFixed";
import InfoIcon from "@material-ui/icons/Info";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import JwtDecode from "jwt-decode";
import effect from "./models/effect";
import { ExpField, ModuleTypes, IModuleRecord, IConfig, ResourceNames } from "./types";
import EffectCreate from "./models/effect/EffectCreate";

const debug = require("debug")("app:ariaboconfig");

export const featureAcess = (type: any, data: any) => {
	const debug = require("debug")("app:ariaboconfig:featureAcess");
	const userRole = localStorage.getItem("role");
	debug("checking", type, data);
	if (userRole === "admin") return true;
	switch (type) {
		case "PUBLISH_MANY":
		/* falls through */
		case DELETE_MANY:
			return userRole === "admin"; //could be return false but lets remain readable
		case "PUBLISH":
			if (userRole === "default") return false;
		/* falls through */
		case DELETE:
			if (!data || !data.stateInfo) return false;
			const userId: string = localStorage.getItem("userId")!;
			const createdBy: string = JwtDecode<any>(data.stateInfo)[
				"createdBy"
			];
			return createdBy.startsWith(userId);
		default:
			return true;
	}
};

export const genericOwnedAcess = (type: any, resource: string, params: any) => {
	const debug = require("debug")("app:ariaboconfig:genericOwnedAcess");
	const role = localStorage.getItem("role");
	if (role === "admin") return true;
	if (featureAcess(type, params.previousData)) return true;
	return true;
};

export const dataOpAcess = {
	pois: genericOwnedAcess,
	pous: genericOwnedAcess,
	commercialPoints: genericOwnedAcess,
	newsPoints: genericOwnedAcess
};


export const ModuleLabels: IModuleRecord<string> = {
	GeolocationModule: "Geo Location",
	TriggersModule: "Triggers",
	EffectsModule: "Effects",
	LocalizationModule: "Localization",
	DataClassificationModule: "Data Classification",
	PlayersModule: "Players",
	HierarchyModule: "Hierarchy",
	LightModule: "Light",
	ShapeModule: "Shape",
	TransformModule: "Transform",
	TransformDirectorsModule: "Transform Directors",
	EventsModule: "Events",
	StyleModule: "Style",
	SkyboxModule: "Skybox",
	GlobalStadiumEventModule: "Global Stadium Event"
};

export const ModuleSources: IModuleRecord<string> = {
	GeolocationModule: "geolocation",
	TriggersModule: "triggers",
	EffectsModule: "effects",
	LocalizationModule: "localization",
	DataClassificationModule: "classification",
	PlayersModule: "players",
	HierarchyModule: "hierarchy",
	LightModule: "lights",
	ShapeModule: "shape",
	TransformModule: "transform",
	TransformDirectorsModule: "transformDirectors",
	EventsModule: "events",
	StyleModule: "style",
	SkyboxModule: "skybox",
	GlobalStadiumEventModule: "globalStadiumEvent"
};

const defaultThemes = {
	darkTheme: {
		palette: {
			type: "dark" // Switching the dark mode on is a single property value change.
		}
	},
	lightTheme: {
		palette: {
			primary: indigo,
			secondary: pink,
			error: red,
			// Used by `getContrastText()` to maximize the contrast between the background and
			// the text.
			contrastThreshold: 3,
			// Used to shift a color's luminance by approximately
			// two indexes within its tonal palette.
			// E.g., shift from Red 500 to Red 300 or Red 700.
			tonalOffset: 0.2
		}
	}
};

const portasDoSolThemes = {
	darkTheme: defaultThemes.darkTheme,
	lightTheme: {
		palette: {
			primary: {
				light: "#ffb04c",
				main: "#f57f17",
				dark: "#bc5100",
				contrastText: "#fff"
			},
			secondary: {
				light: "#ccff75",
				main: "#97ed41",
				dark: "#62ba00",
				contrastText: "#fff"
			}
		}
	}
};

const DefaultUserPage = {
	//example on how to build page freely
	acess: User.acess,
	name: User.name,
	list: User.list,
	create: User.create,
	edit: User.edit,
	show: User.show,
	icon: User.icon,
	options: { label: "root.pages.users" }
};

const TagsPage = {
	name: Exp.name,
	options: { label: "root.resources.tags" },
	icon: Exp.icon
};
const ProjectsPage = {
	...TagsPage,
	...ProjectExp,
	options: {
		label: "root.pages.projects",
		defaultValues: {
			name: "Project Root",
			modules: { classification: { category: "MediaConsoleProject" }, hierarchy: { isPersistent: true } }
		},
		modules: [
			ModuleTypes.SkyboxModule,
			ModuleTypes.StyleModule,
			ModuleTypes.TransformModule,
			ModuleTypes.LightModule,
			ModuleTypes.ShapeModule,
			ModuleTypes.TransformDirectorsModule,
			ModuleTypes.EventsModule,
			ModuleTypes.EffectsModule,
			ModuleTypes.PlayersModule,
			ModuleTypes.DataClassificationModule
		],
		jsonViewer: true
	}
};

const MapEditorPage = {
	...MapEditor,
	options: { label: "root.pages.map" }
};

const TriggerPage = {
	options: { label: "root.pages.triggers" },
	name: Trigger.name,
	list: Trigger.list,
	icon: Trigger.icon
};

const EffectPage = {
	...Effect,
	name: Effect.name,
	list: Effect.list,
	create: Effect.create,
	icon: Effect.icon
};

const DeliveryPage = Delivery; //example inline page declaration

const DefaultDashboardPage = DefaultDashBoard;

const DeaultOfficeConfig = (permissions: any) => ({
	themes: defaultThemes,
	dashboard: DefaultDashboardPage,
	pages: [
		DefaultUserPage,
		TagsPage,
		Asset,
		TriggerPage,
		EffectPage,
		DeliveryPage
	]
});
const basePointKeys = [
	"geoHash",
	"name",
	"type",
	"img",
	"description",
	"assets",
	"expTriggers",
	"creationTime",
	"authors"
];

const defaultTagKeys: ExpField[] = [
	"img",
	"name",
	"description",
	"content",
	"creationTime",
	"authors"
];

const defaultTagKeysWithAttributes = [
	...defaultTagKeys
	// "attributes"
];

const newsPointsKeys: ExpField[] = [
	...defaultTagKeysWithAttributes,
	"isBreakingNews"
];

const GeoTags = {
	...CustomExp(
		"geolocatedTags",
		defaultTagKeys,
		[ModuleTypes.GeolocationModule],
		{ name: "New GeoLocated Tag" },
		{ label: "root.resources.geoTags", jsonViewer: true }
	)
};

const PlayableTags = {
	...CustomExp(
		"playableTags",
		defaultTagKeys,
		[ModuleTypes.PlayersModule],
		{ name: "New Play Tag" },
		{ label: "root.resources.playableTags", jsonViewer: true }
	)
};

const PointsOfInterest = {
	...CustomExp(
		"pois",
		defaultTagKeysWithAttributes,
		[ModuleTypes.GeolocationModule],
		{
			name: "New Point of Interest",
			modules: {
				classification: {
					category: 1
				}
			}
		},
		{ label: "root.pages.pois", jsonViewer: true }
	),
	icon: GPSFixedIcon
};

const PointsOfUtility = {
	...CustomExp(
		"pous",
		defaultTagKeysWithAttributes,
		[ModuleTypes.GeolocationModule],
		{
			name: "New Point of Utility",
			modules: {
				classification: {
					category: 2
				}
			}
		},
		{ label: "root.pages.pous", jsonViewer: true }
	),
	icon: BuildIcon
};

const PointsOfNews = {
	...CustomExp(
		"newsPoints",
		newsPointsKeys,
		[ModuleTypes.GeolocationModule],
		{
			name: "New News Article",
			modules: {
				classification: {
					category: 4
				}
			}
		},
		{ label: "root.pages.newsPoints", jsonViewer: true }
	),
	icon: InfoIcon
};

const CommercialPoints = {
	...CustomExp(
		"commercialPoints",
		defaultTagKeysWithAttributes,
		[ModuleTypes.GeolocationModule],
		{
			name: "New Commercial Point",
			modules: {
				classification: {
					category: 3
				}
			}
		},
		{ label: "root.pages.commercialPoints", jsonViewer: true }
	),
	icon: ShoppingCartIcon
};

const DefaultTags = {
	...CustomExp(
		"tags",
		defaultTagKeys,
		[],
		{ name: "New Tag" },
		{ label: "root.resources.tags", jsonViewer: true }
	)
};

const Images = {
	...Asset,
	name: "images"
};

const AllPoints = {
	...GeoTags,
	create: undefined,
	options: { ...GeoTags.options, label: "root.resources.all" }
}
const RATourConfig = (permissions?: any) => ({
	themes: defaultThemes,
	name: "Realidade Alges",
	MapInitial: { latitude: 38.701042, longitude: -9.230824 },
	dashboard: MapDashboard,
	accessControl: {},
	locateUser: true,
	baseTagResource: (AllPoints as any).name,
	pages: [
		AllPoints,
		PointsOfInterest,
		// PointsOfUtility,
		CommercialPoints,
		PointsOfNews,
		Asset,
		DefaultUserPage
	]
});

const DigitalSig = (permissions: any) => ({
	themes: defaultThemes,
	name: "DigitalSignage",
	MapInitial: { latitude: 38.701042, longitude: -9.230824 },
	pages: [
		ProjectsPage,
		{ name: "expGroups" }, //example to include data without showing page itself
		PointsOfInterest,
		PointsOfUtility,
		CommercialPoints,
		{ name: "tags" },
		Asset,
		Player,
		TriggerPage,
		EffectPage,
		DefaultUserPage
	]
});

const DefaultTagsBO = (permissions?: any) => ({
	// themes: defaultThemes,
	name: "Default",
	dashboard: MapDashboard,
	MapInitial: { latitude: 38.701042, longitude: -9.230824 },
	baseTagResource: (DefaultTags as any).name,
	pages: [
		ApplicationConfig,
		DefaultTags,
		GeoTags,
		PlayableTags,
		ProjectsPage,
		PointsOfInterest,
		PointsOfUtility,
		CommercialPoints,
		// Images,
		Asset,
		EffectPage,
		DefaultUserPage
	]
});

const ResourcesMap: { [key in ResourceNames]: any } = {
	ApplicationConfigs: ApplicationConfig,
	Tags: DefaultTags,
	GeolocatedTags: GeoTags,
	PlayTags: PlayableTags,
	Projects: ProjectsPage,
	Points: AllPoints,
	PointsOfInterest: PointsOfInterest,
	UtilityPoints: PointsOfUtility,
	CommercialPoints: CommercialPoints,
	NewsPoints: PointsOfNews,
	Assets: Asset,
	Effects: EffectPage,
	Users: DefaultUserPage,
	Offices: Office
}

const getCustomResourcesFromConfig = () => {
	const config: IConfig | undefined = (window as any).Config;
	if (!config || !config.resources) return []; //idkw why sometimes it comes as an empty object, need to investigate later
	const customResources = Object.keys(config.resources).filter(i => !Object.keys(ResourcesMap).includes(i));
	return customResources;
}
export const getDataRoutes = () => {
	return [
		{
			from: "pous",
			to: "tags",
			withFilter: { field: "modules.classification.category", value: 2 }
		},
		{
			from: "geolocatedTags",
			to: "tags",
			withFilter: {
				field: "modules.geolocation",
				value: { $ne: null } /*,  CHILD_PERFIL , ADULT_PERFIL*/
			}
		},
		{
			from: "playableTags",
			to: "tags",
			withFilter: {
				field: "modules.players",
				value: { $ne: null }
			}
		},
		{
			from: "acPois",
			to: "tags",
			withFilter: {
				field: "group",
				value: { $oid: POIS_GROUP } /*,  CHILD_PERFIL , ADULT_PERFIL*/
			}
			// withFilter:{field:"or", value:[{"group.expGroups_id":  ADULT_PERFIL },{"group.expGroups_id":  CHILD_PERFIL }]}
		},
		{
			from: "pois",
			to: "tags",
			withFilter: { field: "modules.classification.category", value: 1 }

			// withFilter:{field:"or", value:[{"group.expGroups_id":  ADULT_PERFIL },{"group.expGroups_id":  CHILD_PERFIL }]}
		},
		{
			from: "commercialPoints",
			to: "tags",
			withFilter: { field: "modules.classification.category", value: 3 }
		},
		{
			from: "newsPoints",
			to: "tags",
			withFilter: { field: "modules.classification.category", value: 4 }
		},
		{
			from: "projectTags",
			to: "tags",
			withFilter: { field: "modules.classification.category", value: 7 }
		},
		{
			from: "sounds",
			to: "assets",
			withFilter: { field: "assetType", value: { $in: ["audio", 4] } }
		},
		{
			from: "panoramaImages",
			to: "assets",
			withFilter: { field: "assetType", value: { $in: ["panoramaImage", 2] } } //check both enum serializations
		},
		{
			from: "videos",
			to: "assets",
			withFilter: { field: "assetType", value: { $in: ["video", 3] } }
		},
		{
			from: "models3d",
			to: "assets",
			withFilter: { field: "assetType", value: { $in: ["model3d", 5] } }
		},
		{
			from: "arocs",
			to: "assets",
			withFilter: { field: "assetType", value: { $in: ["aroc", 6] } }
		},
		{
			from: "panoramaVideos",
			to: "assets",
			withFilter: { field: "assetType", value: { $in: ["panoramaVideo", 7] } }
		},
		{
			from: "images",
			to: "assets",
			withFilter: { field: "assetType", value: { $in: ["image", 1] } }
		},
		{
			from: "texts",
			to: "assets",
			withFilter: { field: "assetType", value: { $in: ["text", 0] } }
		},
		...getCustomResourcesFromConfig().map((cr) => ({
			from: cr,
			to: "tags",
			withFilter: {
				field: `modules.${ModuleSources.DataClassificationModule}.subset`,
				value: cr
			}
		}))
	]
}

export const loadPublicConfig: (permissions?: any) => any = () => {
	const config: IConfig = (window as any).Config;
	const recognizedResources = Object.keys(config.resources).map((r: string) => ResourcesMap[r as ResourceNames]).filter((i) => !!i)
	const customResources = Object.keys(config.resources).filter(i => !Object.keys(ResourcesMap).includes(i));
	const customPages = customResources.map((cr: any) => {
		const dataform = JSON.parse(config.resources[cr]["dataform"])
		// dataRoutes.push({
		// 	from: cr,
		// 	to: "tags",
		// 	withFilter: {
		// 		field: "modules.classification.group",
		// 		value: cr
		// 	}
		// })
		const moduleSourcesKeys = Object.keys(ModuleSources);
		const modules = Object.keys(dataform["modules"]).filter((key:any)=>dataform["modules"][key]).map((m: string) => moduleSourcesKeys.find((mt: string) => ModuleSources[mt as ModuleTypes] === m));
		return CustomExp(
			cr,
			Object.keys(dataform) as ExpField[],
			modules as ModuleTypes[],
			{
				name: `New ${cr}`, modules: {
					[ModuleSources.DataClassificationModule]: {
						subset: cr
					}
				}
			},
			{ label: config.resources[cr]["title"], jsonViewer: true, icon: config.resources[cr].icon }
		)
	})
	const boConfig = {
		name: config.name,
		dashboard: config.hasDashboard ? MapDashboard : undefined,
		MapInitial: config.geolocation && { latitude: config.geolocation.latitude, longitude: config.geolocation.longitude },
		baseTagResource: config.tagContextName,
		pages: [...recognizedResources, ...customPages, !recognizedResources.some(r => r.name == "tags") && { name: "tags" }, !recognizedResources.some(r => r.name == "assets") && { name: "assets" }]
	}
	return boConfig;
}

export default DefaultTagsBO;
