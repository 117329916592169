import React, { FC, useMemo } from "react";
import {
	TextField,
	List,
	Datagrid,
	CardActions,
	CreateButton,
	EditButton,
	DeleteButton,
	FunctionField
} from "react-admin";
import { Route } from "react-router";
import { Drawer, Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import LoadOfficeButton from "./LoadOfficeButton";
import get from "lodash/get";

const OfficeList: FC = (props: any) => {
	return (
		<>
			<List title="Offices" {...props}>
				<Datagrid>
					<FunctionField
						label="Icon"
						render={(record: any) => (
							<>
								{record && record.id && (
									<Avatar
										alt={`${record["name"]}`}
										src={get(record, "icon") + ".thumb"}
									>
										<Avatar
											alt={`${record["name"]}`}
											src={get(record, "icon")}
										>
											{record["name"][0]}
										</Avatar>
									</Avatar>
								)}
							</>
						)}
						{...props}
					/>
					<TextField source="name" />
					<TextField source="title" />
					<TextField source="apiUrl" />
					<EditButton />
					<LoadOfficeButton />
					<DeleteButton />
				</Datagrid>
			</List>
		</>
	);
};

export default OfficeList;
