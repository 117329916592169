import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DateRangePicker from './DateRangePicker';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateRange from '@material-ui/icons/DateRange';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
var debug = require('debug')('app:components:daterangeinput')

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    margin: theme.spacing.unit,
  },
});

class Inputs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            selectedValue: undefined,
            datePickerValue:undefined,
            startDate:new Date(),
            endDate:new Date()
          };

    }

    handleDatePicked = (range) =>{
        if(!range)return;
        let start = range.startDate.toISOString().split('T')[0];
        let end = range.endDate.toISOString().split('T')[0];
        this.setState({
            startDate: range.startDate,
            endDate: range.endDate
        })
        if(this.props.input)this.props.input.onChange(start + "&&" + end)
        debug("range>>" , start +" " +end)
    }

    handleClickOpen = () => {
        this.setState({
          open: true,
        });
      };

    handleClose = value => {
        this.setState({ selectedValue: value, open: false });
    };

    componentDidMount()
    {
        debug("props",this.props);
        this.setState({
            startDate: this.props.startDate && !isNaN(this.props.startDate)?  this.props.startDate: new Date(),
            endDate: this.props.endDate && !isNaN(this.props.endDate)? this.props.endDate : new Date()
        });
        if(this.props.input )
        {
            let start = this.props.startDate && !isNaN(this.props.startDate)? this.props.startDate.toISOString().split('T')[0] : new Date() ;
            let end = this.props.endDate && !isNaN(this.props.endDate)?this.props.endDate.toISOString().split('T')[0] : new Date()
            this.props.input.onChange(start+ "&&" + end)
        }
    }

    render() {
        const {
            props,
        } = this;

        const { classes } = props;
        return (
          <div className={classes.container}>
				<Input
					value={this.state.startDate.toISOString().split('T')[0] + " " +this.state.endDate.toISOString().split('T')[0]}
					className={classes.input}
					inputProps={{
						'aria-label': 'Description',
					}}
					endAdornment={
					<InputAdornment position="start">
						<DateRange onClick={this.handleClickOpen}/>
					</InputAdornment>
					}
				/>
				<Dialog 
					fullWidth={true} 
					maxWidth="md" 
					fullScreen={false} 
					onClose={this.handleClose} 
					aria-labelledby="responsive-dialog-title" 
					open={this.state.open} 
                >
					<DialogTitle id="simple-dialog-title">Set range</DialogTitle>
                    <DialogContent>
					    <DateRangePicker onDatePick={this.handleDatePicked} startDate={this.state.startDate} endDate={this.state.endDate} key={this.props.key}/>
					</DialogContent>
				</Dialog>
          </div>
        );
    }
}

Inputs.propTypes = {
    classes: PropTypes.object.isRequired,
    startDate:PropTypes.object,
    endDate:PropTypes.object,
    key:PropTypes.string
};
Inputs.defaultProps ={
    startDate:new Date(),
    endDate:new Date(),
    key:'selection'
}

export default withStyles(styles)(Inputs);