import React, { FC, useMemo } from "react";
import {
	TextField,
	List,
	Datagrid,
	CardActions,
    CreateButton,
    EditButton,
    DeleteButton
} from "react-admin";
import { Route } from "react-router";
import PlayerCreate from "./PlayerCreate";
import PlayerEdit from "./PlayerEdit";
import { Drawer } from "@material-ui/core";
import { connect } from "react-redux";
import { push } from "react-router-redux";

const PlayerListActions: FC = (
	props: any //ill deal with react admin prop types later
) => (
	<CardActions>
		<CreateButton basePath={props.basePath} />
	</CardActions>
);

const PlayerList: FC = (props: any) => {
	return (
		<>
			<List title="Players" {...props} actions={<PlayerListActions />}>
				<Datagrid>
					<TextField source="name" />
					<TextField source="description" />
                    <EditButton />
                    <DeleteButton />
				</Datagrid>
			</List>
			<Route path="/players/create">
				{routeProps => (
					<Drawer
						open={!!routeProps.match} //force return bool
						anchor="right"
						onClose={() => props.push("/players")}
					>
						<PlayerCreate
							{...props}
							onCancel={() => props.push("/players")}
						/>
					</Drawer>
				)}
			</Route>
			<Route path="/players/:id">
				{routeProps => {
					const { match } = routeProps;
					const isMatch: boolean =
						match && match.params && match.params.id !== "create";
					return (
						<Drawer
							open={isMatch}
							anchor="right"
							onClose={() => props.push("/players")}
						>
							{isMatch ? (
								<PlayerEdit
									id={match!.params.id}
									onCancel={() => props.push("/players")}
									{...props}
								/>
							) : null}
						</Drawer>
					);
				}}
			</Route>
		</>
	);
};

export default connect(
	undefined,
	{ push }
)(PlayerList);
