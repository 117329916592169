import * as THREE from 'three';
import React, {Component, useState, useRef} from 'react';
import { Canvas, extend, useThree, useRender } from 'react-three-fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent } from '@material-ui/core';
const debug = require('debug')('app:components:panoramaviewer');

extend({ OrbitControls })

const Controls = ({onGetControls, ...props}) => {
    const { camera, gl } = useThree();
    const canvas = gl.domElement;
    const controls = useRef()
    debug("canvas", canvas)
    useRender(() => {
        if(controls.current){
            controls.current.update();
        }
    })
    if(onGetControls)onGetControls(controls);
    return <orbitControls ref={controls} args={[camera, canvas]} target={[0,0,0]} {...props} domElement={canvas}/> 
}

const PanoramaBoi = (props)=>{
    let uri2img = /*'https://ds.aromni.com/api/Files/assets/download/1559235672759.benfica360.3_Pavilhao.jpg'*/ props.src;
    let geometry = new THREE.SphereBufferGeometry(500, 60, 40);
    geometry.scale(-1, 1, 1);
    let texture = new THREE.TextureLoader().load(uri2img);
    var material = new THREE.MeshBasicMaterial({map:texture});
    var mesh = new THREE.Mesh(geometry, material);
    return <primitive object={mesh} />
}

class Viewer extends Component{
    render(){
        const {record, source} = this.props;

        return (
            <div style={{backgroundColor:'lightgrey', height:'100%'}}>            
                    <Canvas  key={record.id+'.'+source} style={{ height:'100%' }} camera={{near:0.1, far:1000}} >
                        <PanoramaBoi src={record[source]}/>
                        <Controls  rotateSpeed={-0.1} autoRotate={true} autoRotateSpeed={0.5} enableZoom={false} enablePan={false} screenSpacePanning={true}  />
                    </Canvas>
            </div>
        )
    }
}

const ButtonToViewer = ({classes, record, source, ...props}) =>{
    const [modal, setModal] = useState(false);
    const [thumb, setThumb] = useState(record && record[source] +".thumb");
    const onImgError = (ev)=>{
        if(!record)return;
        setThumb(record[source]);
    }
    return (
        <div className={classes.divRoot}>
            <img
                className={classes.sourceImage}
                alt="" 
                onClick={i=>{
                    i.preventDefault();
                    setModal(true)}
                }
                src={thumb}
                onError={onImgError}
            />
            {modal &&
                <Dialog 
                    classes={{paper:classes.paper}}
                    open={modal}
                    onClose={i=>setModal(false)}
                    maxWidth="lg" 
                    maxHeight='100%'
                    fullWidth={true} 
                    width='auto' 		
                    fullScreen={false} 
                    aria-labelledby="confirmation-dialog-title" >
                    <DialogContent style={{height:'100%'}}>
                        <Viewer record={record} source={source} {...props}/>
                    </DialogContent>
                </Dialog>
            }
        </div>
    )
}

const buttonStyles= theme=>({
    divRoot:{
        textAlign:'center'
    },
    sourceImage:{
        // maxWidth: '100%',
        width:'100%',
        height:'auto'
    },
    paper:{height:'75%', width:'75%'},
})
export default withStyles(buttonStyles)(ButtonToViewer);