import React from 'react';
import { Menu, Item, Separator, Submenu } from 'react-contexify';
import { Layout, Orientation, Link, TreeMenuProps } from './types';
import { Dict } from '../../types';
import { Button } from '@material-ui/core';
// import ButtonGroup from '@material-ui/core/ButtonGroup';

const onClick = (args:any) => {};

interface ItemPropSetter<T>{
    set:(value:T)=>void;
    value:T
}

const itemStyle:<T>(v:T, c:T)=>React.CSSProperties = (value, check) => {
    return {
        fontWeight:value===check? 'bold' : 'normal'
    };
}
const LayoutItem: React.FC<ItemPropSetter<Layout>> = (props) => {
    const {set,value} = props;
	return (
		<Submenu label='Layout'>
			<Item style={itemStyle(value, 'cartesian')} onClick={()=>set('cartesian')}>Cartesian</Item>
			<Item style={itemStyle(value, 'polar')} onClick={()=>set('polar')}>Polar</Item>
		</Submenu>
	);
}

const OrientationItem: React.FC<ItemPropSetter<Orientation>> = (props) => {
    const {set, value} = props;
	return (
		<Submenu label='Orientation'>
			<Item style={itemStyle(value, 'horizontal')} onClick={()=>set('horizontal')}>Horizontal</Item>
			<Item style={itemStyle(value, 'vertical')} onClick={()=>set('vertical')}>Vertical</Item>
		</Submenu>
	);
}

const LinkItem: React.FC<ItemPropSetter<Link>> = (props) => {
    const {set, value} = props;
	return (
		<Submenu label='Link'>
			<Item style={itemStyle(value, 'diagonal')} onClick={()=>set('diagonal')}>Diagonal</Item>
 			<Item style={itemStyle(value, 'step')} onClick={()=>set('step')}>Step</Item>
            <Item style={itemStyle(value, 'curve')} onClick={()=>set('curve')}>Curve</Item>
            <Item style={itemStyle(value, 'line')} onClick={()=>set('line')}>Line</Item>
            <Item style={itemStyle(value, 'elbow')} onClick={()=>set('elbow')}>Elbow</Item>
		</Submenu>
	);
}

// const StepPercentItem: React.FC<ItemPropSetter<number>> = (props)=>{
//     const {set, value} = props;
//     return(
//         <Item >
//             Step Percent
//             <span>
//                 <ButtonGroup>
//                     <Button>-</Button>
//                     <Button>{value}</Button>
//                     <Button>+</Button>
//                 </ButtonGroup>

//             </span>
//         </Item>
//     )
// }

const MenuTable:React.SFC<TreeMenuProps> = (props) =>{
    const {
        setLayout,
        setOrientation,
        setLink,
        setShowId,
        setStepPercent,
        config
    } = props;
	return (
		<Menu id='vxTree_context_menu' style={{zIndex:10}}>
            <LayoutItem set={setLayout} value={config.layout} />
            <OrientationItem set={setOrientation} value={config.orientation}/>
            <LinkItem set={setLink} value={config.link}/>
            {/* <StepPercentItem set={setStepPercent} value={config.stepPercent} /> */}
            <Item onClick={()=>setShowId(!config.showId)}>
                {config.showId?'Hide Id':'Show Id'}
            </Item>
        </Menu>
	);
    return <div/>;
}

export default MenuTable;