import React, { Fragment } from 'react'
import {
    BulkDeleteButton,
    DELETE_MANY
} from 'react-admin';
import PropTypes from 'prop-types';
import { AccessControlledFeature, BulkPublish } from "../../components";

const PostBulkActionButtons = props => (
    <>
        <AccessControlledFeature {...props} feature="PUBLISH_MANY">
            <BulkPublish label="Publish" />
        </AccessControlledFeature>
        <AccessControlledFeature {...props} feature={DELETE_MANY}>
            <BulkDeleteButton />
        </AccessControlledFeature>
    </>
);

PostBulkActionButtons.props = {
    data: PropTypes.object
}

export default PostBulkActionButtons;