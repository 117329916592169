import ExpCreate from "./ExpCreate";
import ExpEdit from "./ExpEdit";
import ExpList from "./ExpList";
import Bookmark from "@material-ui/icons/Book";

export default {
	name: "projectTags",
	list: ExpList,
	create: ExpCreate,
	edit: ExpEdit,
	icon: Bookmark
};