import React from "react";
import useAuthToken from "./useAuthToken";
import useConfig from "./useConfig";
import useFetch, { UseFetch } from "use-http";
import urljoin from "url-join";

type AuthorizedFetchHook=(path:string,deps?:Array<any>)=>UseFetch<any>
const useAuthorizedFetchHook:AuthorizedFetchHook = (path:string, deps?:Array<any>)=>{
    const authToken = useAuthToken();
    const config = useConfig();
    const fetch_response = useFetch(urljoin(config.apiUrl, path), {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `bearer ${authToken}`
        }
    }, [path, ...(deps?deps:[])])
    return fetch_response;
}


export default<AuthorizedFetchHook> useAuthorizedFetchHook;