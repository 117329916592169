import { Reducer } from "react"

export interface IResourceSchemas {
    ApplicationConfigs?:{[key:string]:any},
    Assets?:{[key:string]:any},
    Tags?:{[key:string]:any},
    Effects?:{[key:string]:any},
}
export const initialState:IResourceSchemas = {
    ApplicationConfigs:undefined,
    Assets:undefined,
    Tags:undefined,
    Effects:undefined
}
export enum EReSchemsReduxActions {
    GET_RESOURCESCHEMAS="GET_RESOURCESCHEMAS",
    SET_RESOURCESCHEMAS="SET_RESOURCESCHEMAS",
    SET_APPLICATIONCONFIGS="SET_APPLICATIONCONFIGS",
    GET_APPLICATIONCONFIGS="GET_APPLICATIONCONFIGS",
    SET_ASSETS="SET_ASSETS",
    GET_ASSETS="GET_ASSETS",
    SET_TAGS="SET_TAGS",
    GET_TAGS="GET_TAGS",
    SET_USERS="SET_USERS",
    GET_USERS="GET_USERS"
}

export interface IReSchemsReduxAction {
    type:EReSchemsReduxActions,
    data?:{[key:string]:any}|Partial<IResourceSchemas>
}

export type ResourceSchemasReducer = (state:IResourceSchemas, action:IReSchemsReduxAction)=>{[key:string]:any}|IResourceSchemas

export default (state:IResourceSchemas=initialState, action:IReSchemsReduxAction)=>{
    switch (action.type) {
        case EReSchemsReduxActions.GET_RESOURCESCHEMAS:
            return {...state};
        case EReSchemsReduxActions.SET_RESOURCESCHEMAS:
            return {...action.data}
        case EReSchemsReduxActions.GET_APPLICATIONCONFIGS:
            return {...state.ApplicationConfigs}
        case EReSchemsReduxActions.SET_APPLICATIONCONFIGS:
            return {...state, ApplicationConfigs:action.data}
        case EReSchemsReduxActions.GET_ASSETS:
            return {...state.Assets}
        case EReSchemsReduxActions.SET_ASSETS:
            return {...state, Assets:action.data}
        case EReSchemsReduxActions.GET_TAGS:
            return {...state.Tags}
        case EReSchemsReduxActions.SET_TAGS:
            return {...state, Tags:action.data}
        default:
            return state;
    }
}

