import React from "react";
import { addField } from "react-admin";
import { useForm, AnyObject } from "react-final-form";
import { get, set, remove } from "lodash";
const debug = require("debug")("app:components:resourcecollector");

export const processResources = (
    formValues: AnyObject,
    currentValue: any,
    newValue: any,
    collection: string,
    source: string,
    valueSource: string
) => {
    const formattedValue = newValue;
    const currentResources = { ...(get(formValues, source!) || get(set(formValues, source, {}), source)) }; //resources : {}

    const currentTarget: string[] =
        get(currentResources, collection) ||
        set(currentResources, collection, [])[collection]; //resources : {collection:[]}
    const mapping =
        get(currentResources, "map") || set(currentResources, "map", {})["map"]; //resources : {collection:[], map:{}}
    Object.values<string[]>(mapping)
        .filter(arr => arr.some(v => v === valueSource))
        .forEach((arr: string[]) => remove(arr, v => v === valueSource)); //i know, can simplify this, later
    if (!mapping[currentValue] || mapping[currentValue].length <= 0)
        remove(currentTarget, v => v === currentValue);
    if (formattedValue && typeof formattedValue === "string") {
        if (!currentTarget.some(id => id === formattedValue))
            currentTarget.push(formattedValue);
        const idMapping =
            get(mapping, formattedValue) ||
            set(mapping, formattedValue, [])[formattedValue];
        idMapping.push(valueSource);
    }
    return currentResources;
};

interface ResourceCollectorProps {
    children: any;
    collection: string;
    input?: any;
    meta?: any;
    formValues?: any;
    source?: string;
}
const Collector: React.FC<ResourceCollectorProps> = (
    props: ResourceCollectorProps
) => {
    const { children, source, collection, input, meta, ...restProps } = props;
    const form = useForm();
    const formValues = form.getState().values;
    const onChange = (event: any) => {
        const value = event.target.value;
        if (!source) throw "source is null, not cool";
        const childSource: string = children.props.source;
        const currentValue = get(formValues, childSource);
        const updatedResources = processResources(
            formValues,
            currentValue,
            value,
            collection,
            source,
            childSource
        );
        input.onChange(updatedResources);
        event.stopPropagation()
    };
    return (
        <>
            {React.Children.map(children, (child, index) =>
                React.cloneElement(child, {
                    ...child.props,
                    onChange,
                    onBlur: onChange,
                    onFocus: onChange
                    // parse,
                    // format
                })
            )}
        </>
    );
};

export default addField(Collector) as React.FC<ResourceCollectorProps>;
