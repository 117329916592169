import * as React from 'react';
import {FieldProps, Dict} from '../types';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import _ from 'lodash';
import { setInheritance } from "../customActions";
import {FamilyTree} from './ComponentIcons';
import { createStyles } from '@material-ui/core';
import { Record } from "ra-core";

var debug = require('debug')('app:components:inheritable')



interface InheritableInputProps extends FieldProps{
    path:string,
    parentIndex:string,
    baseRecord:Record,
    children:React.DetailedReactHTMLElement<any, HTMLElement>;
    injectProps(value:any, sourceProps:Dict):Dict;
    [key:string]:any;
}

///hopefully will change this one day to separated objects
const GetInherited = (record:Record, parentIndex:string, selfIndex:string, path:string):Dict =>{
    let value:any = _.get(record,`${selfIndex && selfIndex+'.'}${path}`);
    if(value)return {value:value, owner:selfIndex? _.get(record, selfIndex) : record}; //jic
    let searchSource:string = selfIndex;
    value = undefined;
    while (searchSource && value === undefined) {
        debug("in iter", searchSource, value)
        searchSource = searchSource.substring(0, searchSource.lastIndexOf('.children['))
        value=_.get(record,`${searchSource && searchSource+'.'}${path}`);
    }
    debug("inherited", value, 'from ', searchSource)
    return {value:value, owner:searchSource? _.get(record, searchSource) : record};
}

const styles = createStyles({ //perhaps we'll use later on
    divRoot:{
        display:"inline-flex",  
        width: "-webkit-fill-available",
        // backgroundColor: "red"

    }
})

const InheritableInput:React.FunctionComponent<InheritableInputProps>=(props:InheritableInputProps)=>{
    const {
        children,
        change,
        parentIndex,
        inheritance,
        setInheritance,
        onChange,
        path,
        injectProps,
        ...restProps
    } = props;
    const child = children;
    const [edited, setEdited] = React.useState(false);
    const [inheritedFrom, setInheritedFrom] = React.useState<Dict>({});

    const [selfSource, absoluteValueSource]:Array<string|undefined> = React.useMemo(() => {
        let sf = restProps.source && restProps.source.substring(0, restProps.source.indexOf('.'+path));
        let avs = restProps.source && restProps.source.substring(restProps.source.indexOf(path), restProps.source.length);
        debug("MY SOURCE", restProps.source);
        return [sf, avs];
    }, [restProps.source, path]);

    const initialValue:any = React.useMemo(()=>{
        return restProps.source && _.get(restProps.baseRecord,restProps.source);
    }, [restProps.source, restProps.baseRecord])

    const _onChange = React.useCallback(()=> (event:any, current:any, previous:any, path:any) =>{
        if(onChange)onChange(event, current, previous, path);
        const newinheritable = _.set(inheritance, restProps.source!, current);
        debug("will set inheritance to ",newinheritable);
        setInheritance(newinheritable)
        debug(`Edited from ${previous} to ${current} at path ${path}`)
        setEdited(true);
    }, [restProps.source])
    
    let canInherit:boolean = false;

    const inherited:Dict = React.useMemo(()=>{
        const r:Dict = GetInherited(restProps.baseRecord, parentIndex, selfSource!, absoluteValueSource!);
        setInheritedFrom(r);
        debug("set inherited", absoluteValueSource, r)
        return r;
    }, [selfSource, parentIndex, absoluteValueSource]);
    
    canInherit = !edited && initialValue===undefined && inheritedFrom && inheritedFrom.value;
    //can inherit if not edited, initial value undefined, and there's an inherited value

    const injectedProps:Dict =  React.useMemo(() => {
        const injection = canInherit? injectProps(inheritedFrom['value'],restProps) : {};
        debug("set inject props", path, injection);
        return injection;
    }, [inheritedFrom, restProps, canInherit]);

    const title=React.useMemo(()=>{
        const ownerName:string = inheritedFrom && inheritedFrom['owner'] && inheritedFrom['owner']['name'];
        debug("owner name", ownerName, inheritedFrom);
        return `Inherited from ${ownerName || '<unknown>'}`;
    }, [inheritedFrom]);

    debug("inherittable with", props, child.props)
    return child ? (
        <div style={styles.divRoot as React.CSSProperties}>
            {React.cloneElement(child,{...restProps, ...child.props, onChange:_onChange, ...(canInherit? injectedProps : {})})}
            {canInherit &&
                <Tooltip title={title}>
                    <FamilyTree style={{placeSelf:'end'}} width='24' height='24'/>
                </Tooltip>
            }
        </div>
    ):<></>;
}

InheritableInput.defaultProps={
    injectProps : (value:any, sourceProps:Dict):Dict =>{
        return {
            options:{
                ...sourceProps['options'],
                value: value, //oh react admin....
                InputProps:{
                    value: value //OH REACT ADMIN.....!
                }
            }
        }
    }
}


const mapStateToProps=(state:any)=>({
    inheritance:state.inheritance
})

const connectedInput = connect(mapStateToProps, {change, setInheritance})(InheritableInput);
export default connectedInput;