import React from "react";
import { addField, regex } from "react-admin";
import GoogleMaps from "./GoogleMaps";
import {
	Paper,
	withStyles,
	createStyles,
	Theme,
	Typography,
	IconButton
} from "@material-ui/core";
import InputBase, {
	InputBaseProps,
	InputBaseComponentProps
} from "@material-ui/core/InputBase";
import _ from "lodash";
import { Marker } from "@react-google-maps/api";
import { latLon_rgx, stringToLatLng } from "../helpers";
import DeleteIcon from '@material-ui/icons/Delete';
var debug = require("debug")("app:components:geolocationinput");

var image = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';

var markerIcon = {
	path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
	scale: 0.9,
	fillColor: "#0CB",
	fillOpacity: 1,
	strokeWeight: 1.5,
	strokeColor: "#FFF"
}

var markerIconSecondary = {
	path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
	scale: 0.9,
	fillColor: "#0CB",
	fillOpacity: 1,
	strokeWeight: 0,
	strokeColor: "#000"
}
const styles = createStyles((theme: Theme) => ({
	root: {
		position: "relative",
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		left: "50%",
		marginLeft: "-120px",
		margin: "10px",
		width: "280px"
	},
	typography: {
		width: "fit-content",
		position: "relative",
		left: "50%",
		marginLeft: "-120px",
		marginTop: "-10px"
	},
	input: {
		marginLeft: theme.spacing(),
		flex: 1
	},
	error: {
		textDecoration: "underline",
		textDecorationColor: "red",
		textDecorationStyle: "dashed"
	}
}));

interface MUITextInputProps extends InputBaseProps {
	[key: string]: any;
	onRemove: () => void;
	classes?: any;
}

const MUITextInput: React.FC<MUITextInputProps> = (
	props: MUITextInputProps
) => {
	const { classes, submit, errorMessage, onRemove, ...restProps } = props;
	const inputClasses = _.pick(classes, "error");
	return (
		<>
			<Paper className={classes && classes.root}>
				<InputBase
					classes={inputClasses}
					id="latLon_input"
					className={classes && classes.input}
					placeholder="Latitude, Longitude"
					inputProps={{ "aria-label": "Latitude, Longitude" }}
					{...restProps}
				/>
				<IconButton onClick={onRemove} aria-label="delete" className={classes.margin}>
					<DeleteIcon fontSize="small" />
				</IconButton>
			</Paper>
			{props.error && (
				<Typography
					className={classes && classes.typography}
					variant="caption"
					color="error"
				>
					{errorMessage}
				</Typography>
			)}
		</>
	);
};

const LatLongTextInput = withStyles(styles)(MUITextInput);

const GeoLocationInput: React.FC<any> = (props: any) => {
	const { input, meta, style, children } = props;
	const [selectedIndex, selectIndex] = React.useState<number>(0);
	const latLngs = input.value && input.value.map((v: string) => stringToLatLng(v))
	const MAP = React.useRef<any>(undefined);
	const onChange = (ev: any) => {
		var currentValue = [...input.value];
		const ll = stringToLatLng(ev.target.value);
		currentValue[selectedIndex] = ev.target.value;
		input.onChange(currentValue);
		ll && MAP.current && MAP.current.panTo(ll);
	};
	const onRemove = () => {
		var currentValue = [...input.value];
		if (currentValue.length < 1) return;
		currentValue.splice(selectedIndex, 1);
		const ll = stringToLatLng(currentValue[currentValue.length - 1]);
		input.onChange(currentValue);
		selectIndex(currentValue.length - 1)
		ll && MAP.current && MAP.current.panTo(ll);
	}
	const regexError = false;
	// const regexError = !latLon_rgx.test(input.value);
	// console.log(regexError(input.value))
	return (
		<div style={style || { width: "100%", height: "50vmin" }}>
			<GoogleMaps
				onClick={e => {
					debug("???????", e);
					const newIndex = input.value.length;
					input.onChange([...input.value, `${e.latLng.lat()}, ${e.latLng.lng()}`]);
					selectIndex(newIndex)
				}}
				onLoad={map => {
					MAP.current = map;
					const initValue = stringToLatLng(input.value[0]);
					initValue && map.setCenter(initValue);
				}}
				mapProps={{
					mapContainerStyle: {
						width: "100%",
						height: "100%",
						display: "flex",
						flexDirection: "column-reverse"
					},
					mapTypeId: "hybrid"
				}}
				ignoreUserLocation={!!input.value && !!input.value[0]}
			>
				<LatLongTextInput
					error={meta.touched && (regexError || !!meta.error)}
					errorMessage={
						(regexError &&
							"Invalid Format, Expected: number, number") ||
						meta.error
					}
					inputProps={{
						...(input as InputBaseComponentProps),
						value: (input.value && input.value[selectedIndex]) || "",
						onChange, // ;),
						autoComplete: "off"
					}}
					onRemove={onRemove}
				/>
				{children && children}
				{latLngs && latLngs.map((latLng: string, index: number) => <Marker icon={selectedIndex === index ? markerIcon : markerIconSecondary} position={latLng} onClick={() => selectIndex(index)} />)}
			</GoogleMaps>
		</div>
	);
};

const FieldedInput = addField(GeoLocationInput);

const FinalInput: React.FC<any> = (props: any) => {
	return (
		<FieldedInput
			{...props}
			validate={[
				...(props.validate || [])
				// regex(latLon_rgx, "Invalid Format, Expected: number, number")
			]}
		/>
	);
};

export default FieldedInput;
