import { GetConfig } from "../config";
import dataProvider from "./index";
import { fetchUtils, GET_LIST, GET_MANY } from "react-admin";
import { publishChildrenWithParent, ExpAriaServiceName } from "../config";
import _ from "lodash";
import { featureAcess } from "../ariabo.config";
import urljoin from "url-join";
var debug = require("debug")("app:dataprovider:rest");

const httpClient = (url, options = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: "application/json" });
	}
	const token = localStorage.getItem("token");
	options.headers.set("Authorization", `Bearer ${token}`);
	return fetchUtils.fetchJson(url, options);
};
const apiUrlKey = "apiUrl"
//methods on index.js should be prefered over these for centralization
export default class rest {
    static REST_API_URL = GetConfig()["apiUrl"]; //doesnt work..., race conditions etc..
	static post(resource, data) {
		let body = JSON.stringify(data);
		debug("DBG POST RESOURCE " + resource + " " + body);

		return fetch(urljoin(GetConfig()[apiUrlKey], resource), {
			method: "POST",
			body: body,
			mode: "cors",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				Authorization: "Bearer " + localStorage.getItem("token")
			}
		});
	}

	static get(resource, resourceId) {
		resourceId = resourceId === undefined ? "" : resourceId;
		debug("USER", localStorage.getItem("token"));
		return fetch(urljoin(GetConfig()[apiUrlKey], resource, resourceId), {
			method: "GET",
			mode: "cors",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				Authorization: "Bearer " + localStorage.getItem("token")
			}
		});
	}

	static countGroupTags(group) {
		let body = JSON.stringify({ groupId: group });
		return fetch(urljoin(GetConfig()[apiUrlKey], "exps/countGroupTags"), {
			method: "POST",
			body: body,
			mode: "cors",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				Authorization: "Bearer " + localStorage.getItem("token")
			}
		});
	}

	static delete(resource, resourceId) {
		// let body=JSON.stringify({id:resourceId});
		debug("DBG DELETE RESOURCE " + resourceId + " FROM " + resource);
		return fetch(urljoin(GetConfig()[apiUrlKey], resource, resourceId), {
			method: "DELETE",
			mode: "cors",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				Authorization: "Bearer " + localStorage.getItem("token")
			}
		});
	}

	static put(resource, data) {
		let body = JSON.stringify(data);
		debug(`DBG PUT RESOURCE ${resource} ${data.id}`);
		return fetch(urljoin(GetConfig()[apiUrlKey], resource), {
			method: "PUT",
			body: body,
			mode: "cors",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				Authorization: "Bearer " + localStorage.getItem("token")
			}
		});
	}

	static ObjIsArray(obj) {
		if (typeof Array.isArray === "undefined") {
			Array.isArray = function(v) {
				return Object.prototype.toString.call(v) === "[object Array]";
			};
		}
		return Array.isArray(obj);
	}

	static async resolveRefs(baseObject, obj, objSource, cb) {
		let _debug = require("debug")("app:dataprovider:rest:resolverefs");
		let objectKeys = Object.keys(obj).filter(
			e => obj[e] && typeof obj[e] !== "string"
		); //filter so we dont waste time with literal string values
		_debug("received object", baseObject);
		for (let e of objectKeys) {
			let fieldName = e;
			let src = objSource ? `${objSource}.${fieldName}` : `${fieldName}`;

			if (this.ObjIsArray(obj[fieldName])) {
				let arr = obj[fieldName];
				await this.resolveRefs(baseObject, arr, src);
			} else if (
				Object.keys(obj[fieldName]).every(
					k => k.endsWith("_id") === false
				)
			) {
				if (
					Object.values(obj[fieldName]).some(
						v => typeof v === "object"
					)
				) {
					// let src = objSource? `${objSource}.${fieldName}` : `${fieldName}`
					await this.resolveRefs(baseObject, obj[fieldName], src);
				}
			} else {
				let validKey = Object.keys(obj[fieldName]).find(k =>
					k.endsWith("_id")
				);
				if (validKey === undefined) return;
				let resource = validKey.replace("_id", "");
				let resourceId = obj[fieldName][validKey];
				let r = await this.get(resource, resourceId);
				if (!r.ok) {
					_debug(
						"FAILURE AT FETCHING RESOURCE",
						resource,
						resourceId
					);
				}
				let j = await r.json();
				await this.resolveRefs(baseObject, j);
				let tableName = validKey.split("_")[0];

				switch (tableName) {
					case "deliveries":
					case "triggers":
						obj[fieldName] = j.key;
						break;
					case "effects":
						obj[fieldName] = j.link || j.key;
						break;
					case "assets":
						_debug("trying to resolve", src);
						_debug("resolve", obj, fieldName, baseObject);
						let assetSpaceSrc = src.substring(
							0,
							src.lastIndexOf(".")
						);
						if (!r.ok) {
							const assetSpaceOrigin = assetSpaceSrc.substring(
								0,
								assetSpaceSrc.lastIndexOf(".")
							);
							const array = _.get(baseObject, assetSpaceOrigin);
							if (array && Array.isArray(array)) {
								_.remove(
									array,
									s =>
										s.asset &&
										s.asset.assets_id === resourceId
								);
							}
							break;
						}
						_debug("assetSpaceSrc", assetSpaceSrc);
						let assetSpace = _.get(baseObject, assetSpaceSrc);
						let targetObject = _.get(baseObject, src);
						_debug("target obj", targetObject);
						_debug("assetSpace", assetSpace);
						_.set(
							baseObject,
							src.substring(0, src.lastIndexOf(".")),
							{ ..._.omit(assetSpace, fieldName), ...j }
						);
						// let i = baseObject.assets.findIndex(ind=>{
						//     // _debug("comparing", ind.asset.assets_id, j.id, ind.asset.assets_id===j.id)
						//     return ind.asset && ind.asset.assets_id===j.id
						// });
						// baseObject.assets[i] = j;
						// obj[fieldName] =j;
						break;
					case "markers":
						let i_marker = baseObject.markers.findIndex(ind => {
							_debug(
								"comparing",
								ind.asset.assets_id,
								j.id,
								ind.asset.assets_id === j.id
							);
							return ind.marker && ind.marker.markers_id === j.id;
						});
						baseObject.markers[i_marker] = j;
						break;
					default:
						obj[fieldName] = j;
						break;
				}
			}
		}
		return baseObject;
	}

	static async unpublishTags(items, cb) {
		let debug = require("debug")("app:dataprovider:rest:umpublishtags");

		let records = {};
		if (items.every(x => typeof x === "string")) {
			debug(
				"unpublishTags DBG detected array of ids instead of records, will fetch first"
			);
			let response = await dataProvider(GetConfig()[apiUrlKey], httpClient)(
				GET_MANY,
				"exps",
				{ ids: items }
			);
			records = response.data;
		} else records = items;
		if (records.some(rec => !featureAcess("PUBLISH", rec))) {
			return Promise.reject(
				"Unauthorized",
				"you lack permissions to publish one or more of the selected tags"
			);
		}
		if (publishChildrenWithParent) {
			let allChildren = [];
			for (let r of records) {
				let childrenData = await this.getTagChildren(r["id"]);
				if (childrenData.data && childrenData.data.length > 0) {
					allChildren.push(...childrenData.data);
				}
				debug(`Got children of ${r.name}`, childrenData);
			}
			records.push(...allChildren);
		}
		debug("Will umpublish these", records);
		for (let i = 0; i < records.length; i++) {
			const rec = records[i];
			debug("Will remove", rec.name);
			await this.unpublishTag(rec, null, false);
			debug("Removed", rec.name);
		}
		if (cb) cb();
		return Promise.resolve();
	}

	static async pushGroup(groupdId, cb) {
		//debug("push ", groupdId)
		let req = await dataProvider()(GET_LIST, "exps", {
			filter: { "group.expGroups_id": { like: groupdId } },
			pagination: { page: 0, perPage: 0 },
			sort: { field: "id", order: "DESC" }
		});
		let recs = req.data;
		//debug("push returned", recs)
		await this.pushTags(recs);
		return Promise.resolve();
	}

	static async pushTags(items, cb) {
		let debug = require("debug")("app:dataprovider:rest:pushtags");
		let records = [];
		debug(items);
		if (items.every(x => typeof x === "string")) {
			debug("detected array of ids instead of records, will fetch first");
			let response = await dataProvider(GetConfig()[apiUrlKey], httpClient)(
				GET_MANY,
				"exps",
				{ ids: items }
			);
			records = response.data;
		} else records = items;
		if (records.some(rec => !featureAcess("PUBLISH", rec))) {
			return Promise.reject(
				"Unauthorized",
				"you lack permissions to publish one or more of the selected tags"
			);
		}
		_.remove(
			records,
			rec =>
				rec.publishedId &&
				rec.stateInfo.selfVersion - 1 ===
					rec.state.stateInfo.publishedVersion
		); //remove published ones
		if (publishChildrenWithParent) {
			let allChildren = [];
			for (let r of records) {
				debug(records);
				let childrenData = await this.getTagChildren(r["id"]);
				if (childrenData.data && childrenData.data.length > 0) {
					allChildren.push(...childrenData.data);
				}
				debug(`Got children of ${r.name}`, childrenData);
			}
			records.push(...allChildren);
		}
		for (let i = 0; i < records.length; i++) {
			let record = records[i];
			if (
				record.stateInfo &&
				record.stateInfo.publishedId &&
				record.stateInfo.publishedVersion ===
					record.stateInfo.selfVersion - 1
			) {
				continue;
			}
			await this.pushTag(record, undefined, false);
		}
		if (cb) cb();
		return Promise.resolve();
	}

	static async isPublished(record_id) {
		return fetch(
			urljoin(GetConfig()[apiUrlKey], ExpAriaServiceName, "isPublished", record_id),
			{
				method: "GET",
				mode: "cors",
				headers: {
					"Content-Type": "application/json; charset=utf-8",
					Authorization: "Bearer " + localStorage.getItem("token")
				}
			}
		);
	}

	static async unpublishExp(expId) {
		//api/v1/ExpAriaService/unpublish/{expId}
		return fetch(
			urljoin(GetConfig()[apiUrlKey], ExpAriaServiceName, "unpublish", expId),
			{
				method: "DELETE",
				mode: "cors",
				headers: {
					"Content-Type": "application/json; charset=utf-8",
					Authorization: "Bearer " + localStorage.getItem("token")
				}
			}
		);
	}
	static async publishExp(expId) {
		let url = urljoin(GetConfig()[apiUrlKey], ExpAriaServiceName, "publish", expId);
		let post = await fetch(url, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				Authorization: "Bearer " + localStorage.getItem("token")
			}
		});
		return Promise.resolve(post);
	}

	static async updatePublishExp(expId) {
		let url = urljoin(
			GetConfig()[apiUrlKey],
			ExpAriaServiceName,
			"updatePublish",
			expId
		);
		let post = await fetch(url, {
			method: "PUT",
			mode: "cors",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				Authorization: "Bearer " + localStorage.getItem("token")
			}
		});
		return Promise.resolve(post);
	}

	static async upsertPublishExp(expId) {
		let url = urljoin(
			GetConfig()[apiUrlKey],
			ExpAriaServiceName,
			"upsertPublish",
			expId
		);
		let post = await fetch(url, {
			method: "PUT",
			mode: "cors",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				Authorization: "Bearer " + localStorage.getItem("token")
			}
		});
		return Promise.resolve(post);
	}

    static async loadOfficeConfig(office_id){
        const url = urljoin(
			GetConfig()[apiUrlKey],
			"offices",
			"load",
			office_id
        );
        const post = await fetch(url, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				Authorization: "Bearer " + localStorage.getItem("token")
			}
		});
		return Promise.resolve(post);
    }

	static async getTagChildren(parent_id) {
		let debug = require("debug")("app:dataprovider:rest:gettagchildren");
		let id = parent_id;
		debug("will get children of ", parent_id);
		return dataProvider()(GET_LIST, "exps", {
			filter: {
				where: { parent: id }
			},
			pagination: {
				page: 1,
				perPage: 0
			},
			sort: {
				field: "id",
				order: "DESC"
			}
		});
	}

	static async pushTag(record, cb, checkPushChildrenConfig = true) {
		let debug = require("debug")("app:dataprovider:rest:pushtag");
		if (!featureAcess("PUBLISH", record)) {
			return Promise.reject(
				"Unauthorized",
				"you lack permissions to publish one or more of the selected tags"
			);
		}
		debug("will publish", record);
		let url = urljoin(GetConfig()[apiUrlKey], "ExpAriaServices");
		const preFetch = await this.get("exps", record.id);
		if (preFetch.ok) {
			const json = await preFetch.json();
			if (
				json.stateInfo &&
				json.stateInfo.selfVersion !== record.stateInfo.selfVersion
			) {
				debug("outdated resource {updated, outdated}", json, record);
				return Promise.reject("Outdated resource(s)", "OUTDATED");
			} else debug("passed legacy check {source, current}", json, record);
		} else debug("failed legacy check request", preFetch);
		if (record.stateInfo === undefined) {
			record.stateInfo = {
				appVersion: "0.0",
				selfVersion: 0,
				publishedVersion: 0,
				publishedId: ""
			};
		}
		let origin = JSON.stringify(record);
		let originTag = JSON.parse(origin);
		debug("ready to resolve refs", record);
		let resolved = await this.resolveRefs(record, record);
		url = record.stateInfo.publishedId
			? url + "/" + record.stateInfo.publishedId
			: url;
		debug("aria", resolved);
		let body = { ariatag: resolved };
		body = JSON.stringify(body);
		debug("DBG POST ARIA TAG " + resolved.id);
		let fr = await fetch(url, {
			method: record.stateInfo.publishedId ? "PUT" : "POST",
			body: body,
			mode: "cors",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				Authorization: "Bearer " + localStorage.getItem("token")
			}
		});
		let j = await fr.json();
		originTag.stateInfo.publishedVersion = record.stateInfo.selfVersion;
		originTag.stateInfo.publishedId = j.id;
		let response = await this.put("exps", originTag);
		let responseData = await response.json();

		if (checkPushChildrenConfig && publishChildrenWithParent) {
			let childrenData = await this.getTagChildren(record.id);
			if (childrenData.data && childrenData.data.length > 0) {
				await this.pushTags(childrenData.data);
			}
		}
		if (cb) {
			cb(responseData);
		}
		return Promise.resolve(responseData);
	}

	static async unpublishTag(record, cb, checkPushChildrenConfig = true) {
		if (!featureAcess("PUBLISH", record)) {
			return Promise.reject(
				"Unauthorized",
				"you lack permissions to publish one or more of the selected tags"
			);
		}
		await this.delete("ExpAriaServices", record.stateInfo.publishedId);
		record.stateInfo.publishedId = "";
		record.stateInfo.publishedVersion = 0;
		await this.put("exps", record);
		if (checkPushChildrenConfig && publishChildrenWithParent) {
			//debug("here will get that child i guess")
			let childrenData = await this.getTagChildren(record.id);
			if (childrenData.data && childrenData.data.length > 0) {
				await this.unpublishTags(childrenData.data);
			}
		}
		if (cb) {
			cb();
		}
		return Promise.resolve();
	}
}
