import React from "react";
import { Button } from "react-admin";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { makeStyles } from "@material-ui/styles";
import { Dialog } from "@material-ui/core";
import {ResourceCalendar, ResourceSchedule} from "./";

const ButtonToResourceCalendar: React.FC<any> = (props: any) => {
	const [calendarOpen, setCalendarOpen] = React.useState(false);
	return (
		<>
			<Button
				label="Calendar"
				onClick={() => {
					setCalendarOpen((current) => !current);
				}}
			>
				<CalendarTodayIcon />
			</Button>
			<Dialog
				maxWidth="xl"
				open={calendarOpen}
				onClose={() => setCalendarOpen(false)}
			>
				<ResourceSchedule {...props}/>
			</Dialog>
		</>
	);
};

export default ButtonToResourceCalendar;
