import React from 'react';
import { TextField, List, Datagrid } from 'react-admin';
import Avatar from '../../components/Avatar';

export default (props) => (
    <List title={"Effects"} {...props}>
        <Datagrid>
            <TextField source='name' />
            <TextField source='key' />
            <Avatar size='30%' source='icon' />
        </Datagrid>
    </List>
);