import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { twitterTabsStylesHook } from "@mui-treasury/styles/tabs";

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
	}
});

export default (props: any) => {
	const { children, onTabChange } = props;
	const classes = useStyles();
	const [value, setValue] = React.useState<number>(0);

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        alert("new "+ newValue);
        onTabChange && onTabChange(newValue);
	};
	const tabsStyles = twitterTabsStylesHook.useTabs();
    const tabItemStyles = twitterTabsStylesHook.useTabItem();
    let children_props = children;
    if (typeof(children_props) === "function") children_props=children(props);
	return (
		<Paper className={classes.root}>
			<Tabs
				value={value}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				classes={tabsStyles}
				variant={"fullWidth"}
				centered
			>
				{children_props}
			</Tabs>
		</Paper>
	);
};
