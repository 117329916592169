export default {
	"ui:options": {
		addable: false,
		expandable: true
	},
	palette: {
        primary:{
            light:{
                "ui:widget": "color"
            },
            main:{
                "ui:widget": "color"
            },
            dark:{
                "ui:widget": "color"
            },
            contrastText:{
                "ui:widget": "color"
            }
        },
        secondary:{
            light:{
                "ui:widget": "color"
            },
            main:{
                "ui:widget": "color"
            },
            dark:{
                "ui:widget": "color"
            },
            contrastText:{
                "ui:widget": "color"
            }
        },
        error:{
            light:{
                "ui:widget": "color"
            },
            main:{
                "ui:widget": "color"
            },
            dark:{
                "ui:widget": "color"
            },
            contrastText:{
                "ui:widget": "color"
            }
        }
	},
	label: {
		mainColor: {
			"ui:widget": "color"
		},
		sideColor: {
			"ui:widget": "color"
		},
		borderColor: {
			"ui:widget": "color"
		}
	},
	effects: {
		items: {
			soundEffect: {
				"ui:widget": "audioAssetRef"
            },
            visualEffectKey: {
                "ui:widget": "effectRef"
            }
		}
	}
};
