import React from "react";
import { Create } from "react-admin";
import { ReactAdminCreateProps } from "../../types";
import AssetForm from "./AssetForm";

const AssetCreate: React.FC<ReactAdminCreateProps> = (
	props: ReactAdminCreateProps
) => {
	return (
		<Create {...props}>
			<AssetForm />
		</Create>
	);
};

export default AssetCreate;
