import React from "react";
import {
	SelectInput,
	Loading,
	TextInput,
	AutocompleteInput
} from "react-admin";
import {
	Button,
	Dialog,
	DialogContent,
	Card,
	IconButton,
	CardContent,
	CardHeader,
	CardActions,
	Avatar,
	Theme,
	Icon
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import StorageIcon from "@material-ui/icons/Storage";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import CloseIcon from "@material-ui/icons/Close";
import { useAuthorizedDataFetch } from "../../hooks";
import get from "lodash/get";
import omit from "lodash/omit";
import { InputSchemaForm, MaterialUIIconInput } from "../../components";
import { useForm } from "react-final-form";
import * as mui from "@material-ui/icons";

const allIconsChoices = Object.keys(mui).map(k => ({ id: k, name: k }));

const useDialogStyles = makeStyles({
	container: {
		padding: 0
	},
	button: {
		width: "100%"
	}
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			minWidth: 275
		},
		cardGridContent: {
			display: "grid"
		},
		divider: {
			marginTop: "0.1rem",
			marginBottom: "0.1rem"
		},
		clearButton: {
			color: theme.palette.error.main
		}
	})
);

const sanitizeJsonSchema = (schema: any) => {
	const technicalFields = [
		"properties.stateInfo",
		"properties.publicState",
		"properties.id"
	];
	const ommitTechnical: any = omit(schema, technicalFields);

	const boolifyProps = (sch: any) => {
		const property_keys = Object.keys(sch.properties);
		property_keys
			.filter(i => i != "modules")
			.forEach(p => {
				//DANGEROUS
				sch.properties[p] = {
					...sch.properties[p],
					type: "boolean"
				};
				delete sch.properties[p].$ref;
			});
	};
	boolifyProps(ommitTechnical);
	const modulesRef = ommitTechnical.properties["modules"]["$ref"];
	const regex = /^\#\//;
	const cleanModulesRef = modulesRef.replace(regex, "").replace("/", ".");
	const moduleCollectionDefinition = get(ommitTechnical, cleanModulesRef);
	boolifyProps(moduleCollectionDefinition);
	return ommitTechnical;
};

const VariantCard: React.FC<any> = (props: any) => {
	const { source, index, id, resource, close } = props;
	const classes = useStyles();
	const { loading, error, data } = useAuthorizedDataFetch(
		"offices/getTagSchema"
	);
	const form = useForm();
	if (!data) return <Loading />;
	const tagSchema = sanitizeJsonSchema(data);
	const clear = () => {
		form.change(source, {});
	};
	return (
		<Card className={classes.root}>
			<CardHeader
				title={"Resource Editor"}
				// subheader={index !== undefined ? idToReadableRoute(source) : "Root"}
				action={
					<>
						{close && (
							<IconButton color="primary" onClick={() => close()}>
								<CloseIcon />
							</IconButton>
						)}
					</>
				}
				avatar={
					<Avatar>
						<StorageIcon />
					</Avatar>
				}
			/>
			<CardContent className={classes.cardGridContent}>
				<MaterialUIIconInput source={`${source}.icon`} />

				<SelectInput
					label="Base resource"
					source={`${source}.base`}
					choices={[
						{
							id: "appConfigs",
							name: "Application Config",
							disabled: true
						},
						{ id: "tags", name: "Tags" },
						{ id: "assets", name: "Assets", disabled: true },
						{ id: "users", name: "Users", disabled: true }
					]}
				/>
				<TextInput label="Title" source={`${source}.title`} />
				{/* <AutocompleteInput source={`${source}.icon`} choices={allIconsChoices} shouldRenderSuggestions={(val:string) => { return val.trim().length > 5 }}/> */}
				<InputSchemaForm
					label="Data Form"
					schema={tagSchema}
					source={`${source}.dataform`}
					parse={(jObject: any) => JSON.stringify(jObject)}
					format={(jString: string) => jString && JSON.parse(jString)}
				/>
			</CardContent>
			<CardActions>
				<Button color="primary" onClick={() => close()}>
					Ok
				</Button>
				<Button
					startIcon={<DeleteSweepIcon />}
					classes={{ root: classes.clearButton }}
					onClick={() => {
						clear();
					}}
				>
					Clear
				</Button>
			</CardActions>
		</Card>
	);
};

const VariantDialog: React.FC<any> = (props: any) => {
	const { buttonLabel, ...restProps } = props;
	const [open, setOpen] = React.useState(false);
	const handleClose = () => {
		setOpen(false);
	};
	const classes = useDialogStyles();

	return (
		<>
			<Button
				className={classes.button}
				variant="outlined"
				color="primary"
				onClick={() => setOpen(true)}
			>
				{buttonLabel || "Tag variant"}
			</Button>
			{/* <AutocompleteInput source={`${props.source}.icon`} choices={allIconsChoices} shouldRenderSuggestions={(val:string) => { return val.trim().length > 5 }}/> */}

			<Dialog fullWidth={true} open={open} onClose={handleClose}>
				<DialogContent classes={{ root: classes.container }}>
					<VariantCard {...restProps} close={handleClose} />
				</DialogContent>
			</Dialog>
		</>
	);
};

export default VariantDialog;
