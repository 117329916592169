import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import DirectionsIcon from '@material-ui/icons/Directions'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import DynamicIcon from "./icons";
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import List from '@material-ui/core/List/List';
import Collapse from '@material-ui/core/Collapse/Collapse';
import { List,Datagrid, TextField,ShowButton } from "react-admin";
import InputBase from '@material-ui/core/InputBase/InputBase';

const styles = theme=>({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
	width: 400,
	zIndex:"2",
	position:"relative"
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  },
  list:
  {
	marginLeft:"25px",
	marginRight:"25px",
	zIndex:"1",
	position: "relative"
  },
  primary: {},
  icon: {},
})


class CustomizedInputBase extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null,
			selectedKey: Object.keys(props.menuItems)[0],
			documents: [],
			value:undefined,
			nameFilter:{}
		};
		this.value = undefined;
	}

	static filterValue = "";

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = (...args) => {
		this.setState({ anchorEl: null });
	};

	handleMenuItemClick = (event, i) => {
		this.setState({ selectedKey: i, anchorEl: null });
	};

	onInputChange = (event) =>{
		this.setState({
			documents:[...this.state.documents, event.target.value],
			value:event.target.value,
			nameFilter:{
				name:event.currentTarget.value
			}
		})
		this.value = event.target.value;
		this.filterValue = event.target.value;
	}
	render() {
		const {
			props,
		} = this;

		const { anchorEl } = this.state;
		const { classes, menuItems } = props

		let fakeProps = {
			basePath: `/${this.state.selectedKey}`,
			hasCreate: false,
			hasEdit: false,
			hasList: true,
			hasShow: false,
			history: {},
			location: { pathname: "/", search: "", hash: "", state: undefined },
			match: { path: "/", url: "/", isExact: true, params: {} },
			options: {},
			permissions: null,
			toolbar:null,
			actions:null,
			resource: this.state.selectedKey,
			sortable:false,
			perPage:0,
			rowsPerPageOptions:[]
		}

		return (
			<>
			<Paper className={classes.root} >
				<IconButton 
					aria-owns={anchorEl ? 'simple-menu' : undefined}
					aria-haspopup="true"
					onClick={this.handleClick}
					className={classes.iconButton} 
					aria-label='Menu'>
					<DynamicIcon k={this.state.selectedKey}/>
				</IconButton>
				<Menu
					id='simple-menu'
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={this.handleClose}>
					{Object.keys(menuItems).map(i=>(
						<MenuItem onClick={event=>this.handleMenuItemClick(event, i)}>
							<ListItemIcon className={classes.icon}>
								<DynamicIcon k={i}/>
							</ListItemIcon>
							{menuItems[i]}
						</MenuItem>
					))
					}
				</Menu>
				<InputBase onChange={this.onInputChange} className={classes.input} placeholder={`Search ${menuItems[this.state.selectedKey]}`} />
				<IconButton className={classes.iconButton} aria-label='Search'>
					<SearchIcon />
				</IconButton>
				<Divider className={classes.divider} />
				<IconButton color='primary' className={classes.iconButton} aria-label='Directions'>
					<DirectionsIcon />
				</IconButton>
			</Paper>
				<Paper square={true} className={classes.list}>
					<Collapse in={this.state.documents.length>0} >
						{/* <List dense={false}>
							{this.state.documents.map(d=>(
								<ListItem>
									<ListItemText primary={d} />
								</ListItem>
							))}
						</List> */}
						<List {...fakeProps} key={this.filterValue} filter={this.state.nameFilter}>
							<Datagrid>
								<TextField source="name" />
								<ShowButton label="" />
							</Datagrid>
						</List>
					</Collapse>
				</Paper>

			</>
		)
	}
}

CustomizedInputBase.propTypes = {
	classes: PropTypes.object.isRequired,
	onSumbited: PropTypes.func,
	menuItems: PropTypes.object
}
CustomizedInputBase.defaultProps={
	menuItems:{
		"expGroups":"Brands",
		"exps":"root.resources.tags",
		"potato":"Potato!",
		"assets":"root.resources.assets",
		"markers":"Markers"
	}
}

export default withStyles(styles)(CustomizedInputBase)
