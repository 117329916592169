import React from "react";
import {
	Dialog,
	DialogContent,
	Button,
	Icon,
	Container,
	Typography,
	IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SearchIcon from "./SearchIcon";
import { useInput, required } from "react-admin";
import { toSnakeCase } from "../utils/caseConvert";

const useDialogStyles = makeStyles({
	container: {
		padding: 5
	},
	button: {
		width: "100%"
	},
	iconContainer:{
		textAlign:"center"
	}
});



const DialogWrappers: React.FC<any> = (props: any) => {
	const { buttonLabel, ...restProps } = props;
	const [open, setOpen] = React.useState(false);
	const handleClose = () => {
		setOpen(false);
	};
	const {
		input: { name, onChange, value },
		meta: { touched, error },
		isRequired
	} = useInput(restProps);
	const classes = useDialogStyles();
	return (
		<>
			<Container className={classes.iconContainer}>
				<Typography variant="subtitle2" align="center">
					{buttonLabel || "Icon"}
				</Typography>
				{value ? (
					<IconButton size="medium" onClick={() => setOpen(true)}>
						<Icon>
							{toSnakeCase(value)}
						</Icon>
					</IconButton>
				) : (
					<Button
						className={classes.button}
						variant="outlined"
						onClick={() => setOpen(true)}
						startIcon={value && <Icon>{toSnakeCase(value)}</Icon>}
					>
						Select
					</Button>
				)}
			</Container>

			<Dialog fullWidth={true} open={open} onClose={handleClose}>
				<DialogContent classes={{ root: classes.container }}>
					<SearchIcon
						finish={handleClose}
						onSelect={(icon: any) => onChange(icon)}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default DialogWrappers;
