import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	SelectInput,
	ArrayInput,
	SimpleFormIterator,
	TextInput,
	FormDataConsumer,
	Labeled,
	NumberInput,
	addField,
	useInput,
	ReferenceArrayInput,
	SelectArrayInput,
} from "react-admin";
import {
	Card,
	Dialog,
	DialogTitle,
	Button,
	CardContent,
	Typography,
	DialogContent,
	Divider,
	Container,
	Box,
	createStyles,
	Theme,
	Fade,
	CardHeader,
	Avatar,
	IconButton,
	CardActions,
	fade,
} from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import { useForm } from "react-final-form";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import CloseIcon from "@material-ui/icons/Close";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import { useConfig } from "../hooks";
import { ModuleSources } from "../ariabo.config";
import {InputConfigLoader} from "."
import get from "lodash/get";
var debug = require("debug")("app:components:hierarchymoduleinput");

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			minWidth: 275,
		},
		cardGridContent: {
			display: "grid",
		},
		divider: {
			marginTop: "0.1rem",
			marginBottom: "0.1rem",
		},
		clearButton: {
			color: theme.palette.error.main,
		},
	})
);

const useDialogStyles = makeStyles({
	container: {
		padding: 0,
	},
	button: {
		width: "100%",
	},
});

interface HierarchyInputProps {
	// source: string,
	[key: string]: any;
}

const HierarchyInput: React.FC<HierarchyInputProps> = (
	props: HierarchyInputProps
) => {
	const { source, resource } = props;
	return (
		<InputConfigLoader resource={resource}>
			<ReferenceArrayInput
				source={`${source}.parents`}
				label="Parents"
				reference="tags"
				perPage={0}
			>
				<SelectArrayInput optionText="name" />
			</ReferenceArrayInput>
			<ReferenceArrayInput
				source={`${source}.children`}
				label="Children"
				reference="tags"
				perPage={0}
			>
				<SelectArrayInput optionText="name" />
			</ReferenceArrayInput>
		</InputConfigLoader>
	);
};

const HierarchyCard: React.FC<HierarchyInputProps> = (
	props: HierarchyInputProps
) => {
	const { source, resource, index, id, close } = props;
	const classes = useStyles();
	const form = useForm();
	debug("HierarchyCard props", props);
	const clear = () => {
		form.change(source, {});
	};
	return (
		<Card className={classes.root}>
			<CardHeader
				title={"Hierarchy Module"}
				/*subheader={
					index !== undefined ? idToReadableRoute(source) : "Root"
				} */
				action={
					<>
						{close && (
							<IconButton onClick={() => close()}>
								<CloseIcon />
							</IconButton>
						)}
					</>
				}
				avatar={
					<Avatar>
						<AccountTreeIcon />
					</Avatar>
				}
			/>
			<CardContent className={classes.cardGridContent}>
				<HierarchyInput source={source} resource={resource} />
			</CardContent>
			<CardActions>
				<Button color="primary" onClick={() => close()}>
					Ok
				</Button>
				<Button
					startIcon={<DeleteSweepIcon />}
					classes={{ root: classes.clearButton }}
					onClick={() => clear()}
				>
					Clear
				</Button>
			</CardActions>
		</Card>
	);
};

const HierarchyInputDialog: React.FC<any> = (props: any) => {
	const { buttonLabel, ...restProps } = props;
	const [open, setOpen] = React.useState(false);
	const handleClose = () => {
		setOpen(false);
	};
	debug("DialogHierarchyItem props", props);
	console.log(props);
	const classes = useDialogStyles();
	return (
		<>
			<Button
				className={classes.button}
				variant="outlined"
				color="primary"
				onClick={() => setOpen(true)}
			>
				{buttonLabel || "Edit Hierarchy"}
			</Button>
			<Dialog open={open} onClose={handleClose}>
				<DialogContent classes={{ root: classes.container }}>
					<HierarchyCard {...restProps} close={handleClose} />
				</DialogContent>
			</Dialog>
		</>
	);
};

const HierarchyModule: React.FC<any> = (props: any) => {
	const { source } = props;
	debug("HierarchyModule props", props);

	return (
		<div>
			<Labeled label="Hierarchy" {...props}>
				{/* this guy is controlling the props of my HierarchyInputDialog, careful */}
				<HierarchyInputDialog {...props} buttonLabel="Edit Hierarchy" />
			</Labeled>
		</div>
	);
};

export default HierarchyModule;
