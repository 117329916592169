import React from "react";
import { BooleanInput, addField, FormDataConsumer } from "react-admin";
import { useForm } from "react-final-form";
import _ from "lodash";
import get from "lodash/get";
import set from "lodash/set";
import omit from "lodash/omit";
import { FormControlLabel, Switch } from "@material-ui/core";

interface IProps {
    source: string,
    attribute: string,
    label?: string,
    [key: string]: any
}

const Toggle: React.FC<IProps> = (props: IProps) => {
    const { source, attribute, label, input } = props;
    const form = useForm();
    const formState = form.getState();
    const formValues = formState.values;
    const isChecked = React.useRef(false);
    const attributes = _.get(formValues, source) || [];
    const parse = (value: boolean) => {
        const attributes = _.get(formValues, source) || [];
        isChecked.current = value;
        return [
            ...(value ? _.union(attributes, [attribute]) : _.difference(attributes, [attribute]))
        ]

    }

    const format = (value: any) => {
        const attributes: string[] = value;
        isChecked.current = attributes != undefined && attributes.includes(attribute);
        if (attributes === undefined) return false;
        return attributes.includes(attribute);
    }

    const switchOnChange = (value: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const attributes = _.get(formValues, source) || [];
        input.onChange([
            ...(checked ? _.union(attributes, [attribute]) : _.difference(attributes, [attribute]))
        ]);
    }

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={attributes.includes(attribute)}
                    onChange={switchOnChange}
                    color="primary"
                />
            }
            label={label || source}
        />

        // <>
        //     <FormDataConsumer>
        //         {(props: any) => {
        //             const { formData, ...rest } = props;
        //             const attributes = get(formData, source);
        //             const isOn = attributes != undefined && attributes.includes(attribute);
        //             return <FormControlLabel
        //                 control={
        //                     <Switch
        //                         checked={isOn}
        //                         onChange={switchOnChange}
        //                         color="primary"
        //                     />
        //                 }
        //                 label={label || source}
        //             />
        //         }}
        //     </FormDataConsumer>
        // </>
        // <BooleanInput {...props} label={label||attribute} format={format} parse={parse} />
    )
}

export default addField(Toggle);