// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import React, { Component } from "react";
import PropTypes from "prop-types";

class Picker extends Component {
    constructor(props, context) {
        super(props, context);
    
        this.state = {
          dateRangePicker: {
            selection: {
              startDate: this.props.startDate,
              endDate: this.props.endDate,
              key: 'selection',
            },
          },
        };
    }

    handleRangeChange(which, payload) {
        this.setState({
          [which]: {
            ...this.state[which],
            ...payload,
          },
        });
        if(this.props.onDatePick && payload)
        {
            this.props.onDatePick(payload.selection)
        }
    }

	render(){
		return (
            <DateRangePicker
                onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                className={'PreviewArea'}
                months={2}
                ranges={[this.state.dateRangePicker.selection]}
                direction="horizontal"
            />

		)
	}
}

Picker.propTypes={
    startDate:PropTypes.object,
    endDate:PropTypes.object,
    key:PropTypes.string,
    onDatePick:PropTypes.func
}

Picker.defaultProps={
    startDate:new Date(),
    endDate:addDays(new Date(), 7),
    key:'selection'
}

export default Picker;