import { AuthProvider } from "ra-core";
import urljoin from "url-join";
import { GetConfig } from "./config";
import jwt_decode from "jwt-decode";
const debug = require("debug")("app:auth")

const authProvider: AuthProvider = {
    login: async (params) => {
        const debug = require("debug")("app:auth:login")
        const { username, password } = params;
        const request = new Request(urljoin(GetConfig()["apiUrl"], "Users/login"), {
            method: "POST",
            body: JSON.stringify({ username, password }),
            headers: new Headers({ "Content-Type": "application/json" }),
        })
        debug("Initiated login process")
        const req = await fetch(request);
        debug("Request returned", req);
        const data = await req.json();
        debug("Request response json returned", data)
        if (req.status < 200 || req.status >= 300) {
            return Promise.reject(req.statusText);
            throw new Error(req.statusText);
        }
        const jwt_data = jwt_decode<any>(data["token"]);
        debug("Decoded token", jwt_data);
        // const json = response.json();
        // debug("Request response json returned", json)
        localStorage.setItem("token", data["token"]);
        localStorage.setItem("username", jwt_data["unique_name"]);
        localStorage.setItem("userId", jwt_data["nameid"]);
        localStorage.setItem("role", Array.isArray(jwt_data['role']) ? jwt_data['role'][0].toLowerCase() : jwt_data['role'].toLowerCase());
        return Promise.resolve();
    },
    logout: (params) => {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        localStorage.removeItem("userId");
        localStorage.removeItem("role");
        return Promise.resolve();
    },
    checkAuth: params => localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
    checkError: error => Promise.resolve(),
    getPermissions: params => Promise.resolve(),
}

export default authProvider;