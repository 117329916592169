
export default {
    pos:{
        configuration:"Configuração",
        theme:{
            name:"Tema",
            light:"Claro",
            dark:"Escuro"
        },
        language:"Lingua"
    },
    root:{
        resources: {
			users: "Users",
			map: "Map",
			pois: "Points of Interest",
			pous: "Utility Points",
			commercialPoints: "Commercial Points",
			newsPoints: "News Points",
			assets: "Assets",
			content: "Content",
			markers: "Markers",
			triggers: "Triggers",
            projects: "Projects",
            tags: "Tags",
            applications:"Aplicações",
            geoTags: "Tags Geolocalizados",
            visualEffects: "Efeitos Visuais",
            playableTags: "Play Tags",
            allPoints: "Pontos",
            all:"Tudo",
            points:"Pontos",
            offices:"Escritórios"
		},
        pages:{
            users: "Utilizadores",
            map: "Mapa",
            pois: "Pontos de Interesse",
            pous: "Pontos de Utilidade",
            commercialPoints: "Pontos Comerciais",
            newsPoints: "Pontos de Noticias",
            assets: "Conteúdos",
            content: "Conteúdo",
            markers: "Marcas",
            triggers: "Condições",
            projects: "Projetos",
            allPoints: "Pontos",
            all:"Tudo",
            points:"Pontos"
        },
        properties:{
            name: "Nome",
            parent: "Pai",
            group: "Grupo",
            brand: "Marca",
            type: "Tipo",
            assets: "Conteúdos",
            content: "Conteúdo",
            description: "Descrição",
            expTriggers: "Condições para Experiência",
            markers: "Marcas",
            lang: "Idioma",
            transition: "Transição",
            point: "Id do Ponto",
            thumbnail: "Thumbnail",
            audioClip: "Audio",
            images: "Imagens",
            attributes: "Atributos"
        },
        action:{
            upload_new_map: "Carregar novo Mapa",
        },
        message:{
            publish_item:"Publicar item",
            publish_items:"Publicar itens",
            offline_item:"Item está offline",
            online_item:"Item está online, pressione para desconectar",
            online_outdated_item:"O item está online, mas desatualizado, carregue a nova versão ou pressione para desconectar",
            point_id_format_error:"Apenas um caractere permitido",
            pou_id_format_error:"Apenas letras normais maiúsculas permitidas",
            invalid_form:"Este formulário não é válido. Verifique se você preencheu todos os campos obrigatórios em todas as traduções e variantes.",
            current_map: "Mapa Atual",
            map_image: "Imagem do Mapa",
            adult: "Adulto",
            child: "Criança",
            deletePublishedExpPrompt: "Tem certeza de que deseja apagar o(s) item(ns) selecionado(s)? Seleção contém item(ns) publicado(s).",
            deletePublishedExpDenial: "Apenas Admin pode excluir itens publicados"
        }
    },        
    ra: {
        action: {
            delete: "Apagar",
            show: "Mostrar",
            list: "Listar",
            save: "Guardar",
            create: "Criar",
            edit: "Editar",
            cancel: "Cancelar",
            saveAndPublish: "Guardar e Publicar"
        },
    }
}