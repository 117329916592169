import React, { useState } from "react";
import { Button, Tooltip, CircularProgress } from "@material-ui/core";
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import { loadPublicConfig } from "../../ariabo.config";
import rest from "../../dataProvider/rest";
const debug = require("debug")("app:models:office:loadofficebutton");

const LoadButton: React.FC<any> = (props: any) => {
    const { record } = props;
    const [loading, setLoading] = useState(false);
    const boConfig = React.useMemo(() => loadPublicConfig(), []);
    const onClick = async () => {
        setLoading(true);
        const request = await rest.loadOfficeConfig(record.id);
        setLoading(false);
    }
    return (
        <>
        { !loading?
            <Tooltip title="Load office configuration">
                <Button onClick={() => onClick()} color="primary">
                    <FlipCameraIosIcon />
                </Button>
            </Tooltip>
        :
            <CircularProgress color="primary" />
        }
        </>
    )
}

export default LoadButton;