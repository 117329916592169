import { RichTextField, UrlField } from "react-admin";
import React from "react";
import ASSET_TYPES from "./asset-types";
import PropTypes from "prop-types";
import { ImageModal, ModelViewer, PanoramaViewer } from "../../components";
import ReactAudioPlayer from 'react-audio-player';
import ReactPlayer from 'react-player'

const styles = {
    av_imgdfltstyle: {
        maxWidth: '100%',
        maxHeight: '100%'
    },
    av_urlfield:{
        padding:"8px",
        wordWrap: 'break-word'
    },
    av_audioPlayer:{
        width: "100%"
    }
}

export default class AssetView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            record:props['record'],
            assetType:props['record']['assetType'],
            content:props['record']['content']
        }
    }
    static propTypes ={
        AVimgStyle:PropTypes.object
    }
    static defaultProps ={
        AVimgStyle: styles.av_imgdfltstyle
    }
    render() {
        const {
            props,
        } = this;
        return( 
            <div key={this.state.record.id +'.assetsdiv'}>
                {this.state.assetType === ASSET_TYPES.TEXT &&
                    <RichTextField record={this.state.record}  source='content' />
                }
                {(this.state.assetType === ASSET_TYPES.VIDEO || this.state.assetType === ASSET_TYPES.PANORAMAVIDEO) &&
                    <ReactPlayer  url={this.state.record['content']} controls={true} playing={false} height="auto" width="100%"/>
                }
                {this.state.assetType === ASSET_TYPES.MODEL3D &&
                    <ModelViewer setPosAuto={true} record={this.state.record} source='content' />
                }
                {this.state.assetType === ASSET_TYPES.AROC &&
                    <UrlField style={styles.av_urlfield} record={this.state.record}  source='content' />
                }
                {this.state.assetType === ASSET_TYPES.PANORAMAIMAGE &&
                    <PanoramaViewer record={this.state.record} source='content'/>
                }
                {(this.state.assetType === ASSET_TYPES.IMAGE) &&
                    // <Avatar size='60%' record={this.state.record} source='content' />
                    <ImageModal style={props.AVimgStyle} image={this.state.record['content']} thumb={this.state.record['content']+".thumb"}/>
                }
                {this.state.assetType === ASSET_TYPES.AUDIO &&
                    <ReactAudioPlayer style={styles.av_audioPlayer} src={this.state.record['content']} autoPlay={false} controls/>
                    // <UrlField style={{wordWrap:'break-word'}} record={this.state.record}  source='content' />
                }
            </div>
        );
    }
}