import {GetConfig} from '../config';
import axios from 'axios';
import urljoin from "url-join";
var debug = require('debug')('app:dataprovider:file')

const filesApiUrlKey = "filesApiUrl";
export default class file {
    static FILE_REST_API_URL = GetConfig()["filesApiUrl"]; //this doesnt work for some reason
    static ExtractNameFromDownloadURL (url){
        let url_split = url.split('/')
        return url_split[url_split.length-1];
    };

    static GetDownloadLink (container, name){
        return urljoin(GetConfig()[filesApiUrlKey], container, "download", name);
    };
    
    static post(container, file, name){
        let formData = new FormData();
        formData.append("file", file, name);
        debug("DBG POST FILE " + container+"/"+name);
        return fetch(urljoin(GetConfig()[filesApiUrlKey],container, "upload"), {
            method: 'POST',
            body: formData,
            mode: 'no-cors',
            headers: {
				"Authorization": "Bearer "+localStorage.getItem("token")
            }
        });
    };
    
    static postWithAxios(container, file, name){
        let formData = new FormData();
        formData.append("file", file, name);
        debug("DBG POST FILE " + container+"/"+name);
        return axios({ 
            url:urljoin(GetConfig()[filesApiUrlKey], container, "upload"),
            method: 'POST',
            data: formData,
            mode: 'no-cors',
            headers: {
				"Authorization": "Bearer "+localStorage.getItem("token")
            }
        })
    }

    static destroy(container, name){
        debug("DBG DELETE FILE " + container+"/"+name);
        return fetch(urljoin(GetConfig()[filesApiUrlKey], container,"files", name), {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
				"Authorization": "Bearer "+localStorage.getItem("token")
            }
        });
    }
    static createFileName = (name) =>{
        return `${JSON.stringify(Date.now())}.${name.trim().replace(/\s+/g, '')}`
    }
}


