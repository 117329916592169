import React, {FC} from 'react';
import { Create, TextInput, SimpleForm, required, SaveButton, Toolbar, translate } from 'react-admin';
import { Button } from '@material-ui/core';

const PlayerCreateToolbar:FC<any> = translate((props:any)=>(
    <Toolbar {...props}>
        <SaveButton />
        <Button onClick={props.onCancel}>
            {props.translate('ra.action.cancel')}
        </Button>
    </Toolbar>
));

const PlayerCreate:FC<any> = (props:any) => (
    <Create title=" " {...props}>
        <SimpleForm toolbar={<PlayerCreateToolbar onCancel={props.onCancel}/>}>
            <TextInput source="name" validate={required()} />
            <TextInput source="key" validate={required()} />
            <TextInput source="description" multiline/>
        </SimpleForm>
    </Create>
);

export default PlayerCreate;