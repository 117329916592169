// in AssetQuickPreviewButton.js
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {
    Button,
    SimpleShowLayout,
    TextField,
    ImageField,
    UrlField,
    RichTextField,
    FunctionField
} from 'react-admin';
import { ASSET_TYPES } from "../asset";
import reduxForm from 'redux-form/lib/reduxForm';
import ReactAudioPlayer from 'react-audio-player';
import { ModelViewer, PanoramaViewer, ImageModal } from "../../components";
import ReactPlayer from 'react-player';

var debug = require('debug')('app:models:assets:assetquickpreviewbutton');

const styles = theme => ({
    aqpb_field: {
        '& span': {
            display: 'inline-block',
            maxWidth: 'auto'
        }
    },
    aqpb_field1:{
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '0',
        fontSize: '0.75rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: '1',
        display: 'inline-block',
        maxWidth: '20em'
    },
    aqpb_div:
    {
        marginTop: '16px',
        marginBottom: '8px',
        display: 'inline-flex',
        flexDirection: 'column',
        lineHeight:"1",
        width:"100%"
    },
    aqpb_audioplayer:
    {
        minWidth:"100px"
    }
});

class AssetPreviewView extends React.Component {
    render() {
        const { classes } = this.props;
        debug("YO MY RECORD IS YOU KNOW",this.props);
        return (
            <SimpleShowLayout {...this.props} id={"5e29cfbd031d7a6170d7e8ad"} >
                <TextField source="name" className={classes.aqpb_field} />
                <FunctionField label='Content' render={record=>{
                    debug("field render", record)
                    switch (record['assetType']) {
                        case ASSET_TYPES.PANORAMAIMAGE:
                            return <PanoramaViewer record={record} source='content' />
                        case ASSET_TYPES.IMAGE:
                            return (
                                <ImageModal style={{width:"100%", height:"100%"}} image={record['content']} thumb={record['content']+".thumb"}/>

                                // <ImageField record={record} source={typeof record.content === 'object'?'content.rawFile.preview':'content'} />
                            )
                        case ASSET_TYPES.AUDIO:
                            return(
                                <ReactAudioPlayer 
                                    className={classes.aqpb_audioplayer}
                                    src={typeof record.content === 'object'? record.content.rawFile.preview : record['content']}
                                    autoPlay={false} controls/>
                            )
                        case ASSET_TYPES.MODEL3D:
                            return <ModelViewer setPosAuto={true} record={record} source={typeof record.content === 'object'?'content.rawFile.preview':'content'}/>
                            // return <GLTFModel src={record.content} texPath=""/>
                        case ASSET_TYPES.PANORAMAVIDEO:
                        case ASSET_TYPES.VIDEO:
                            return(
                                <ReactPlayer width="100%"  url={record['content']} controls={true} playing={false} />
                            )
                        case ASSET_TYPES.AROC:
                            return (
                                <UrlField record={record} source={typeof record.content === 'object'?'content.rawFile.preview':'content'} />
                            )
                        default:
                            return (           
                                <RichTextField className={classes.aqpb_field} record={record} source='content' />
                            )
                    }
                }}/>
                <p/>
            </SimpleShowLayout>
        );
    }
}

const mapStateToProps = (state, props) => {
    debug("mapStateToProps", state, props)
    debug("mapStateToProps", (props.record? "true" : "false"))

    return {
    // Get the record by its id from the react-admin state.
    record: props.record?props.record: state.admin.resources[props.resource]
        ? state.admin.resources[props.resource].data[props.id]
        : null,
    version: state.admin.ui.viewVersion
}};

const AssetPreview = connect(mapStateToProps, {})(
    withStyles(styles)(AssetPreviewView)
);

export class AssetQuickPreviewButtonBase extends Component {
    state = { showPanel: false };

    static defaultProps = {
        label:"ra.action.show"
    }
    handleClick = (event) => {
        event.stopPropagation();
        this.setState({ showPanel: true });
    };

    handleCloseClick = (event) => {
        event.stopPropagation();
        this.setState({ showPanel: false });
    };

    render() {
        const { showPanel } = this.state;
        let id = this.props['id'];
        if(!id && this.props.basePath!=="/assets")
        {
            let src = this.props.source.split('.');
            id = this.props['record'][src[0]][src[1]];
        }
        else if (!id && this.props.basePath==="/assets")
        {
            id=this.props.record.id;
        }
        debug("button props", this.props)
        return (
            <Fragment>
                <Button style={{width:'fit-content'}} onClick={this.handleClick} label={this.props.label}>
                    <IconImageEye />
                </Button>
                <Drawer
                    anchor="right"
                    open={showPanel}
                    onClose={this.handleCloseClick}
                    fullscreen={true}
                >
                    <div>
                        <Button label="Close" onClick={this.handleCloseClick}>
                            <IconKeyboardArrowRight />
                        </Button>
                    </div>
                    {/*id needs to be sent*/}
                    <AssetPreview name="" id={id} record={this.props.record} basePath="/assets" resource="assets" />
                </Drawer>
            </Fragment>
        );
    }
}

export default connect()(reduxForm({form:'AssetQuickPreviewButton',})(AssetQuickPreviewButtonBase));