export default {
	pos: {
		configuration: "Configuration",
		theme: {
			name: "Theme",
			light: "Light",
			dark: "Dark"
		},
		language: "Language"
	},
	root: {
		resources: {
			users: "Users",
			map: "Map",
			pois: "Points of Interest",
			pous: "Utility Points",
			commercialPoints: "Commercial Points",
			newsPoints: "News Points",
			assets: "Assets",
			content: "Content",
			markers: "Markers",
			triggers: "Triggers",
            projects: "Projects",
            tags: "Tags",
            applications: "Applications",
            geoTags: "Geolocated Tags",
            visualEffects: "Visual Effects",
            playableTags: "Play Tags",
            allPoints: "All Points",
            all:"All",
			points:"Points",
			offices:"Offices"
		},
		pages: {
			users: "Users",
			map: "Map",
			pois: "Points of Interest",
			pous: "Utility Points",
			commercialPoints: "Commercial Points",
			newsPoints: "News Points",
			assets: "Assets",
			content: "Content",
			markers: "Markers",
			triggers: "Triggers",
            projects: "Projects",
            allPoints: "All Points",
            all:"All",
            points:"Points"
		},
		properties: {
			name: "Name",
			parent: "Parent",
			group: "Group",
			brand: "Brand",
			type: "Type",
			assets: "Assets",
			content: "Content",
			description: "Description",
			expTriggers: "Experience Triggers",
			markers: "Markers",
			lang: "Language",
			transition: "Transition",
			point: "Point Id",
			thumbnail: "Thumbnail",
			audioClip: "Audio",
            images: "Images",
            attributes: "Attributes"
		},
		action: {
			upload_new_map: "Upload new Map"
		},
		message: {
			publish_item: "Publish item",
			publish_items: "Publish items",
			online_item: "Item is online, press to disconnect",
			offline_item: "Item is offline",
			online_outdated_item:
				"Item is online but outdated, push new version or press to disconnect",
			point_id_format_error: "Only one character allowed",
			pou_id_format_error: "Only uppercase normal letters allowed",
			invalid_form:
				"This form is not valid. Make sure you've populated all required fields in all translations and variants.",
			current_map: "Current Map",
			map_image: "Map Image",
			adult: "Adult",
			child: "Children",
			deletePublishedExpPrompt:
				"Are you sure you want to delete the selected item(s)? Selection contains published item(s).",
			deletePublishedExpDenial: "Only Admin can delete published items"
		}
	},
	ra: {
		action: {
			saveAndPublish: "Save e Publish"
		}
	}
};
