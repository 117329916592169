import UserIcon from '@material-ui/icons/Group';
import ExpGroups from '@material-ui/icons/CollectionsBookmark';
import Book from '@material-ui/icons/Book';
import Asset from '@material-ui/icons/Bookmark';
import Marker from '@material-ui/icons/Wallpaper';
import Triggers from '@material-ui/icons/CallSplit';
import Deliveries from '@material-ui/icons/PhonelinkRing';
import Effects from '@material-ui/icons/Star';
import Error from '@material-ui/icons/Error';
import React from "react";

export default ({k})=>{
    let Icon = undefined;
    let key = k;
    switch (key) {
        case "users":
            Icon=UserIcon;
            break;
        case "expGroups":
            Icon=ExpGroups;
            break;
        case "exps":
            Icon=Book;
            break;
        case "assets":
            Icon=Asset;
            break;
        case "markers":
            Icon=Marker;
            break;
        case "triggers":
            Icon=Triggers;
            break;
        case "deliveries":
            Icon=Deliveries;
            break;
        case "effects":
            Icon=Effects;
            break;
        default:
            Icon=Error;
            break;
    }
    return <Icon />;
}