import { IConfig } from "./types";

// export const REST_API_URL = "https://ds.aromni.com/api/";
// export const FILE_REST_API_URL= "https://ds.aromni.com/api/Files/";
// export const REST_API_URL = window.Config["apiUrl"]; //http://172.17.19.4:4100 PortasDoSol; http://172.17.15.46:42000 DigitalSignage; http://172.17.15.46:44000 realidadeAlges
// export const FILE_REST_API_URL= window.Config["filesApiUrl"];
export const CHILD_PERFIL = "5c2df9b911b73c11aff7f61c";
export const ADULT_PERFIL = "5c2df99911b73c11aff7f61b";
export const POUS_GROUP = "5c867093c86b32135f346d60";
export const POIS_GROUP = "5c8a890e62ccd04b1d000491";
export const COMMERCIAL_GROUP = "5d792169a0dbee8bccf05129";
export const NEWS_GROUP = "5d792174a0dbee8bccf05147";
export const MAIN_MAP_NAME = "main";
export const deleteChildrenWithParent = false;
export const publishChildrenWithParent = false;
export const ExpAriaServiceName = "publicTags";
export const supported_content_langs =[
    {
        name:"pt",
        readable:"Português"
    },
    {
        name:"gb",
        readable:"English"
    },
    {
        name:"es",
        readable:"Español"
    },
    {
        name:"fr",
        readable:"Français"
    }
]

export const exp_variants=[
    {
        name:"root.message.adult",
        key:"adult_variant",
        where:{
            group:{
                expGroups_id:ADULT_PERFIL
            }
        }
    },
    {
        name:"root.message.child",
        key:"child_variant",
        where:{
            group:{
                expGroups_id:CHILD_PERFIL
            }
        }
    }
]

export const GetConfig:()=>IConfig = () => {
    return (window as any).Config as IConfig;
}