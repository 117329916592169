import AssetRefInput, {AudioAssetRefInput, PanoramaImageRefInput} from "./AssetRefInput";
import EffectRefInput from "./EffectRefInput";
import EffectKeyInput from "./EffectKeyInput";

export default{
    "assetRef":AssetRefInput,
    "effectRef":EffectRefInput,
    "audioAssetRef":AudioAssetRefInput,
    "panoramaImageAssetRef":PanoramaImageRefInput,
    "effectKey":EffectKeyInput
}