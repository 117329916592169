import TextIcon from '@material-ui/icons/FormatAlignLeft';
import ImgIcon from '@material-ui/icons/Photo';
import VidIcon from '@material-ui/icons/Movie';
import AudioIcon from '@material-ui/icons/Audiotrack';
import ModelIcon from '@material-ui/icons/ThreeDRotation';
import ArocIcon from '@material-ui/icons/Inbox';
import Image360 from '@material-ui/icons/ThreeSixty';
import React from "react";

const types= {
    NONE:"none",
    TEXT:"text",
    IMAGE:"image",
    PANORAMAIMAGE:"panoramaImage",
    VIDEO:"video",
    AUDIO:"audio",
    MODEL3D:"model3d",
    AROC:"aroc",
    PANORAMAVIDEO:"panoramaVideo",
    icons:{
        "aroc": <ArocIcon />,
        "image": <ImgIcon />,
        "panoramaImage":<Image360 />,
        "video": <VidIcon />,
        "text": <TextIcon />,
        "audio": <AudioIcon />,
        "model3d": <ModelIcon />,
        "panoramaVideo":<Image360 />
    }
}
export default types;


