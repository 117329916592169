import React from 'react';
class DataFormatter extends React.Component{
    render(){
        const {format, parse,children, input,...props} = this.props;
        return (
            <>
                {React.Children.map(children,child=> (
                        React.cloneElement(child, {...child.props, ...props})
                    )
                )}
            </>
        )
    }
}

export default DataFormatter;