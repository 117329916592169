import React from "react";
import PublishIcon from "@material-ui/icons/Publish";
import {
	Button,
	CircularProgress
} from "@material-ui/core";
import rest from "../dataProvider/rest";
import { crudGetOne, crudGetMany } from "react-admin";
import { connect } from "react-redux";
import Cloud from "@material-ui/icons/Cloud";
import CloudOff from "@material-ui/icons/CloudOff";
import CloudDone from "@material-ui/icons/CloudDone";
import { Record  } from "ra-core";
import {
	CrudGetOneAction,
	Identifier,
	RefreshSideEffect,
	CrudGetManyAction
} from "ra-core";
import JwtDecode from "jwt-decode";
const debug = require("debug")("app:components:publishbutton");

type PublishStatus = "fetching" | "publishing" | "unpublishing" | "iddle";

const mongoIdRgx = /^[a-f\d]{24}$/i;

interface ActionPublishButtonProps {
	publishAction: () => Promise<void>;
	enabled: boolean;
}
const ActionPublishButton: React.FC<ActionPublishButtonProps> = (
	props: ActionPublishButtonProps
) => {
	const { enabled, publishAction } = props;
	return (
		<Button
			disabled={!enabled}
			color="primary"
			onClick={async (event) => {
                event.stopPropagation();
                await publishAction();
            }}
		>
			<PublishIcon />
		</Button>
	);
};

type ExpPublishStatus = "unpublished" | "outdated" | "published";
interface StatePublishButtonProps {
	unpublishAction: () => Promise<void>;
	status: ExpPublishStatus;
}
const StatePublishButton: React.FC<StatePublishButtonProps> = (
	props: StatePublishButtonProps
) => {
	const { status, unpublishAction } = props;
	switch (status) {
		case "outdated":
			return (
				<Button
					style={{ color: "#ffc107" }}
					onClick={async (event) => {
                        event.stopPropagation();
                        await unpublishAction();
                    }}
				>
					<Cloud />
				</Button>
			);
		case "published":
			return (
				<Button
					style={{ color: "#76ff03" }}
					onClick={async (event) => {
                        event.stopPropagation();
                        await unpublishAction();
                    }}
				>
					<CloudDone />
				</Button>
			);
		default:
		case "unpublished":
			return (
				<Button color="primary" disabled={true}>
					<CloudOff />
				</Button>
			);
	}
};

interface PublishControllerProps {
	record: Record;
	resource: string;
	basePath: string;
}
interface ConnectedPublishControllerProps extends PublishControllerProps {
	crudGetOne: (
		resource: string,
		id: Identifier,
		basePath: string,
		refresh?: RefreshSideEffect
	) => CrudGetOneAction;
}

const PublishController: React.FC<Partial<PublishControllerProps>> = connect(
	undefined,
	{ crudGetOne }
)((props: Partial<ConnectedPublishControllerProps>) => {
	const { record, crudGetOne, resource, basePath } = props;
	const [controllStatus, setControllStatus] = React.useState<PublishStatus>(
		"iddle"
	);
	const [isPublished, setIsPublished] = React.useState(false);

    const id = record && record.id;
    const stateInfo:any = record && record.stateInfo && JwtDecode(record.stateInfo);
	const publishedId = id && stateInfo && stateInfo["publishedId"];
	const publishedVersion = publishedId && stateInfo && stateInfo["publishedVersion"];

	React.useMemo(async () => {
		setControllStatus("fetching");
		const r = await rest.isPublished(record && record.id);
		if (r.status >= 200 && r.status < 300) {
			const data = await r.json();
			setIsPublished(data["isPublished"]);
			setControllStatus("iddle");
		} else console.error(`Failure at fetching publish data for ${id}`, r);
	}, [id, publishedId, publishedVersion]);

	const publishAction = React.useCallback(async () => {
		setControllStatus("publishing");
		var action = mongoIdRgx.test(publishedId) ? rest.updatePublishExp : rest.publishExp;
		var publish = await action(id);
		if (publish.status >= 200 && publish.status < 300) {
			const data = await publish.json();
            debug("publish data", id, data);
            setIsPublished(true);
			setControllStatus("iddle");
		} else console.error(`Failure at publishing ${id}`, publish);
		crudGetOne!(resource!, id!, basePath!);
	}, [id, publishedId, resource, basePath]);

	const unpublishAction = React.useCallback(async () => {
		setControllStatus("unpublishing");
		var publish = await rest.unpublishExp(id);
		if (publish.status >= 200 && publish.status < 300) {
			const data = await publish.json();
			debug("unpublish data", id, data);
			setControllStatus("iddle");
		} else console.error(`Failure at unpublishing ${id}`, publish);
		crudGetOne!(resource!, id!, basePath!);
	}, [id, publishedId, resource, basePath]);

	const onlineStatus = React.useMemo<ExpPublishStatus>(() => {
		if (isPublished) return "published";
		if (!isPublished && mongoIdRgx.test(publishedId)) return "outdated";
		return "unpublished";
	}, [isPublished, publishedId]);

	debug("props", props);
	return (
		<>
			{controllStatus === "iddle" ? (
				<>
					<ActionPublishButton
						enabled={!isPublished}
						publishAction={publishAction}
					/>
					<StatePublishButton
						status={onlineStatus}
						unpublishAction={unpublishAction}
					/>
				</>
			) : (
				<CircularProgress color="primary" />
			)}
		</>
	);
});

interface BulkPublishControllerProps {
	selectedIds: Identifier[];
	resource: string;
	basePath: string;
}

interface BulkRecordState{
    [key:string]:ExpPublishStatus
}

interface ConnectedBulkPublishControllerProps extends BulkPublishControllerProps {
	crudGetMany: (resource: string, ids: Identifier[]) => CrudGetManyAction;
}

const BulkPublishController: React.FC<BulkPublishControllerProps> = connect(
	undefined,
	{ crudGetMany }
)((props: ConnectedBulkPublishControllerProps) => {
    const {selectedIds, resource, basePath, crudGetMany} = props; 
	const debug = require("debug")(
		"app:components:publishbutton:bulkpublishcontroller"
    );
    const [controllStatus, setControllStatus] = React.useState<PublishStatus>(
		"iddle"
	);

    const publishManyAction = async ()=>{
        debug("will publish", selectedIds)
        if(!selectedIds)return Promise.reject("Select ids null or empty");
        setControllStatus("publishing");
        for (let i = 0; i < selectedIds!.length; i++) {
            const id  = selectedIds![i];
            const upsert = await rest.upsertPublishExp(id);
            if (upsert.status >= 200 && upsert.status < 300) {
                var data = await upsert.json();
                debug("upsert publish data", upsert.status+upsert.statusText, id, data);
            }
            else console.error("failed upsert publishing",id, upsert)
        }
        crudGetMany(resource, selectedIds);
        setControllStatus("iddle");
    };

    const unpublishManyAction = async()=>{
        if(!selectedIds)return Promise.reject("Select ids null or empty");
        setControllStatus("unpublishing");
        for (let i = 0; i < selectedIds!.length; i++) {
            const id  = selectedIds![i];
            const unpublish = await rest.unpublishExp(id);
            if (unpublish.status >= 200 && unpublish.status < 300) {
                var data = await unpublish.json();
                debug("unpublish data", id, data);
            }
            else console.error("failed unpublishing",id, unpublish)
        }
        crudGetMany(resource, selectedIds);
        setControllStatus("iddle");
    }

	debug("props", props);
	return (
		<>
			{controllStatus === "iddle" ? (
				<>
					<ActionPublishButton
						enabled={true}
						publishAction={async ()=>await publishManyAction()}
					/>
					<Button color="primary" onClick={async ()=>await unpublishManyAction()}>
                        <CloudOff />
                    </Button>
				</>
			) : (
				<CircularProgress color="primary" />
			)}
		</>
    )
});

export const BulkPublish = BulkPublishController;
export default PublishController;
