import React from "react";
import {
	FormDataConsumer,
	SelectInput,
	TextInput,
	FileInput,
	FileField
} from "react-admin";
import { BasedImageInput, BasedAudioClipInput } from "../../components";

const FileSelector: React.FC<any> = (props: any) => {
	return (
		<FormDataConsumer>
			{(props: any) => {
				const { formData, ...rest } = props;
				if(formData)switch (formData.assetType) {
					case "text":
						return <TextInput fullWidth source="content" multiline />;
					case "panoramaImage":
					case "image":
						return (
							<BasedImageInput
								record={formData}
								source="content"
								label="Content image"
								accept="image/*"
							/>
						);
					case "video":
						return (
							<FileInput
								source="content"
								label="Content video"
								accept="video/*"
							>
								<FileField source="content" title="name" />
							</FileInput>
                        );
                    case "panoramaVideo":
						return (
							<FileInput
								source="content"
								label="Content video"
								accept="video/*"
							>
								<FileField source="content" title="name" />
							</FileInput>
						);
					case "audio":
						return (
							<BasedAudioClipInput
								record={formData}
								source="content"
								label="Content audio"
								accept=".mp3, .wav"
								audioSrc="content"
							/>
						);
					case "model3d":
						return (
							<FileInput
								source="content"
								label="Content 3dModel"
								accept=".gltf, .glb"
							>
								<FileField source="content" title="name" />
							</FileInput>
						);
					case "aroc":
						return (
							<FileInput
								source="content"
								label="Content Aroc"
								accept=".aroc"
							>
								<FileField source="content" title="name" />
							</FileInput>
						);
					default:
						return <TextInput source="content" disabled />;
                }
                else return <TextInput source="content" disabled />
			}}
		</FormDataConsumer>
	);
};

export default FileSelector;
